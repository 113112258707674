import { CSSObject } from 'styled-components';
import { SendEmailPayload, TClient } from '../../../../types';
import { FitContentModal } from '../../FitContentModal/FitContentModal';
import { EmailActionButtons } from './components/EmailActionButtons';
import { EmailForm } from './components/EmailForm';
import { UiConfigColorsProvider } from '../../../../providers/providers/UiConfigColorProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema, TEmailFormData } from './schema';
import { useEffect, useState } from 'react';
import { useUser } from '../../../Slide/providers/UserProvider';
import { EmailPreview } from './components/EmailPreview';
import { useEmailPreview } from './hooks/useEmailPreview';
import { transformFormValuesToPayload } from './transformFormValuesToPayload';

interface Props {
  isOpen: boolean;
  client: TClient;
  onClose: () => void;
  presentationHash: string;
  headerTitle?: string;
  initialMessage?: string;
  initialSubject?: string;
  modalContainerStyles?: CSSObject;
  address?: string;
  isPdfGenerating?: boolean;
  pdfUrl?: string;
}

export const EmailPopup = ({
  isOpen,
  onClose,
  modalContainerStyles,
  headerTitle,
  client,
  initialSubject,
  presentationHash,
  initialMessage,
  isPdfGenerating,
  pdfUrl,
}: Props) => {
  const [isPreview, setIsPreview] = useState(false);

  const [previewPayload, setPreviewPayload] = useState<SendEmailPayload | null>(null);

  const { data: emailPreview, isLoading } = useEmailPreview(previewPayload);

  const user = useUser();
  const isUserDefaultSignature = Boolean(user?.showSignatureOnOutgoingEmails && user?.signature);

  const methods = useForm<TEmailFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      client: {
        id: Number(client.id),
        name: client.name,
        email: client.email,
      },
      subject: initialSubject,
      message: initialMessage,
      useDefaultSignature: isUserDefaultSignature,
      signature: `<p>Best Wishes,</p> <p>${user.name}</p>`,
      isAgentPhotoIncluded: !isUserDefaultSignature,
    },
  });

  const onCloseEmailForm = () => {
    methods.reset();
    onClose();
  };

  useEffect(() => {
    if (!initialMessage) return;
    methods.setValue('message', initialMessage);

    if (!initialSubject) return;
    methods.setValue('subject', initialSubject);
  }, [initialMessage, initialSubject, methods]);

  const onPreviewClick = async () => {
    const isValid = await methods.trigger();

    if (!isValid) return;

    const formValues = methods.getValues();

    const payload = transformFormValuesToPayload({ formValues, user, pdfUrl, presentationHash });

    setPreviewPayload(payload);

    setIsPreview(true);
  };

  const onBackClick = () => {
    setIsPreview(false);
  };

  return (
    <UiConfigColorsProvider>
      <FormProvider {...methods}>
        <FitContentModal
          isOpen={isOpen}
          headerTitle={headerTitle || ''}
          onClose={onCloseEmailForm}
          modalContainerStyles={modalContainerStyles}
          customButtons={
            <EmailActionButtons
              presentationHash={presentationHash}
              isPdfGenerating={isPdfGenerating}
              pdfUrl={pdfUrl}
              onPreviewClick={onPreviewClick}
              onClose={onCloseEmailForm}
              isPreview={isPreview}
              onBackClick={onBackClick}
            />
          }
          contentStyles={{ height: 'calc(100vh - 120px)' }}
        >
          {isPreview ? (
            <EmailPreview preview={emailPreview} isLoading={isLoading} />
          ) : (
            <EmailForm />
          )}
        </FitContentModal>
      </FormProvider>
    </UiConfigColorsProvider>
  );
};
