import React from 'react';

import {
  TCategory,
  TDynamicSectionId,
  TSectionConfig,
  TSlide,
  TSlideId,
  TSlidePlatform,
  TSlideTheme,
} from '../../../../../types';
import { CATEGORIES } from '../../../../../constants/categories';
import { usePreviewMode } from '../../providers/PreviewModeProvider';
import { useCategory } from '../../../../../providers/providers/CategoryProvider';
import { SlideFactory } from '../../../../../components/Slide/services/SlideFactory';
import { useOrder } from '../../../../../providers/providers/OrderProvider';
import { useSlides } from '../../../../../providers/providers/SlidesProvider';
import { useUiConfigTheme } from '../../../../../providers/providers/UiConfigThemeProvider';
import { OrderableSlidesComponentProps } from '../DynamicOrderableSections/DynamicSection/DynamicSection.component';
import { DynamicOrderableSlides } from '../DynamicOrderableSections/DynamicSection/DynamicOrderableSlides/DynamicOrderableSlides.component';

export const CMADynamicOrderableSlides: React.FC<OrderableSlidesComponentProps> = ({
  section,
  open,
}) => {
  const { sectionsSlide, removeSlideFromSection } = useOrder();

  const { getSlides } = useSlides();

  const { theme } = useUiConfigTheme();
  const { category } = useCategory();
  const { previewMode } = usePreviewMode();

  const currentSectionSlides = React.useMemo(() => {
    return getSlides(sectionsSlide[section.id as TDynamicSectionId] ?? []);
  }, [getSlides, sectionsSlide, section.id]);

  const onRemoveSlide = (slide: TSlide) => {
    removeSlideFromSection(section.id, slide.id as TSlideId);
  };

  const slidesMap = React.useMemo(
    () => Object.fromEntries(currentSectionSlides.map(slide => [slide.id, { ...slide }])),
    [currentSectionSlides],
  );

  const hasSlideComponent = (
    slide: TSlide,
    theme: TSlideTheme,
    platform: TSlidePlatform,
    category?: TCategory,
  ) => {
    if (!slide?.id) return false;

    return !!SlideFactory.create({
      slide: slide.id as TSlideId,
      theme,
      platform,
      template: slide.template,
      category: category ?? CATEGORIES.TRADITIONAL,
    });
  };

  const slidesOrder = React.useMemo(() => {
    return sectionsSlide[section.id]
      .map(slideId => slidesMap[slideId])
      .filter(slide => hasSlideComponent(slide, theme, previewMode, category))
      .map(slide => slide.id as TSlideId);
  }, [sectionsSlide, previewMode, section?.id, slidesMap, theme, category]);

  if (!section?.id) return null;

  return (
    <DynamicOrderableSlides
      order={slidesOrder}
      slides={slidesMap}
      section={section as TSectionConfig}
      onRemoveSlide={onRemoveSlide}
      notDraggable={['coverSlide', 'greetingVideo', 'closingAndNextSteps', 'listingFeedback']}
      notRemovable={['coverSlide']}
      open={open}
    />
  );
};
