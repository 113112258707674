import { cmaApi } from '../cmaApiCommunicator';
import { PropertyApiMapper } from './PropertyApiMapper';

export class PropertyCommunicator {
  static async search(address) {
    const apiSearchCriteria = await cmaApi.post('/address/info', {
      address: address.deliveryLine,
      city: address.city,
      number: address.number,
      state: address.state,
      external: address?.external,
      withoutDefault: address?.withoutDefault || false,
    });

    return !apiSearchCriteria ? null : PropertyApiMapper.apiToEntity(apiSearchCriteria);
  }
}
