import React from 'react';

import { withProviders } from '../../../../hoc/withProviders';
import { OrderProvider } from '../../../../providers/providers/OrderProvider';
import { SlidesProvider } from '../../../../providers/providers/SlidesProvider';
import { ClientProvider } from '../../../../providers/providers/ClientProvider';
import { CreateBuyerTourActions } from './CreateBuyerTourActions';
import { SectionsProvider } from '../../../../providers/providers/SectionsProvider';
import { VideoLinkProvider } from '../../providers/VideoLinkProvider';
import { PropertiesProvider } from '../../../../providers/providers/PropertiesProvider';
import { CreateBuyerTourLayout } from '../../layouts/CreateBuyerTourLayout';
import { useUser } from '../../../../components/Slide/providers/UserProvider';
import { UiConfigProvider } from '../../../../providers/providers/UiConfigProvider';
import { CategoryProvider } from '../../../../providers/providers/CategoryProvider';
import { CreatedAtProvider } from '../../../../providers/providers/CreatedAtProvider';
import { BuyerTourCreatorContent } from './BuyerTourCreatorContent/BuyerTourCreatorContent';
import { PresentationTypeProvider } from '../../../../providers/providers/PresentationTypeProvider';

export const BuyerTourPresentationCreator: React.FC = withProviders(
  PresentationTypeProvider,
  CategoryProvider,
  PropertiesProvider,
  ClientProvider,
  SlidesProvider,
  SectionsProvider,
  OrderProvider,
  VideoLinkProvider,
  UiConfigProvider,
  CreatedAtProvider,
)(function BuyerTourBuilder() {
  const user = useUser();

  if (!user) return null;

  return (
    <CreateBuyerTourLayout HeaderActions={CreateBuyerTourActions}>
      <BuyerTourCreatorContent />
    </CreateBuyerTourLayout>
  );
});
