import { useCallback, useEffect, useState } from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  COMPARABLE_MODAL_MENU_ITEM_ID,
  COMPARABLE_MODAL_MENU_SUBITEM_ID,
  CustomComparableFormData,
  CustomComparablePayload,
  PRESENTATION_CREATION_SEARCH_PARAMS,
  TProperty,
} from '../../../../types';
import { FitContentModal } from '../../../../components/Common';
import { schema } from '../../schema/schema';
import { SideMenu } from './SideMenu';
import { ComparablesModalContent } from './ComparablesModalContent/ComparablesModalContent';
import { customComparableFormDataToEntityMapper } from '../../services/customComparableFormDataToEntityMapper';
import { customComparableEntityToFormDataMapper } from '../../services/customComparableEntityToFormDataMapper';
import { useUrlSearchParam } from '../../../../hooks/useQueryParams';
import { customComparableEntityToApiMapper } from '../../services/customComparableEntityToApiMapper';
import { subjectAtom } from '../../../report/state/subjectAtom';
import { useCreateCustomComparable } from '../../hooks/useCreateCustomComparable';
import { useEditCustomComparable } from '../../hooks/useEditCustomComparable';
import { customComparableToEditAtom } from '../../state/customComparableToEditAtom';
import { useUser } from '../../../../components/Slide/providers/UserProvider';
import { PropertyCommunicator } from '../../../../pages/PresentationCreate/dash/communicators/Property/PropertyCommunicator';
import { defaultMapCenterAtom } from '../../state/defaultMapCenterAtom';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (data: TProperty) => void;
  creatingFromInvalidListing?: boolean;
}

export const CustomComparablesModal = ({
  isOpen,
  onClose,
  onSuccess,
  creatingFromInvalidListing = false,
}: Props) => {
  const [activeMainItemId, setActiveMainItemId] = useState<COMPARABLE_MODAL_MENU_ITEM_ID>(
    COMPARABLE_MODAL_MENU_ITEM_ID.REQUIRED_FIELDS,
  );
  const [activeSubItemId, setActiveSubItemId] = useState<COMPARABLE_MODAL_MENU_SUBITEM_ID | null>(
    null,
  );
  const presentationId = useUrlSearchParam(PRESENTATION_CREATION_SEARCH_PARAMS.PRESENTATION_ID);
  const subject = useAtomValue(subjectAtom);
  const [customComparableToEdit, setCustomComparableToEdit] = useAtom(customComparableToEditAtom);

  const { mutateAsync: createComparable, isLoading: createLoading } = useCreateCustomComparable();
  const { mutateAsync: editComparable, isLoading: editLoading } = useEditCustomComparable();
  const { isCanadaUser } = useUser();
  const setDefaultMapCenter = useSetAtom(defaultMapCenterAtom);

  const methods = useForm({
    defaultValues: {
      isCanadaUser,
    },
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
    trigger,
    watch,
    clearErrors,
  } = methods;

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      if (Object.keys(errors).length === 1 && errors.virtualTour) return;

      const anchor = document.querySelector(`#${COMPARABLE_MODAL_MENU_ITEM_ID.REQUIRED_FIELDS}`);
      anchor?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [errors]);

  const fetchAttomData = useCallback(async (customComparableToEdit: Partial<TProperty>) => {
    const deliveryLine = customComparableToEdit?.address?.deliveryLine;
    const city = customComparableToEdit?.address?.city;
    const state = customComparableToEdit?.address?.state;

    if (!deliveryLine || !city || !state) return customComparableToEdit;
    const property = await PropertyCommunicator.search({
      deliveryLine,
      city,
      state,
      external: true,
      withoutDefault: true,
    });

    return {
      ...customComparableToEdit,
      coordinates: {
        latitude: customComparableToEdit?.coordinates?.latitude || property?.coordinates?.latitude,
        longitude:
          customComparableToEdit?.coordinates?.longitude || property?.coordinates?.longitude,
      },
      zip: customComparableToEdit?.address?.zip || property?.address?.zip,
      propertyType: customComparableToEdit?.propertyType || property?.propertyType,
      beds: customComparableToEdit?.beds || property?.beds,
      baths: customComparableToEdit?.baths || property?.baths,
      totalBaths: customComparableToEdit?.totalBaths || property?.totalBaths,
      size: customComparableToEdit?.size || property?.size,
      totalSize: customComparableToEdit?.totalSize || property?.totalSize,
      yearBuilt: customComparableToEdit?.yearBuilt || property?.yearBuilt,
    };
  }, []);

  useEffect(() => {
    const getFullData = async () => {
      if (!customComparableToEdit) return;
      try {
        const fullCustomData = await fetchAttomData(customComparableToEdit);

        const transformedComparable = customComparableEntityToFormDataMapper(
          fullCustomData as TProperty,
        );
        reset(transformedComparable);
        const coordinates = fullCustomData?.coordinates;
        setDefaultMapCenter(
          coordinates?.latitude && coordinates?.longitude
            ? {
                lat: coordinates?.latitude,
                lng: coordinates?.longitude,
              }
            : null,
        );
        trigger();
      } catch (e) {
        const transformedComparable = customComparableEntityToFormDataMapper(
          customComparableToEdit as TProperty,
        );
        const coordinates = customComparableToEdit?.coordinates;
        setDefaultMapCenter(
          coordinates?.latitude && coordinates?.longitude
            ? {
                lat: coordinates?.latitude,
                lng: coordinates?.longitude,
              }
            : null,
        );
        reset(transformedComparable);
        trigger();
      }
    };

    reset({ isCanadaUser });

    if (!customComparableToEdit) return;

    if (creatingFromInvalidListing) {
      getFullData();
      return;
    }

    const transformedComparable = customComparableEntityToFormDataMapper(
      customComparableToEdit as TProperty,
    );
    reset(transformedComparable);
  }, [
    creatingFromInvalidListing,
    customComparableToEdit,
    fetchAttomData,
    isCanadaUser,
    reset,
    setDefaultMapCenter,
    trigger,
  ]);

  const handleCreate = async (payload: CustomComparablePayload) => {
    createComparable(payload, {
      onSuccess: createdComparable => {
        onSuccess?.(createdComparable);
        reset({ isCanadaUser });
        onClose();
        setDefaultMapCenter(null);
      },
    });
  };

  const handleEdit = async (payload: CustomComparablePayload & { id: string }) => {
    editComparable(payload, {
      onSuccess: data => {
        onSuccess?.(data);
        reset({ isCanadaUser });
        setCustomComparableToEdit(null);
        onClose();
        setDefaultMapCenter(null);
      },
    });
  };

  const handleSave = handleSubmit(async (values: CustomComparableFormData) => {
    if (!presentationId) return;

    const comparable = customComparableFormDataToEntityMapper(values);

    const payload = customComparableEntityToApiMapper({
      ...comparable,
      subject: {
        size: subject?.property.size,
        coordinates: {
          latitude: subject?.lat,
          longitude: subject?.lng,
        },
      },
      presentationId: Number(presentationId),
      creatingFromInvalidListing:
        comparable.isCreatedFromInvalidListing || Boolean(creatingFromInvalidListing),
    });

    if (customComparableToEdit && !creatingFromInvalidListing) {
      handleEdit({
        ...payload,
        id: (customComparableToEdit.sourceId as string) || (customComparableToEdit.id as string),
      });
      return;
    }

    if (!customComparableToEdit || creatingFromInvalidListing) {
      handleCreate(payload);
    }
  });

  useEffect(() => {
    const subscription = watch(value => {
      if (value.address) {
        clearErrors('address');
      }
      if (value.city) {
        clearErrors('city');
      }
      if (value.state) {
        clearErrors('state');
      }
      if (value.zip) {
        clearErrors('zip');
      }
      if (value.propertyType) {
        clearErrors('propertyType');
      }
      if (value.listPrice) {
        clearErrors('listPrice');
      }
      if (value.soldPrice) {
        clearErrors('soldPrice');
      }
      if (value.bedroomCount) {
        clearErrors('bedroomCount');
      }
      if (value.livingAreaSqft) {
        clearErrors('livingAreaSqft');
      }
      if (isCanadaUser && value.province) {
        clearErrors('province');
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, clearErrors, isCanadaUser]);

  const handleClose = () => {
    reset({ isCanadaUser });
    setCustomComparableToEdit(null);
    setDefaultMapCenter(null);
    onClose();
  };

  const hasErrors = Object.keys(errors).length > 0;
  const modalTitle = creatingFromInvalidListing
    ? 'Add Custom Comparable'
    : 'Add OFF-MLS Comparable';
  const confirmBtnText = customComparableToEdit ? 'Save Changes' : 'Add Comparable';

  return (
    <FormProvider {...methods}>
      <FitContentModal
        isOpen={isOpen}
        headerTitle={modalTitle}
        onClose={handleClose}
        confirmBtnText={confirmBtnText}
        loading={createLoading || editLoading}
        hasCancelBtn
        modalContainerStyles={{ maxWidth: 800, width: '80%' }}
        contentStyles={{ height: 'calc(100vh - 120px)' }}
        onConfirmClick={handleSave}
        contentDisplay='flex'
        disabled={hasErrors}
      >
        <Container>
          <SideMenu
            activeMainItemId={activeMainItemId}
            activeSubItemId={activeSubItemId}
            setActiveMainItemId={setActiveMainItemId}
            setActiveSubItemId={setActiveSubItemId}
          />
          <ComparablesModalContent
            setActiveMainItemId={setActiveMainItemId}
            setActiveSubItemId={setActiveSubItemId}
            isOnMls={creatingFromInvalidListing}
          />
        </Container>
      </FitContentModal>
    </FormProvider>
  );
};

const Container = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
`;
