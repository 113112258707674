import { CSSProperties, ReactNode, MouseEvent, useEffect, useRef, useState, Children } from 'react';
import styled from 'styled-components';

import { Portal } from './Portal';

type RootModalStyles = {
  backdrop?: CSSProperties;
};

type RootModalProps = {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  styles?: RootModalStyles;
  anchorEl?: Element | null;
  modalRoot?: 'custom-modal';
  disableOutsideClick?: boolean;
};

export function RootModal({
  modalRoot = 'custom-modal',
  open,
  children,
  styles,
  anchorEl,
  onClose,
  disableOutsideClick,
}: RootModalProps) {
  const [show, setShow] = useState(open);
  const anchorRefEl = useRef<HTMLDivElement>(null);

  const getOffset = (el: Element) => {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY,
    };
  };

  const anchorPos = useRef<null | { top: number; left: number }>(
    anchorEl ? getOffset(anchorEl) : null,
  );

  const el = useRef<HTMLDivElement>(null);

  const handleClose = (e: MouseEvent<HTMLDivElement>) => {
    if (disableOutsideClick) return;

    if (el.current && el.current === e.target) {
      onClose();
      setShow(false);
    }
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [open]);

  useEffect(() => {
    if (open !== show) setShow(open);
  }, [open, show]);

  let Child = Children.only(children);

  if (anchorEl && anchorPos.current) {
    Child = (
      <ChildContainer
        ref={anchorRefEl}
        top={anchorPos.current.top * 0.8}
        left={anchorPos.current.left * 0.8}
      >
        {Child}
      </ChildContainer>
    );
  }

  if (!show) return null;

  return (
    <Portal modalRoot={modalRoot} anchorEl={anchorEl}>
      <BackdropEl ref={el} onClick={handleClose} style={styles?.backdrop}>
        {Child}
      </BackdropEl>
    </Portal>
  );
}

const BackdropEl = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`;
const ChildContainer = styled.div<{ top: number; left: number }>`
  position: absolute;
  width: max-content;
  height: fit-content;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
`;
