import styled from 'styled-components';

export const Container = styled('div')(({ theme }) => ({
  padding: 25,
  overflowY: 'auto',
  border: `1px solid ${theme.colors.v2.gray[200]}`,
  borderRadius: 5,
  backgroundColor: theme.colors.v2.background,
  overflow: 'auto',
}));

export const Grid = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 24,
  paddingBottom: 30,
});

export const NoSlidesText = styled('p')(({ theme }) => ({
  color: theme.colors.gray[400],
  fontSize: 16,
  textAlign: 'center',
}));

export const SlideContainer = styled('div')<any | any>(({ theme, disabled }) => ({
  borderRadius: 8,
  border: `1px solid ${theme.colors.gray[200]}`,
  padding: '48px 19px 10px 16px',
  position: 'relative',
  minWidth: 274,
  maxWidth: 317,
  height: 'auto',
  boxShadow: '2px 4px 12px rgba(0, 0, 0, 0.05)',
  cursor: 'pointer',
  transition: 'all .3s',
  overflow: 'hidden',
  '&:hover': {
    boxShadow: '3px 6px 14px rgba(0, 0, 0, 0.2)',
  },
}));

export const SlideWrapper = styled.div<{ disabled?: boolean }>`
  opacity: ${({ disabled }) => (!disabled ? 0.4 : 1)};,
`;

export const MovedSlideFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 20px;
  margin-top: 7px;
`;

export const MoveSlideText = styled.p`
  font-size: 10px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;

export const MoveSlideTextBold = styled.span`
  font-size: 10px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.v2.text.regular};
  font-style: italic;
`;

export const RevertCTA = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.v2.text.link};
  font-weight: 600;
`;
