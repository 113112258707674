import { PropsWithChildren } from 'react';
import { CSSObject } from 'styled-components';

import { RootModal } from '../RootModal/RootModal';
import { Container, Header, Heading, CloseIcon, Content } from './Modal.styles';

type ModalProps = {
  open: boolean;
  title: string;
  onClose: () => void;
  zIndex?: number;
  isBlueHeader?: boolean;
  contentStyles?: CSSObject;
  modalContainerStyles?: CSSObject;
  disableOutsideClick?: boolean;
};

export function Modal({
  open,
  title,
  zIndex,
  onClose,
  children,
  isBlueHeader,
  contentStyles,
  modalContainerStyles,
  disableOutsideClick,
}: PropsWithChildren<ModalProps>) {
  const handleClose = () => {
    onClose();
  };

  if (!open) return null;

  return (
    <RootModal
      open={open}
      onClose={onClose}
      styles={{ backdrop: { zIndex: zIndex ?? 2 } }}
      disableOutsideClick={disableOutsideClick}
    >
      <Container style={modalContainerStyles}>
        <Header isBlue={isBlueHeader}>
          <Heading variant='h3'>{title}</Heading>
          <CloseIcon icon='xmark' onClick={handleClose} />
        </Header>
        <Content style={contentStyles}>{children}</Content>
      </Container>
    </RootModal>
  );
}
