import styled from 'styled-components';

import { TProperty } from '../../../../types';
import { SectionDetailItem } from '../components/SectionDetailItem';
import { useDaysOnSite } from '../../../../hooks/daysOnSite/useDaysOnSite';
import { getPropertyId } from '../../../../features/report/services/getPropertyId';
import { getPropertyTypeString } from '../../../../pages/PresentationCreate/dash/view/pages/Authenticated/PropertySearch/PropertyDetails/PropertySearchCriteria/Criterias/PropertyType/propertyTypeOptions';
import { transformLink } from '../services/transformLink';

interface Props {
  property: TProperty;
}

export const Info = ({ property }: Props) => {
  const DOS = useDaysOnSite(property);
  const customPropertyId = property.sourceId ? property.id || '' : property.mlsListingId || '';
  const id = property.isCustom ? customPropertyId : getPropertyId(property) || '';

  const virtualTourLink = transformLink(property.virtualTour);

  return (
    <Wrapper>
      {property.status !== 'pending' && (
        <SectionDetailItem
          label={DOS.label}
          value={DOS.value ? String(DOS.value) : 'Unavailable'}
        />
      )}
      <SectionDetailItem
        hide={property.status === 'sold'}
        label='Listing Date'
        value={property.listDateHuman || 'Unavailable'}
      />
      <SectionDetailItem
        hide={property.status !== 'sold'}
        label='Sold'
        value={property.saleDateHuman || 'Unavailable'}
      />
      <SectionDetailItem label='MLS ID' value={id ? id.toUpperCase() : 'Unavailable'} />
      <SectionDetailItem
        label='Property Type'
        value={getPropertyTypeString(property.propertyType)}
      />
      <SectionDetailItem label='Listing Type' value='Residential' />
      <SectionDetailItem
        hide={!virtualTourLink}
        label='Virtual Tour'
        value={
          <Link href={virtualTourLink} target='_blank' rel='noreferrer'>
            Virtual Tour Link
          </Link>
        }
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Link = styled.a`
  color: ${({ theme }) => theme.colors.v2.text.link};
  cursor: pointer;
`;
