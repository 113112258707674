import * as yup from 'yup';

export const schema = yup.object({
  customComparables: yup
    .array(
      yup.object({
        status: yup
          .object({
            label: yup.string().required(),
            value: yup.string().required(),
          })
          .required()
          .test('status', 'listingStatus is invalid', function ({ value }) {
            if (!value) {
              return this.createError({
                message: 'listingStatus is invalid',
              });
            }
            return true;
          }),

        price: yup.string().min(1).required(),
        salePrice: yup.string().when('status', {
          is: ({ value }: { value: string }) => value === 'sold',
          then: schema => schema.required().min(1),
          otherwise: schema => schema.notRequired(),
        }),
        address: yup.string().required(),
        sourceId: yup.string().required(),
      }),
    )
    .required(),
});
