import React, { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { BUYER_TOUR } from '../../constants';
import { withProviders } from '../../../../hoc/withProviders';
import { Routes } from '../../../../pages/Router/Routes';
import { CreateBuyerTourLayout } from '../../layouts/CreateBuyerTourLayout';
import { useUser } from '../../../../components/Slide/providers/UserProvider';
import { PresentationEditActions } from '../../../../components/presentation/PresentationEditActions';
import { ClientProvider } from '../../../../providers/providers/ClientProvider';
import { FullScreenLoader } from '../../../../components/Loader/FullScreenLoader';
import type { TBuyerTourPresentation, TSuperPresentation } from '../../../../types';
import { PropertiesProvider } from '../../../../providers/providers/PropertiesProvider';
import { OrderProvider, useOrder } from '../../../../providers/providers/OrderProvider';
import { SlidesProvider, useSlides } from '../../../../providers/providers/SlidesProvider';
import { SectionsProvider, useSections } from '../../../../providers/providers/SectionsProvider';
import { UiConfigProvider, useUiConfigs } from '../../../../providers/providers/UiConfigProvider';
import { CategoryProvider, useCategory } from '../../../../providers/providers/CategoryProvider';
import {
  SelectedSlideProvider,
  useSelectedSlide,
} from '../../../../providers/providers/SelectedSlideProvider';
import { PresentationModeProvider } from '../../../../providers/providers/PresentationModeProvider';
import { PresentationTypeProvider } from '../../../../providers/providers/PresentationTypeProvider';
import { ActiveSectionProvider } from '../../../../components/Slide/providers/ActiveSectionProvider';
import { PresentationEditBody } from '../../../../components/presentation/PresentationEditBody/PresentationEditBody';
import { useSetBuyerTourPresentationFullConfigToProviders } from './useSetBuyerTourPresentationFullConfigToProviders';
import { PreviewModeProvider } from '../../../../pages/PresentationEdit/PresentationCreationContent/providers/PreviewModeProvider';
import { PresentationSettingsHeader } from '../../../../pages/PresentationEdit/PresentationCreationContent/PresentationSettingsHeader/PresentationSettingsHeader';
import { CreatedAtProvider } from '../../../../providers/providers/CreatedAtProvider';

const useFirstPresentationSlide = () => {
  const { slides } = useSlides();

  return slides?.coverSlide;
};

const multiPageSlides = ['listingFeedback', 'listingDetails'];

interface Props {
  presentation: TBuyerTourPresentation;
}
export const BuyerTourPresentationEdit: React.FC<Props> = withProviders<Props>(
  UiConfigProvider,
  PresentationTypeProvider,
  CategoryProvider,
  PropertiesProvider,
  ClientProvider,
  SlidesProvider,
  SectionsProvider,
  OrderProvider,
  ActiveSectionProvider,
  PresentationModeProvider,
  SelectedSlideProvider,
  PreviewModeProvider,
  CreatedAtProvider,
)(function BuyerTourPresentationEdit({ presentation }: Props) {
  const user = useUser();
  const history = useHistory();
  const { selectedSlide, setSelectedSlide } = useSelectedSlide();

  useSetBuyerTourPresentationFullConfigToProviders(presentation);

  const slide = useFirstPresentationSlide();
  useEffect(() => {
    if (selectedSlide || !slide) return;

    setSelectedSlide(slide);
  }, [selectedSlide, setSelectedSlide, slide]);

  const ui = useUiConfigs();
  const { slides } = useSlides();
  const { sections } = useSections();
  const { category } = useCategory();
  const { sections: sectionsOrder, sectionsSlide } = useOrder();
  const { hash } = useParams<{ hash: string }>();

  const updatedPresentation = {
    ...presentation,
    category,
    slides,
    sections,
    order: { sections: sectionsOrder, sectionsSlide },
    ui,
  };

  if (!slide || !user) return <FullScreenLoader />;

  const Actions: React.FC = () => (
    <PresentationEditActions
      isFetching={!presentation || !user}
      presentation={updatedPresentation as TSuperPresentation}
      onBack={() =>
        history.push(
          `${Routes.presentationNew}?type=${BUYER_TOUR}&presentation_id=${presentation.id}&presentation=${hash}&action=edit`,
        )
      }
    />
  );

  return (
    <CreateBuyerTourLayout HeaderActions={Actions}>
      <PresentationSettingsHeader useEmphasis={false} />
      <PresentationEditBody multiPageSlides={multiPageSlides} />
    </CreateBuyerTourLayout>
  );
});
