import { CSSProperties } from 'react';
import styled from 'styled-components';

import { CLOSED_COLOR } from '../../../../pages/PresentationCreate/dash/config/constants';
import { useIsDTC } from '../../../../hooks/daysOnSite/useIsDTC';
import { ScatterChart } from '../components/ScatterChart';
import { ChartContainer } from '../components/ChartContainer';
import { SellingPaceTooltip } from './SellingPaceTooltip';
import { useSellingPaceData } from './useSellingPaceData';
import { useShowPropertyDetails } from '../../hooks/useShowPropertyDetails';
import { useAtomValue } from 'jotai';
import { reportPropertiesAtom } from '../../state/reportPropertiesAtom';
import { isPresentationViewAtom } from '../../state/isPresentationViewAtom';

interface SellingPaceChartProps {
  headline?: string;
  description?: string;
  style?: CSSProperties;
}

export const SellingPaceChart = ({ headline, description, style }: SellingPaceChartProps) => {
  const { graph } = useSellingPaceData();
  const isDTC = useIsDTC();

  const properties = useAtomValue(reportPropertiesAtom);
  const isPresentationView = useAtomValue(isPresentationViewAtom);

  const { showPropertyDetails } = useShowPropertyDetails();

  const onMoreInfo = (id: string) => {
    if (isPresentationView) return;
    const property = properties.find(p => p.property.id === id)?.property;

    showPropertyDetails(property || null);
  };

  const xDataKey = isDTC ? 'days_to_close' : 'days_on_market';
  const xLabel = isDTC ? 'Days To Close' : 'DOS';

  // Add adjusted price to the graph, this should be updated with data from the API
  const newGraph = graph.map(item => {
    const property = properties.find(p => p.property.id === item.id);
    return {
      ...item,
      isCustom: property?.property?.isCustom || false,
      adjustedPrice: property?.property?.adjustedPrice ? property.property.adjustedPrice : null,
    };
  });

  return (
    <ChartContainer
      headline={headline}
      description={description && <Description description={description} />}
      childrenWrapperStyle={style}
    >
      <ScatterChart
        data={newGraph}
        dataKey='sale_price'
        moreInfo={onMoreInfo}
        customTooltip={props => <SellingPaceTooltip {...props} />}
        color={CLOSED_COLOR}
        xDataKey={xDataKey}
        xLabel={{ value: xLabel, offset: 5, position: 'bottom', fontWeight: 'normal' }}
        yDataKey='sale_price'
        yLabel={{ value: 'SOLD PRICE', angle: -90, position: 'left' }}
        id='colorPvScatter'
        margin={{ top: 30, right: 30, bottom: 30, left: 30 }}
        scatterDataSets={[{ dataKey: 'sale_price', fill: 'url(#colorPvScatter)' }]}
      />
    </ChartContainer>
  );
};

const Description = ({ description }: { description: string }) => (
  <DescriptionContainer>
    <div>{description}</div>
    <div>Click on circles for more info</div>
  </DescriptionContainer>
);

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;
