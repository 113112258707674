import { useState } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import styled from 'styled-components';

import { SearchCombiner } from '../../../pages/PresentationCreate/dash/view/pages/Authenticated/PropertySearch/SearchCombiner';
import { SearchCriteria } from '../../../types';
import { Button } from '../../../components/Common/V2/Button/Button';
import { ManualComparablesContent } from '../components/ManualComparablesContent';
import { useDeleteCustomComparable } from '../hooks/useDeleteCustomComparable';
import { customComparablesAtom } from '../state/customComparablesAtom';
import { CustomComparablesModal } from './CustomComparablesModal/CustomComparablesModal';
import { OffMLSComparable } from './OffMLSComparable';
import { customComparableToEditAtom } from '../state/customComparableToEditAtom';

interface Props {
  onPartial: (criteria: SearchCriteria) => void;
}

export const OffMLSComparables = ({ onPartial }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const customComparables = useAtomValue(customComparablesAtom);
  const setCustomComparableToEdit = useSetAtom(customComparableToEditAtom);

  const { mutateAsync: deleteComparable, isLoading: deleteLoading } = useDeleteCustomComparable();

  const handleDelete = async (id: string) => {
    await deleteComparable(id, {
      onSuccess: () => onPartial(SearchCombiner.combine()),
    });
  };

  const handleOpenEditModal = (id: string) => {
    const comparable = customComparables.find(comparable => comparable.sourceId === id) || null;

    setCustomComparableToEdit(comparable);
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setCustomComparableToEdit(null);
    setIsModalOpen(false);
  };

  return (
    <ManualComparablesContent description='Property is Off-MLS: Manually add address and details.'>
      <ComparablesWrapper>
        {customComparables.map((comparable, index) => (
          <OffMLSComparable
            key={`${comparable.id}-${index}`}
            comparable={comparable}
            onEdit={() => handleOpenEditModal(comparable.sourceId as string)}
            onDelete={() => handleDelete(comparable.sourceId as string)}
            isDisabled={deleteLoading}
          />
        ))}
      </ComparablesWrapper>
      <Button variant='primary' style={{ width: '100%' }} onClick={() => setIsModalOpen(true)}>
        Add Off-MLS Comparable
      </Button>
      <CustomComparablesModal
        isOpen={isModalOpen}
        onClose={handleClose}
        onSuccess={() => onPartial(SearchCombiner.combine())}
      />
    </ManualComparablesContent>
  );
};

const ComparablesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
`;
