import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useAtom, useAtomValue } from 'jotai';
import styled from 'styled-components';

import { COMPARABLE_MODAL_MENU_ITEM_ID } from '../../../../../../../types';
import { ManualEntryMap } from '../../../../../../../components/ManualEntryMap/ManualEntryMap';
import { subjectAtom } from '../../../../../../report/state/subjectAtom';
import { MainSection } from '../MainSection';
import { customComparableToEditAtom } from '../../../../../state/customComparableToEditAtom';
import { useUser } from '../../../../../../../components/Slide/providers/UserProvider';
import { Fields } from './Fields';
import { CanadaUserFields } from './CanadaUserFields';
import { defaultMapCenterAtom } from '../../../../../state/defaultMapCenterAtom';

interface Props {
  isOnMls: boolean;
}

export const RequiredFields = ({ isOnMls }: Props) => {
  const [showInfoText, setShowInfoText] = useState(false);
  const subject = useAtomValue(subjectAtom);
  const customComparableToEdit = useAtomValue(customComparableToEditAtom);
  const { isCanadaUser } = useUser();

  const coordinates = useMemo(
    () => ({
      lat: customComparableToEdit?.coordinates?.latitude || subject.lat || 40.209069,
      lng: customComparableToEdit?.coordinates?.longitude || subject.lng || -98.439192,
    }),
    [customComparableToEdit, subject],
  );

  const [defaultCenter, setDefaultCenter] = useAtom(defaultMapCenterAtom);

  const {
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    if (defaultCenter) return;
    setDefaultCenter(coordinates);
  }, [coordinates, defaultCenter, setDefaultCenter]);

  const markerPositionValue = useWatch({ name: 'markerPosition' });

  const markerPosition = useMemo(() => markerPositionValue || null, [markerPositionValue]);

  const handleMarkerPositionChange: Dispatch<
    SetStateAction<google.maps.LatLngLiteral | null>
  > = position => {
    setValue('markerPosition', position);
    clearErrors('markerPosition');
  };

  const hasErrors = Object.keys(errors).length > 0;
  const infoText = showInfoText
    ? 'Address data sourced from public tax records. Verify for accuracy.'
    : undefined;

  const errorMessage = isOnMls
    ? '* One or more required fields are incomplete. Please ensure all required fields are filled out before creating a custom comparable.'
    : '* One or more required fields are incomplete. Please ensure all required fields are filled out before creating an Off-MLS Comparable.';

  return (
    <MainSection
      title='Required Fields'
      titleId={COMPARABLE_MODAL_MENU_ITEM_ID.REQUIRED_FIELDS}
      infoText={infoText}
      withHorizontalPadding
    >
      {hasErrors && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <Wrapper>
        {isCanadaUser ? (
          <CanadaUserFields
            handleMarkerPositionChange={handleMarkerPositionChange}
            setShowInfoText={setShowInfoText}
            isOnMls={isOnMls}
          />
        ) : (
          <Fields
            handleMarkerPositionChange={handleMarkerPositionChange}
            setShowInfoText={setShowInfoText}
            isOnMls={isOnMls}
          />
        )}
        <MapWrapper>
          <MapDescriptionWrapper>
            <Title>Confirm or edit property location</Title>
            <Description>
              If no pin is visible, drop one to mark the property&apos;s location. If the pin is
              inaccurate, simply move it to the correct spot.
            </Description>
            {errors.markerPosition && (
              <ErrorMessage style={{ marginTop: 0 }}>
                * We couldn&apos;t determine the location of this property. Please click in the map
                to drop a pin at the correct location.
              </ErrorMessage>
            )}
          </MapDescriptionWrapper>
          <ManualEntryMap
            markerPosition={markerPosition}
            setMarkerPosition={handleMarkerPositionChange}
            defaultCenter={defaultCenter}
            zoom={11}
          />
        </MapWrapper>
      </Wrapper>
    </MainSection>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 20px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const MapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const MapDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Title = styled.h4`
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: normal;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;

const Description = styled.p`
  color: ${({ theme }) => theme.colors.v2.text.regular};
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
`;

const GridWrapper = styled.div<{ gridTemplateColumns: string }>`
  display: grid;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  gap: 10px;
`;

const ErrorMessage = styled.span`
  color: ${props => props.theme.colors.v2.status.error};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-top: 5px;
`;
