import { useMutation } from '@tanstack/react-query';
import { cmaApi } from '../../../../pages/PresentationCreate/dash/communicators/cmaApiCommunicator';
import { TAddress, TProperty } from '../../../../types';
import humps from 'humps';

const searchByMlsId = async ({ mlsId, propertyId }: { mlsId: string; propertyId?: string }) => {
  const payload = propertyId
    ? { id: String(mlsId), subject_id: String(propertyId) }
    : { id: String(mlsId) };

  const mlsApiProperties: TProperty[] = await cmaApi.post('/address/get-by-mls-id', payload);

  return mlsApiProperties.map(mlsApiProperty => {
    const property = humps.camelizeKeys(mlsApiProperty) as TProperty & { fullAddress?: TAddress };

    property.address = property.fullAddress ? { ...property.fullAddress } : property.address;
    delete property.fullAddress;

    return property as TProperty;
  });
};

export const useSearchByMlsId = () => {
  return useMutation(searchByMlsId);
};
