import { propertyTypeOptions } from '../../../pages/PresentationCreate/dash/view/pages/Authenticated/PropertySearch/PropertyDetails/PropertySearchCriteria/Criterias/PropertyType/propertyTypeOptions';
import { CurrentUserService } from '../../../services/CurrentUserService';
import { CustomComparableFormData, TProperty } from '../../../types';
import {
  BASEMENT_OPTIONS,
  GARAGE_OPTIONS,
  HOA_FEE_FREQUENCY_OPTIONS,
  LEVELS_OPTIONS,
  LISTING_STATUS_OPTIONS,
  STATES_OPTIONS,
} from '../constants/options';

export const customComparableEntityToFormDataMapper = (
  comparable: TProperty,
): CustomComparableFormData => {
  const isCanadaUser = CurrentUserService.isCanadaUser();
  return {
    address: comparable?.address?.deliveryLine || '',
    city: comparable?.address?.city || '',
    isCanadaUser,
    province: isCanadaUser ? comparable?.address?.state : undefined,
    state: isCanadaUser
      ? null
      : STATES_OPTIONS.find(state => state.value === comparable?.address?.state) || null,
    zip: comparable?.address?.zip || '',
    listingStatus:
      LISTING_STATUS_OPTIONS.find(status => status.value === comparable.status) ||
      LISTING_STATUS_OPTIONS[2],
    propertyType:
      propertyTypeOptions.find(type => type.value === comparable.propertyType) ||
      propertyTypeOptions[0],
    listPrice: comparable.price ? String(comparable.price) : '',
    soldPrice: comparable.salePrice ? String(comparable.salePrice) : undefined,
    bedroomCount: comparable?.beds,
    livingAreaSqft: comparable.size ? String(comparable.size) : '',
    markerPosition: {
      lat: comparable?.coordinates?.latitude || 0,
      lng: comparable?.coordinates?.longitude || 0,
    },
    virtualTour: comparable.virtualTour,
    totalBaths: comparable.totalBaths ? String(comparable.totalBaths) : undefined,
    baths: comparable.baths ? String(comparable.baths) : undefined,
    totalSize: comparable.totalSize ? String(comparable.totalSize) : undefined,
    listDate: comparable.listDateHuman ? new Date(comparable.listDateHuman) : undefined,
    saleDate: comparable.saleDateHuman ? new Date(comparable.saleDateHuman) : undefined,
    yearBuilt: comparable.yearBuilt ? String(comparable.yearBuilt) : undefined,
    mlsId: comparable.mlsId ? String(comparable.mlsId) : '',
    mlsListingId: comparable.id || '',
    description: comparable.description,
    levels: comparable.levels
      ? LEVELS_OPTIONS.find(option => comparable.levels === Number(option.value))
      : undefined,
    isAirConditioning: comparable.isAirConditioning,
    isMasterOnMain: comparable.isMasterOnMain,
    pool: comparable.pool,
    isDeck: comparable.isDeck,
    view: comparable.view,
    basement:
      comparable.basement !== 'none'
        ? BASEMENT_OPTIONS.find(option => option.value.toLowerCase() === comparable.basement)
        : undefined,
    isWaterFront: comparable.isWaterFront,
    isWaterView: comparable.isWaterView,
    isFixerUpper: comparable.isFixerUpper,
    isGreenEnergyStar: comparable.isGreenEnergyStar,
    isNewConstruction: comparable.isNewConstruction,
    isHorseProperty: comparable.isHorseProperty,
    isAdult55: comparable.isAdult55,
    isGolfCourse: comparable.isGolfCourse,
    hoaFee: comparable.hoaFee,
    hoaFeeValue: comparable.hoaFeeValue ? String(comparable.hoaFeeValue) : undefined,
    hoaFeeFreq: comparable.hoaFeeFreq
      ? HOA_FEE_FREQUENCY_OPTIONS.find(option => option.value === comparable.hoaFeeFreq)
      : undefined,
    garage: comparable.garage
      ? GARAGE_OPTIONS.find(option => Number(option.value) === comparable.garage)
      : undefined,
    images:
      comparable?.images?.map((image, index) => ({
        url: image,
        isFavorite: index === 0,
      })) || [],
  };
};
