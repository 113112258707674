import { Dispatch, MutableRefObject, useState } from 'react';
import {
  Active,
  DragEndEvent,
  DragMoveEvent,
  DragOverEvent,
  DragStartEvent,
  Over,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';

import { TSection, TSlideId } from '../../../../../../types';
import { useOrder } from '../../../../../../providers/providers/OrderProvider';
import { getDndSections } from '../getDndSections';
import { useDroppableRecalculation } from './useDroppableRecalculation';

interface Props {
  sectionsOrder: TSection[];
  onSectionsOrderChange: (updatedSectionsOrder: TSection[]) => void;
  onToggle: (sectionId: TSection | null) => void;
  notRemovableSections?: TSlideId[];
  timeoutRef: MutableRefObject<NodeJS.Timeout | null>;
  open: { [key: string]: boolean };
}

export const useDragAndDrop = ({
  sectionsOrder,
  onSectionsOrderChange,
  onToggle,
  notRemovableSections,
  timeoutRef,
  open,
}: Props) => {
  const { setSectionSlidesOrder, sectionsSlide } = useOrder();

  const { updateInt, forceUpdate } = useDroppableRecalculation();

  const [dndElements, setDndElements] = useState<{
    active: Active | null;
    over: Over | null;
  } | null>(null);

  const onDragStart = (event: DragStartEvent & Event): void => {
    const activeId = event.active?.id as TSlideId;

    const disableMove = notRemovableSections?.includes(activeId);

    if (disableMove) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
  };

  const onDragMove = (event: DragMoveEvent): void => {
    const isSectionActive = event?.active?.data?.current?.isSection;
    const isSectionOver = event?.over?.data?.current?.isSection;

    const { active, over } = event;

    const sourceSection = active?.data?.current?.isSection
      ? active.id
      : active?.data?.current?.sectionId;
    const destinationSection = over?.data?.current?.isSection
      ? over.id
      : over?.data?.current?.sectionId;

    if (sourceSection === destinationSection) {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      return;
    }

    const overId = event.over?.id;

    if (isSectionActive) {
      onToggle(null);
      return;
    }

    if (!isSectionActive && isSectionOver && !open[overId as TSection]) {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        onToggle(overId as TSection);
      }, 500);
    }
  };

  const onDragOver = (event: DragOverEvent): void => {
    const { active, over } = event;

    setDndElements({ active, over });
  };

  const onDragEnd = (event: DragEndEvent): void => {
    const { active, over } = event;

    const isActiveSection = active?.data?.current?.isSection;
    const isOverSection = over?.data?.current?.isSection;

    if (isActiveSection && isOverSection) {
      const overSectionId = over?.id as TSlideId;

      if (notRemovableSections?.includes(overSectionId)) return;

      const oldIndex = sectionsOrder.findIndex(item => item === active.id);
      const newIndex = sectionsOrder?.findIndex(item => item === over.id);

      const reorderedItems = arrayMove(sectionsOrder, oldIndex, newIndex);

      onSectionsOrderChange(reorderedItems);
      return;
    }

    const { sourceSection, destinationSection } = getDndSections({ active, over });

    if (sourceSection !== destinationSection) return;

    const activeSlideId = active.id as TSlideId;
    const overSlideId = over?.id as TSlideId;

    const sectionSlides = sectionsSlide[sourceSection as string];
    const sourceIndex = sectionSlides.indexOf(activeSlideId);
    const destinationIndex = sectionSlides.indexOf(overSlideId);

    const reorderedItems = arrayMove(sectionSlides, sourceIndex, destinationIndex);

    setSectionSlidesOrder(sourceSection as string, reorderedItems);
    forceUpdate();
  };

  return {
    onDragStart,
    onDragMove,
    onDragOver,
    onDragEnd,
    setDndElements,
    dndElements,
    updateInt,
  };
};
