import { useMemo } from 'react';
import styled from 'styled-components';
import { ImageProxy } from '../../../components/Slide/services/ImageProxy';

interface Props {
  propertyImages: string[];
}

type TGalleryImage = {
  original: string;
  thumbnail: string;
  thumbnailClass: string;
};

export const ListingGallery = ({ propertyImages }: Props) => {
  const images: TGalleryImage[] = useMemo(
    () =>
      propertyImages
        ?.map(image => ({
          original: image,
          thumbnail: image,
          thumbnailClass: 'custom-thumbnail-class',
        }))
        ?.slice(0, 15),
    [propertyImages],
  );

  const emptyPlaceholders = useMemo(() => {
    if (images.length % 3 === 0) {
      return [];
    }

    return new Array(3 - (images.length % 3))
      .join(',')
      .split(',')
      .map(_ => ({}));
  }, [images.length]);

  const thumbnails = useMemo(() => {
    const [...restOfImages] = images;

    return [...restOfImages, ...emptyPlaceholders];
  }, [emptyPlaceholders, images]);

  if (images.length === 0) {
    // return <NoImagePicture src={noImage} alt='' />;
    return null;
  }

  return (
    <Thumbnails>
      {thumbnails.map((image, i) => (
        <Thumbnail
          key={i}
          style={{
            backgroundImage: `url("${ImageProxy.optimize((image as TGalleryImage)?.thumbnail)}")`,
          }}
        />
      ))}
    </Thumbnails>
  );
};

const NoImagePicture = styled.img`
  width: 100%;
  border-radius: 5px;
  display: block;
`;

const Thumbnails = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
`;

const Thumbnail = styled.div`
  border-radius: 3px;
  background: no-repeat center center;
  -webkit-background-size: cover;
  background-size: cover;
  width: 100%;

  &::before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 0;
    padding-bottom: 80%;
  }
`;
