import styled, { CSSProperties } from 'styled-components';

import { ReactNode } from 'react';

import { Label } from './Label';
import { Value } from './Value';

interface Props {
  hide?: boolean;
  label: string;
  value: string | ReactNode;
  valueStyles?: CSSProperties;
}

export const SectionDetailItem = ({
  hide = false,
  label = '',
  value = '',
  valueStyles = {},
}: Props) => {
  if (hide) return null;

  return (
    <Container>
      <Label>{label}</Label>
      <Value style={valueStyles}>{value}</Value>
    </Container>
  );
};

const Container = styled.div<{ multiline?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 13px;
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  line-height: normal;
  color: #444;
`;
