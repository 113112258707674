import { useMemo } from 'react';
import { useAtomValue } from 'jotai';

import { pricingRateOfChangeAtom } from '../../state/pricingRateOfChangeAtom';
import { reportPropertiesAtom } from '../../state/reportPropertiesAtom';

export function usePricingRateOfChangeData() {
  const data = useAtomValue(pricingRateOfChangeAtom);
  const comparableProperties = useAtomValue(reportPropertiesAtom);

  const mappedProperties = data?.props.map(property => {
    const comparableProperty = comparableProperties.find(
      comparableProperty => comparableProperty.property.id === property.id,
    );

    return {
      ...property,
      isCustom: comparableProperty?.property.isCustom,
    };
  });

  return useMemo(() => {
    const props = mappedProperties || [];
    const text = data?.text;

    return { props, text };
  }, [data, mappedProperties]);
}
