import { TProperty } from '../../../types';
import { ManualComparable } from '../components/ManualComparable';

interface Props {
  comparable: TProperty;
  isDisabled: boolean;
  onDelete: () => void;
  onEdit: () => void;
  withDnD?: boolean;
  index?: number;
  notActive?: boolean;
}

export const OffMLSComparable = ({
  comparable,
  isDisabled,
  onEdit,
  onDelete,
  withDnD,
  index,
  notActive,
}: Props) => {
  return (
    <ManualComparable
      comparableTitle={comparable.address.deliveryLine}
      isDisabled={isDisabled}
      onDelete={onDelete}
      onEdit={onEdit}
      withDnD={withDnD}
      draggableId={comparable.sourceId || comparable.id}
      index={index}
      notActive={notActive}
    />
  );
};
