import React, { useEffect } from 'react';

import {
  Container,
  ContentContainer,
  ContentHeader,
  ContentBody,
  Typography,
  LeftCol,
  RightCol,
  ManualListContainer,
} from './BuyerTourCreatorContent.styles';

import { Map } from '../../Map/Map.component';
import { AddClient } from './AddClient/AddClient.component';
import { useProperties } from '../../../../../providers/providers/PropertiesProvider';
import { useSetBuyerTourDefaultPresentationConfigs } from '../useSetBuyerTourDefaultPresentationConfigs';
import { ManualMLSList } from '../../../../../pages/PresentationCreate/dash/view/pages/Authenticated/PropertySearch/PropertyDetails/ManualMLSList/ManualMLSList';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { Properties } from './Properties/Properties';
import { FEATURES, useHasFeatureFlag } from '../../../../../hooks/useFeatureFlags';

export const BuyerTourCreatorContent = () => {
  useSetBuyerTourDefaultPresentationConfigs();

  const hasCustomComparables = useHasFeatureFlag(FEATURES.CpCustomComparables);

  const { properties, addProperty, removeProperty, updateProperty, setProperties } =
    useProperties();

  const [errors, setErrors] = React.useState({});

  const mlsIds = React.useMemo(() => properties.map(p => p.id), [properties]);

  const activeProperties = React.useMemo(
    () => properties.filter(p => p.status === 'active'),
    [properties],
  );

  useEffect(() => window.scrollTo(0, 0), []);

  const onDragEnd = React.useCallback(
    (result: DropResult): void => {
      if (!result.destination) {
        return;
      }

      const sourceIndex = result.source.index;
      const destinationIndex = result.destination.index;
      if (properties[destinationIndex].status !== 'active') return;
      const updatedProperties = [...properties];
      const movingItem = updatedProperties.splice(sourceIndex, 1);
      updatedProperties.splice(destinationIndex, 0, movingItem[0]);
      setProperties([...updatedProperties, ...properties.filter(p => p.status !== 'active')]);
    },
    [properties, setProperties],
  );

  return (
    <Container>
      <ContentContainer>
        <ContentHeader>
          <Typography variant='h3' style={{ lineHeight: '21.09px', marginBottom: 2 }}>
            Add MLS listings to your Buyer Tour{' '}
          </Typography>
          <Typography variant='body2' style={{ lineHeight: '15.23px' }}>
            Enter the MLS ID for your desired listings. Once entered the property will populate on
            the map.
          </Typography>
        </ContentHeader>

        <ContentBody>
          <LeftCol>
            {hasCustomComparables ? (
              <Properties />
            ) : (
              <ManualListContainer>
                <DragDropContext onDragEnd={onDragEnd}>
                  <ManualMLSList
                    withDnD
                    className={'simple'}
                    mlsIds={mlsIds}
                    subjectId={null}
                    errors={errors}
                    activeProperties={activeProperties}
                    setErrors={setErrors}
                    addProperty={addProperty}
                    removeProperty={removeProperty}
                    onlyActive
                    onUpdate={updateProperty}
                  />
                </DragDropContext>
              </ManualListContainer>
            )}
            <AddClient />
          </LeftCol>

          <RightCol>
            <Map properties={[...activeProperties]} useNumberedMarkers />
          </RightCol>
        </ContentBody>
      </ContentContainer>
    </Container>
  );
};
