import { useMutation } from '@tanstack/react-query';
import { corePresentApi } from '../../../../../api/CorePresentApi';
import { toast } from 'react-toastify';
import { SendEmailPayload } from '../../../../../types';

async function sendEmail(payload: SendEmailPayload) {
  return corePresentApi.post('/clients/email', payload);
}

export function useSendEmail() {
  return useMutation(sendEmail, {
    onSuccess: (_, variables) => {
      toast('Email was sent.', {
        type: 'success',
        position: 'top-center',
      });
    },
    onError: () => {
      toast('There was an error. Make sure your kVCORE client has emailing enabled.', {
        type: 'error',
        position: 'top-center',
        autoClose: 4000,
      });
    },
  });
}
