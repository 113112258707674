import styled from 'styled-components';
import { TextCell } from './TextCell';
import { useCallback, useState } from 'react';
import { PRESENTATION_TYPES } from '../../../../../../../constants/PresentationTypes';
import { TPresentationType } from '../../../../../../../types';
import { TPresentationTypeApi } from '../../../types';
import { useHistory } from 'react-router-dom';
import { AuthenticatedRoutes, Routes } from '../../../../../../Router/Routes';
import { Button } from '../../../../../../../components/Common/V2/Button/Button';
import { Icon } from '../../../../../../../components/Common';
import { getHasNewCreationFlow } from '../../../../../../../services/getHasNewCreationFlow';
import { AuthenticatedRouter } from '../../../../../../Router/AuthenticatedRouter';
import { buildPresentationCreationSearchParams } from '../../../../../../../services/buildPresentationCreationSearchParams';
import { useRebuildPresentation } from '../../../../../../../hooks/useRebuildPresentation';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
`;

interface Props {
  lastUpdatedDate: string;
  presentationHash: string;
  presentationType: TPresentationTypeApi;
  presentationId: number;
  canBeRebuilt?: boolean;
}

export const LastUpdatedCell = ({
  lastUpdatedDate,
  presentationHash,
  presentationType,
  presentationId,
  canBeRebuilt,
}: Props) => {
  const [rebuildInProgress, setRebuildInProgress] = useState(false);
  const { push } = useHistory();
  const hasNewCreationFlow = getHasNewCreationFlow();
  const { mutateAsync: rebuildPresentation } = useRebuildPresentation();

  const onRebuild = useCallback(
    async (hash: string, type: TPresentationType, presentationId: number) => {
      let presId = String(presentationId);
      let presentationHash = hash;

      if (
        type === PRESENTATION_TYPES.WIN_THE_LISTING ||
        type === PRESENTATION_TYPES.WIN_THE_OFFER ||
        type === PRESENTATION_TYPES.BUYER_TOUR
      ) {
        const rebuilt = await rebuildPresentation(presentationId);

        presId = rebuilt.id || '';
        presentationHash = rebuilt.hash || '';
      }

      const params = buildPresentationCreationSearchParams({
        type,
        action: 'rebuild',
        presentationHash: presentationHash,
        presentationId: presId,
      });
      const queryParams = `?${params}`;

      const presentationLink = hasNewCreationFlow
        ? AuthenticatedRoutes.details
        : `/create/search/details`;

      const pages: Record<TPresentationType, string> = {
        [PRESENTATION_TYPES.BUYER_TOUR]: Routes.presentationNew,
        [PRESENTATION_TYPES.WIN_THE_LISTING]: presentationLink,
        [PRESENTATION_TYPES.WIN_THE_OFFER]: presentationLink,
        [PRESENTATION_TYPES.PRESENT_INFO]: Routes.presentationNew,
        [PRESENTATION_TYPES.WIN_THE_REPRESENTATION]: Routes.presentationNew,
      };

      if (!pages[type]) return;

      push(pages[type] + queryParams);
    },
    [hasNewCreationFlow, push, rebuildPresentation],
  );

  const onRebuildClick = useCallback(() => {
    const typeToPresentationType = {
      seller: PRESENTATION_TYPES.WIN_THE_LISTING,
      buyer: PRESENTATION_TYPES.WIN_THE_OFFER,
      info: PRESENTATION_TYPES.PRESENT_INFO,
      [PRESENTATION_TYPES.BUYER_TOUR]: PRESENTATION_TYPES.BUYER_TOUR,
      [PRESENTATION_TYPES.WIN_THE_REPRESENTATION]: PRESENTATION_TYPES.WIN_THE_REPRESENTATION,
    };

    onRebuild(
      presentationHash,
      typeToPresentationType[presentationType] as TPresentationType,
      presentationId,
    );
    setRebuildInProgress(true);
  }, [onRebuild, presentationHash, presentationType, presentationId]);

  return (
    <Container>
      <TextCell text={lastUpdatedDate} />
      <Button
        variant='primary'
        prefixIcon={<Icon iconName='rebuild' fill='#fff' />}
        isLoading={rebuildInProgress}
        disabled={rebuildInProgress || !canBeRebuilt}
        onClick={onRebuildClick}
      >
        Update
      </Button>
    </Container>
  );
};
