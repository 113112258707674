import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { AutosuggestAddresses } from '../AutosuggestAddresses';
import { FormWrapper, GridWrapper } from './common';
import { Dispatch, SetStateAction } from 'react';
import { Select } from '../../../../../../../components/Common/Select/Select.component';
import { Input } from '../../../../../../../components/Common/V2/Input';
import { LISTING_STATUS_OPTIONS, STATES_OPTIONS } from '../../../../../constants/options';
import { propertyTypeOptions } from '../../../../../../../pages/PresentationCreate/dash/view/pages/Authenticated/PropertySearch/PropertyDetails/PropertySearchCriteria/Criterias/PropertyType/propertyTypeOptions';
import NumberFormat from 'react-number-format';

interface Props {
  handleMarkerPositionChange: Dispatch<SetStateAction<google.maps.LatLngLiteral | null>>;
  setShowInfoText: (value: boolean) => void;
  isOnMls?: boolean;
}

export const CanadaUserFields = ({
  handleMarkerPositionChange,
  setShowInfoText,
  isOnMls,
}: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const selectedStatus = useWatch({ name: 'listingStatus' });

  return (
    <>
      <FormWrapper>
        <GridWrapper gridTemplateColumns='2fr 1fr'>
          <AutosuggestAddresses
            setMarkerPosition={handleMarkerPositionChange}
            setShowInfoText={setShowInfoText}
            isOnMls={isOnMls}
          />
          <Controller
            name='aptOrSuite'
            control={control}
            defaultValue={null}
            render={({ field }) => <Input {...field} label='Apt or Suite #:' />}
          />
        </GridWrapper>
        <GridWrapper gridTemplateColumns='1fr 1fr'>
          <Controller
            name='city'
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label='City:'
                required
                error={errors.city ? (errors.city?.message as string) : undefined}
                hideErrorMessage
              />
            )}
          />
          <Controller
            name='province'
            control={control}
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  label='Province:'
                  required
                  error={errors.province ? (errors.province.message as string) : undefined}
                  hideErrorMessage
                />
              );
            }}
          />
        </GridWrapper>
        <GridWrapper gridTemplateColumns='1fr 1fr'>
          <Controller
            name='zip'
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label='Postal Code:'
                type='number'
                required
                error={errors.zip ? (errors.zip.message as string) : undefined}
                hideErrorMessage
              />
            )}
          />
        </GridWrapper>
      </FormWrapper>
      <FormWrapper>
        <GridWrapper gridTemplateColumns='1fr 1fr'>
          <Controller
            name='listingStatus'
            control={control}
            defaultValue={LISTING_STATUS_OPTIONS[2]}
            render={({ field }) => (
              <Select
                {...field}
                label='Listing Status:'
                required
                defaultValue={field.value}
                options={LISTING_STATUS_OPTIONS}
                error={errors.listingStatus ? (errors.listingStatus?.message as string) : undefined}
              />
            )}
          />
          <Controller
            name='propertyType'
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                required
                label='Property Type:'
                defaultValue={field.value}
                options={propertyTypeOptions}
                error={errors.propertyType ? (errors.propertyType?.message as string) : undefined}
              />
            )}
          />
        </GridWrapper>
        <GridWrapper gridTemplateColumns='1fr 1fr'>
          <Controller
            name='listPrice'
            control={control}
            render={({ field }) => (
              <NumberFormat
                customInput={Input}
                required
                label='List Price:'
                value={field.value}
                onValueChange={({ floatValue }) => {
                  field.onChange(floatValue);
                }}
                onBlur={field.onBlur}
                thousandSeparator={true}
                prefix={'$'}
                error={errors.listPrice ? (errors.listPrice.message as string) : undefined}
                hideErrorMessage
              />
            )}
          />
          {selectedStatus && selectedStatus.value === 'sold' && (
            <Controller
              name='soldPrice'
              control={control}
              render={({ field }) => (
                <NumberFormat
                  customInput={Input}
                  required
                  label='Sold Price:'
                  value={field.value}
                  onValueChange={({ floatValue }) => {
                    field.onChange(floatValue);
                  }}
                  onBlur={field.onBlur}
                  thousandSeparator={true}
                  prefix={'$'}
                  hideErrorMessage
                />
              )}
            />
          )}
          <Controller
            name='bedroomCount'
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label='Bedroom Count:'
                type='number'
                required
                error={errors.bedroomCount ? (errors.bedroomCount.message as string) : undefined}
                hideErrorMessage
              />
            )}
          />
        </GridWrapper>
      </FormWrapper>
    </>
  );
};
