import { TProperty } from '../../../../types';
import { TextDescription, Wrapper } from '../components/UI';
import { SectionTitle } from '../components/SectionTitle';

interface Props {
  property: TProperty;
}

export const Description = ({ property }: Props) => {
  return (
    <Wrapper>
      <SectionTitle>Description</SectionTitle>
      <TextDescription>{property.description || 'Unavailable'}</TextDescription>
    </Wrapper>
  );
};
