import { TCustomComparableImage } from '../../../../../../../../types';

export const getValidImages = (images: TCustomComparableImage[]) => {
  const hasFavoriteImage = images.some((val: TCustomComparableImage) => val.isFavorite);
  if (hasFavoriteImage) {
    return images;
  }

  return images?.map((image, i) => {
    if (i === 0) {
      return {
        ...image,
        isFavorite: true,
      };
    }
    return image;
  });
};
