import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Icon } from '../../../../../components/Common';
import { Button } from '../../../../../components/Common/V2/Button/Button';
import { FEATURES, useHasFeatureFlag } from '../../../../../hooks/useFeatureFlags';
import { useWhiteLabelSettings } from '../../../../../features/whitelabel/useWhiteLabelSettings';
import { HomeCard } from '../../../../../types';
import { PRESENTATION_TYPES } from '../../../../../constants/PresentationTypes';
import { PresentationApi } from '../../../../Presentation/api/PresentationApi';

interface PresentationCardProps {
  data: HomeCard;
}

const PresentationCard: React.FC<PresentationCardProps> = ({ data }) => {
  const { title, url, list, presentationType } = data;
  const shouldUseBtTheme = useHasFeatureFlag(FEATURES.BoldTrailTheme);

  const history = useHistory();

  const { hasWL, primary } = useWhiteLabelSettings();

  const checkmarkIcon = shouldUseBtTheme ? 'circle_checkmark_bt' : 'circle_checkmark';

  const fill = hasWL ? primary : '';

  const onStartPresentation = async () => {
    if (presentationType === PRESENTATION_TYPES.BUYER_TOUR) {
      const { id, hash: presHash } = await PresentationApi.create(
        {
          type: PRESENTATION_TYPES.BUYER_TOUR,
        },
        null,
        null,
        false,
      );

      history.push(`${url}&presentation_id=${id}&presentation=${presHash}`);
      return;
    }

    history.push(url);
  };

  return (
    <CardContainer>
      <CardHeader>{title}</CardHeader>
      <ListWrapper>
        <ul>
          {list.map((item, i) => (
            <li key={`${item.name}_${i}`} className={item.enabled ? '' : 'disabled'}>
              <Icon
                iconName={item.enabled ? checkmarkIcon : 'circle_crossmark'}
                fill={item.enabled ? fill : ''}
              />
              {item.name}
            </li>
          ))}
        </ul>
      </ListWrapper>
      <ButtonWrapper>
        <Button onClick={onStartPresentation}>Start</Button>
      </ButtonWrapper>
    </CardContainer>
  );
};

export default PresentationCard;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.colors.v2.gray[200]}`};
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
  min-width: 272px;
  background-color: #fff;

  @media (max-width: 980px) {
    min-width: 100%;
  }
`;

const CardHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.v2.gray[100]};
  padding: 16px 20px;
  color: ${({ theme }) => theme.colors.v2.gray[400]};
  border-radius: 8px 8px 0 0;
  font-family: Figtree;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
`;

const ListWrapper = styled.div`
  padding: 15px 20px;
  font-family: Figtree;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #444;

  ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .disabled {
    color: #aaa;
  }
`;

const ButtonWrapper = styled.div`
  padding: 0px 20px 20px;

  & > button {
    width: 100%;
    font-size: 14px;
    font-family: Figtree;
    font-weight: 700;
    justify-content: center;
  }
`;
