import { Draggable } from 'react-beautiful-dnd';
import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './ManualMLS.module.scss';
import { fetchProperties } from './fetchProperties';
import { Icon } from '../../../../../../../../../../components/Common';
import { searchByMlsId } from '../../../../../../../communicators/Address/AddressCommunicator';
import { MultiplePropertySourcesPopup } from './MultiplePropertySourcesPopup/MultiplePropertySourcesPopup';
import { createSvgWithText } from '../../../../../../../../../../features/BuyerTour/components/Map/Markers/Markers.component';
import { Input } from '../../../../../../../../../../components/Common/V2/Input';
import { faAngleRight, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../../../../../../../../../components/Common/V2/Button/Button';
import { useTheme } from 'styled-components';
import { getCompositeKey } from '../../../../../../../../../../services/getCompositeKey';

const valueToUniqueIds = value => {
  if (!value) return [];

  const csvIds = String(value)
    .trim()
    .replace(/\s+|,+/g, ',');

  return [...new Set(csvIds.split(','))].filter(_m => _m && _m.trim());
};

const getValueParameters = value => {
  if (value && value.includes(':composite:')) {
    const splitted = value.split(':composite:');
    return {
      mlsId: splitted[0],
      listingId: splitted[1],
    };
  }

  return {
    listingId: value,
  };
};

export const ManualMLS = React.memo(function ManualMLS({
  value = '',
  onRemove,
  onAdd,
  mlsIds = [],
  subjectId,
  onlyActive,
  onUpdate = f => f,
  error = '',
  setErrors,
  errors = {},
  innerValue,
  setInnerValue,
  style = {},
  index,
  withDnD = false,
  notActive = false,
  propertyMlsId,
}) {
  const [loading, setLoading] = useState(false);
  const [isInactive, setIsInactive] = useState(false);
  const [modalProperties, setModalProperties] = useState({});
  const notFoundMls = Object.keys(errors).filter(key => errors[key] === 404);
  const { colors } = useTheme();

  const hasMultipleResults = useMemo(
    () => !!Object.keys(modalProperties ?? {}).length,
    [modalProperties],
  );

  const valueParams = useMemo(() => getValueParameters(value), [value]);

  const onClosePropertyModal = () => {
    setModalProperties({});
    setInnerValue('');
    setLoading(false);
  };

  const onConfirmPropertyModal = selectedProperties => {
    const ids = selectedProperties.map(selection => selection?.uuid);
    const _mlsIds = ids.filter(_m => !mlsIds.includes(_m));

    onAdd(
      _mlsIds,
      selectedProperties.map(selection => selection?.property),
    );

    setModalProperties({});
    setInnerValue('');
    setLoading(false);
  };

  useEffect(() => {
    const fetchAndMarkInactive = async value => {
      if (!valueParams.listingId) return;

      try {
        const listings = await searchByMlsId(valueParams.listingId);
        const listing = propertyMlsId ? listings?.find(l => l.mlsId === propertyMlsId) : null;

        if (listing?.status === 'active') return;

        setIsInactive(true);
        setErrors(prev => ({
          ...prev,
          [value]: 'Property not active',
        }));
      } catch (e) {
        setIsInactive(true);
      }
    };

    if (onlyActive) {
      fetchAndMarkInactive(value);
    }
  }, [value, onlyActive, valueParams, propertyMlsId]);

  const triggerAddMls = async id => {
    if (!id) return;

    const ids = valueToUniqueIds(id).filter(_m => !mlsIds.includes(_m));

    setLoading(true);

    try {
      const { properties, errors, hasErrors, multipleResults, hasMultipleResults } =
        await fetchProperties(ids, subjectId, searchByMlsId);

      const filteredIds = ids
        .filter(_id => errors[_id] !== 404 && !multipleResults[_id])
        ?.map(_id => {
          const property = properties.find(p => p.id === _id);
          return getCompositeKey(property);
        });

      onAdd(filteredIds, properties);

      if (hasErrors) {
        setLoading(false);
        setErrors({ ...errors });
        setInnerValue(ids.join(', '));
        return;
      }

      if (hasMultipleResults) {
        setModalProperties(multipleResults);
        return;
      }

      setLoading(false);
      setInnerValue('');
    } catch (e) {
      console.error(e);
    }
  };

  const onAction = () => {
    if (onAdd) {
      return triggerAddMls(innerValue);
    }

    if (onRemove) {
      setErrors(prev => {
        delete prev[value];
        return prev;
      });
      onRemove(value);
    }
  };

  const onChangeInnerValue = ({ target }) => {
    // setErrors('');
    setInnerValue(target.value);
  };

  const clearNotFoundErrors = () => {
    setErrors(prev => {
      const errs = { ...prev };
      for (const key in prev) {
        if (errs[key] === 404) errs[key] = '';
      }

      return errs;
    });
  };

  const DraggableWrapper = ({ children }) => (
    <Draggable draggableId={value} index={index} isDragDisabled={notActive}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          {children}
        </div>
      )}
    </Draggable>
  );

  const inputUniqueIds = valueToUniqueIds(innerValue);
  const commonStyles = { position: 'absolute', top: '50%', transform: 'translateY(-50%)' };

  const Wrapper = withDnD ? DraggableWrapper : React.Fragment;
  const icon = withDnD && !notActive ? createSvgWithText(index + 1) : null;

  return (
    <Wrapper>
      <MultiplePropertySourcesPopup
        properties={modalProperties}
        open={hasMultipleResults}
        onConfirm={onConfirmPropertyModal}
        onClose={onClosePropertyModal}
        onCancel={onClosePropertyModal}
      />

      <div className={styles.root} style={style}>
        {withDnD && !notActive && <Icon iconName='drag' style={{ ...commonStyles, left: 0 }} />}
        {icon && <img src={icon} style={{ ...commonStyles, width: 12, left: 23 }} />}
        <div className={`${styles.item} ${isInactive ? styles.inactive : ''}`}>
          <Input
            autoFocus
            type='text'
            value={valueParams.listingId || innerValue}
            disabled={!!onRemove}
            placeholder='Enter MLS IDs'
            onChange={onChangeInnerValue}
            wrapperStyle={{ width: '100%' }}
            inputWrapperStyle={{ width: '100%', border: `1px solid ${colors.v2.gray[300]}` }}
            inputStyle={{ color: colors.v2.gray[400] }}
            innerSufix={
              onAdd && (
                <Button
                  variant='primary'
                  style={{ borderRadius: '0px 5px 5px 0px' }}
                  isLoading={loading}
                  onClick={onAction}
                >
                  <FontAwesomeIcon icon={faAngleRight} />
                </Button>
              )
            }
            outerSuffix={
              onRemove && (
                <Button variant='primary' isLoading={loading} onClick={onAction}>
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              )
            }
          />

          {!notFoundMls.length ? null : (
            <div
              style={{
                position: 'absolute',
                right: '40px',
                left: '10px',
                background: '#fff',
                overflow: 'visible',
                width: 'auto',
                wordBreak: 'break-word',
                height: '22px',
                minHeight: '22px',
                padding: 0,
                top: '8px',
              }}
              onMouseDown={clearNotFoundErrors}
            >
              {inputUniqueIds.map((_mlsId, i, arr) => {
                const isLast = i === arr.length - 1;
                if (errors[_mlsId] === 404)
                  return (
                    <React.Fragment key={_mlsId}>
                      <span style={{ color: 'red' }}>{_mlsId}</span>
                      {!isLast && ', '}
                    </React.Fragment>
                  );

                return (
                  <span key={_mlsId}>
                    {_mlsId}
                    {isLast ? '' : ', '}
                  </span>
                );
              })}

              <div
                style={{
                  fontSize: 11,
                  marginTop: '20px',
                }}
              >
                {inputUniqueIds.length === 1 && notFoundMls.length === 1 ? (
                  <span style={{ color: 'red' }}>Invalid MLS ID!</span>
                ) : (
                  <>
                    Comma separated, <span style={{ color: 'red' }}>red</span> means invalid ID
                  </>
                )}
              </div>
            </div>
          )}
        </div>
        {error && <span className={styles.error}>{error}</span>}
      </div>
    </Wrapper>
  );
});
