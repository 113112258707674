import { useState } from 'react';

export function useDroppableRecalculation() {
  const [updateInt, setUpdateInt] = useState(0);

  function forceUpdate() {
    setUpdateInt(prev => prev + 1);
  }

  return { forceUpdate, updateInt };
}
