import styled from 'styled-components';
import { Checkbox } from '../../../../../../../../components/Common/V2/Checkbox';
import { Select } from '../../../../../../../../components/Common';
import { forwardRef, useEffect, useState } from 'react';
import {
  SelectOption,
  SelectOptionChangeEventValue,
} from '../../../../../../../../components/Common/Select/Select.component';

interface Props {
  options: SelectOption[];
  disabled?: boolean;
  placeholder?: string;
  onChange: (selectedOption: SelectOptionChangeEventValue | SelectOptionChangeEventValue[]) => void;
  defaultValue?: SelectOption[];
  label?: string;
  required?: boolean;
  error?: string;
  selectedOption: SelectOption | undefined;
  automaticallySelectedOption?: SelectOption;
  uncheckedOption?: SelectOption;
}

export const CheckboxWithSelect = forwardRef<HTMLDivElement, Props>(
  (
    {
      onChange,
      defaultValue,
      options,
      label,
      selectedOption,
      automaticallySelectedOption,
      uncheckedOption,
      ...props
    },
    ref,
  ) => {
    const [isSelected, setIsSelected] = useState(false);

    useEffect(() => {
      setIsSelected(Boolean(selectedOption) && selectedOption?.value !== uncheckedOption?.value);
    }, [automaticallySelectedOption?.value, selectedOption, uncheckedOption?.value]);

    const onToggleSelect = () => {
      if (isSelected) {
        setIsSelected(false);
        onChange(null);
        return;
      }

      setIsSelected(true);
      onChange(automaticallySelectedOption || options[0]);
    };

    return (
      <Wrapper ref={ref}>
        <Checkbox value={isSelected} onChange={onToggleSelect} />
        <Select
          label={label}
          defaultValue={defaultValue}
          onChange={onChange}
          options={options}
          {...props}
        />
      </Wrapper>
    );
  },
);

CheckboxWithSelect.displayName = 'CheckboxWithSelect';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 20px;
`;
