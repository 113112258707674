import styled from 'styled-components';
import {
  COMPARABLE_MODAL_MENU_ITEM_ID,
  COMPARABLE_MODAL_MENU_SUBITEM_ID,
} from '../../../../../types';
import { useEffect, useRef } from 'react';
import { sideMenuItems } from './sideMenuItems';
import { OptionalFields } from './components/OptionalFields/OptionalFields';
import { RequiredFields } from './components/RequiredFields/RequiredFields';

interface Props {
  setActiveMainItemId: (id: COMPARABLE_MODAL_MENU_ITEM_ID) => void;
  setActiveSubItemId: (id: COMPARABLE_MODAL_MENU_SUBITEM_ID | null) => void;
  isOnMls: boolean;
}

export const ComparablesModalContent = ({
  setActiveMainItemId,
  setActiveSubItemId,
  isOnMls,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    const handleScroll = () => {
      sideMenuItems.forEach(item => {
        const element = document.getElementById(item.id);
        const rect = element?.getBoundingClientRect();

        if (
          rect &&
          container?.clientHeight &&
          rect.top <= container?.clientHeight / 1.8 &&
          rect.bottom >= container?.clientHeight / 1.8
        ) {
          setActiveMainItemId(item.id);
        }

        if (item.subItems) {
          item.subItems.forEach(subItem => {
            const subElement = document.getElementById(subItem.id);
            const subRect = subElement?.getBoundingClientRect();
            if (
              subRect &&
              container?.clientHeight &&
              subRect.top <= container?.clientHeight / 1.8 &&
              subRect.bottom >= container?.clientHeight / 1.8
            ) {
              setActiveSubItemId(subItem.id);
            }
          });
        }
      });
    };

    if (!container) return;

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, [setActiveMainItemId, setActiveSubItemId]);

  return (
    <Container ref={containerRef}>
      <RequiredFields isOnMls={isOnMls} />
      <OptionalFields />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 72%;
  padding: 0 0 20px 0;
  position: relative;
`;
