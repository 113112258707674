import React, { useEffect, useMemo } from 'react';

import styled from 'styled-components';
import { usePreviewMode } from '../../providers/PreviewModeProvider';
import {
  TPresentationType,
  TSection,
  TSectionConfig,
  TSlide,
  TSlideId,
} from '../../../../../types';
import { useOrder } from '../../../../../providers/providers/OrderProvider';
import { useSlides } from '../../../../../providers/providers/SlidesProvider';
import { RegularDynamicOrderableSlides } from './RegularDynamicOrderableSlides.component';
import { useSections } from '../../../../../providers/providers/SectionsProvider';
import { useSelectedSlide } from '../../../../../providers/providers/SelectedSlideProvider';
import { usePresentationMode } from '../../../../../providers/providers/PresentationModeProvider';
import { useActiveSection } from '../../../../../components/Slide/providers/ActiveSectionProvider';
import { DynamicOrderableSections } from '../DynamicOrderableSections/DynamicOrderableSections.component';
import { usePresentationType } from '../../../../../providers/providers/PresentationTypeProvider';
import { PRESENTATION_TYPES } from '../../../../../constants/PresentationTypes';

import { AddSlides } from '../AddSlides/AddSlides';
import { useHasSelectedSlide } from '../../../../../hooks/useHasSelectedSlide';

const regularTypes: TPresentationType[] = [
  PRESENTATION_TYPES.WIN_THE_LISTING,
  PRESENTATION_TYPES.WIN_THE_OFFER,
];

function useActivateFirstSectionAndFocusSlide() {
  const { slides, getSlide } = useSlides();
  const { presentationMode } = usePresentationMode();
  const { presentationType } = usePresentationType();
  const { sections: sectionsOrder, sectionsSlide, getOrderedSlides } = useOrder();
  const { previewMode } = usePreviewMode();
  const { setActiveSection } = useActiveSection();
  const { selectedSlide, setSelectedSlide } = useSelectedSlide();

  const coverSlide = getSlide('coverSlide');

  const hasSelectedSlide = useHasSelectedSlide();

  useEffect(() => {
    if (selectedSlide?.id === 'sectionContents' || !coverSlide) return;
    if ((previewMode === 'pdf' && selectedSlide?.id === 'greetingVideo') || !hasSelectedSlide) {
      setSelectedSlide(coverSlide);
    }
  }, [selectedSlide, previewMode, coverSlide, setSelectedSlide, hasSelectedSlide]);

  useEffect(
    function regularPresentation() {
      if (selectedSlide || !regularTypes.includes(presentationType)) return;

      const [firstSectionId] = sectionsOrder ?? [];
      if (!firstSectionId) return;

      setActiveSection(coverSlide ? firstSectionId : null);
      setSelectedSlide(coverSlide ?? null);
    },
    [
      presentationMode,
      presentationType,
      selectedSlide,
      sectionsOrder,
      sectionsSlide,
      slides,
      setActiveSection,
      coverSlide,
      setSelectedSlide,
    ],
  );

  useEffect(
    function presentInfoPresentation() {
      if (selectedSlide || presentationType !== PRESENTATION_TYPES.PRESENT_INFO) return;

      const [firstSectionId] = sectionsOrder ?? [];
      const [firstSlide] = getOrderedSlides();

      if (!firstSectionId || !firstSlide) return;

      setActiveSection(firstSectionId ?? null);
      setSelectedSlide(slides?.[firstSlide] ?? null);
    },
    [
      presentationMode,
      presentationType,
      selectedSlide,
      sectionsOrder,
      sectionsSlide,
      slides,
      getOrderedSlides,
      setActiveSection,
      setSelectedSlide,
    ],
  );
}

export const RegularDynamicSections = () => {
  const { sections } = useSections();
  const { sections: sectionsOrder, findSlideSection, setSectionsOrder, sectionsSlide } = useOrder();
  const { presentationType } = usePresentationType();
  const { slides } = useSlides();

  useActivateFirstSectionAndFocusSlide();

  const findSection = (slide: TSlide) => {
    const sectionId = findSlideSection(slide.id);
    if (!sectionId) return null;

    return (sections[sectionId] as TSectionConfig) ?? null;
  };

  const showAddSlidesButton = useMemo(() => {
    const hasCompanyPresentation =
      slides && Object.values(slides).some(s => s.template === 'pdfPresentationTemplate');
    return regularTypes.includes(presentationType) && hasCompanyPresentation;
  }, [presentationType, slides]);

  const notRemovableSections = React.useMemo(
    () => ['startingSlides', 'closingAndNextSteps'],
    [],
  ) as TSlideId[];

  return (
    <>
      {showAddSlidesButton && <AddSlides section={'startingSlides'} btnStyle='option' />}
      <DynamicOrderableSections
        sectionsOrder={sectionsOrder as TSection[]}
        onSectionsOrderChange={setSectionsOrder}
        sections={sections}
        extractSlideSection={findSection}
        OrderableSlidesComponent={RegularDynamicOrderableSlides}
        slidesOrderBySections={sectionsSlide}
        notRemovableSections={notRemovableSections}
        notMovableAbove={['coverSlide', 'greetingVideo']}
      />
    </>
  );
};
