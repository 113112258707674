import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { BASEMENT_OPTION, COMPARABLE_MODAL_MENU_SUBITEM_ID } from '../../../../../../../types';
import { Checkbox } from '../../../../../../../components/Common/V2/Checkbox';
import { BASEMENT_OPTIONS, GARAGE_OPTIONS } from '../../../../../constants/options';
import { CheckboxWithSelect } from './components/CheckboxWithSelect';
import { SubSection } from '../SubSection';

export const ExteriorFeatures = () => {
  const { control } = useFormContext();

  return (
    <SubSection
      title='Exterior Features'
      titleId={COMPARABLE_MODAL_MENU_SUBITEM_ID.EXTERIOR_FEATURES}
      withHorizontalPadding
    >
      <Wrapper>
        <ColWrapper>
          <Controller
            name='pool'
            control={control}
            defaultValue={false}
            render={({ field }) => <Checkbox {...field} label='Property Pool' />}
          />
          <Controller
            name='view'
            control={control}
            defaultValue={false}
            render={({ field }) => <Checkbox {...field} label='View' />}
          />
          <Controller
            name='isWaterFront'
            control={control}
            defaultValue={false}
            render={({ field }) => <Checkbox {...field} label='Water Front' />}
          />
          <Controller
            name='isWaterView'
            control={control}
            defaultValue={false}
            render={({ field }) => <Checkbox {...field} label='Water View' />}
          />
        </ColWrapper>
        <ColWrapper>
          <Controller
            name='isDeck'
            control={control}
            defaultValue={false}
            render={({ field }) => <Checkbox {...field} label='Deck' />}
          />

          <Controller
            name='basement'
            control={control}
            render={({ field }) => (
              <CheckboxWithSelect
                {...field}
                label='Basement'
                options={BASEMENT_OPTIONS}
                defaultValue={field.value || null}
                selectedOption={field.value || null}
                uncheckedOption={BASEMENT_OPTIONS?.find(
                  option => option.value === BASEMENT_OPTION.NO,
                )}
                automaticallySelectedOption={BASEMENT_OPTIONS?.find(
                  option => option.value === BASEMENT_OPTION.YES,
                )}
              />
            )}
          />

          <Controller
            name='garage'
            control={control}
            defaultValue={null}
            render={({ field }) => (
              <CheckboxWithSelect
                {...field}
                label='Garage'
                options={GARAGE_OPTIONS}
                defaultValue={field.value || null}
                selectedOption={field.value || null}
                uncheckedOption={GARAGE_OPTIONS?.find(option => option.value === '0')}
                automaticallySelectedOption={GARAGE_OPTIONS?.find(option => option.value === '1')}
              />
            )}
          />
        </ColWrapper>
      </Wrapper>
    </SubSection>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
`;

const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
