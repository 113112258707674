import React from 'react';

import { propertyTypeOptions } from '../propertyTypeOptions';

import styles from './PropertyTypeSelector.module.scss';
import { Checkbox } from '../../../../../../../../../../../../components/Common/V2/Checkbox';

export const PropertyTypeSelector = ({ selected, onChange, className = '' }) => {
  const renderTypeSelector = typeOption => {
    const isSelected = !!selected.find(s => s.value === typeOption.value);

    const onChangeCb = newIsChecked =>
      !newIsChecked
        ? onChange(selected.filter(s => s.value !== typeOption.value))
        : onChange([...selected, typeOption]);

    return <Checkbox label={typeOption.label} onChange={onChangeCb} value={isSelected} />;
  };

  return (
    <div className={`${styles.propertyTypeSelector} ${className}`}>
      {propertyTypeOptions.map(renderTypeSelector)}
    </div>
  );
};
