import { forwardRef } from 'react';
import { Input } from '../Common/V2/Input';
import { useTheme } from 'styled-components';

interface Props {
  label: string;
}

export const DatePickerInput = forwardRef<HTMLInputElement, Props>(({ label, ...props }, ref) => {
  const { colors } = useTheme();

  return (
    <div ref={ref}>
      <Input
        {...props}
        leftIconName={'calendar'}
        leftIconStyles={{ color: colors.v2.gray[400] }}
        label={label}
        rightIconName={'chevron-down'}
        readOnly
      />
    </div>
  );
});

DatePickerInput.displayName = 'DatePickerInput';
