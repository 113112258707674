import { CSSProperties } from 'react';
import {
  ControlProps,
  IndicatorProps,
  OptionProps,
  SingleValueProps,
  StylesConfig,
} from 'react-select';

import { ThemeColors } from '../../../config/themes/types';

type SelectFontFamilies = 'Figtree';
export interface CustomSelectStyles {
  flex?: string;
  width?: string;
  fontWeight?: number;
  fontFamily?: SelectFontFamilies;
  fontSize?: number | string;
  menuMarginTop?: string;
  menuMarginBottom?: string;
  minWidth?: string;
  borderRadius?: string;
  menuListPadding?: string;
  zIndex?: number;
}

export const customStyles = (
  colors: ThemeColors,
  {
    flex = 'auto',
    width = '100% !important',
    fontFamily = 'Figtree',
    fontWeight = 500,
    menuMarginTop,
    menuMarginBottom,
    minWidth,
    borderRadius,
    menuListPadding,
    zIndex,
  }: CustomSelectStyles,
  useDataFonts?: boolean,
  hasError?: boolean,
): StylesConfig<any, any> => {
  return {
    container: (provided: CSSProperties) => ({
      ...provided,
      width,
      flex,
      minWidth: minWidth ? minWidth : provided.minWidth,
    }),
    control: (provided: CSSProperties, state: ControlProps<any, any>) => ({
      ...provided,
      fontFamily: fontFamily ? fontFamily : provided.fontFamily,
      borderColor: colors.v2.gray[200],
      height: '35px',
      minHeight: '35px',
      minWidth: minWidth ? minWidth : provided.minWidth,

      // rebranding
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
      boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.25) inset',
      borderRadius: borderRadius || '5px',
      border: hasError
        ? `1px solid ${colors.v2.status.error}`
        : state.isDisabled
          ? `1px solid ${colors.v2.gray[200]}`
          : state.isFocused
            ? 'double 1px transparent'
            : `1px solid ${colors.v2.gray[300]}`,
      backgroundColor: colors.v2.background,
      backgroundOrigin: state.isFocused ? 'border-box' : '',
      backgroundClip: state.isFocused ? 'padding-box, border-box' : '',
      backgroundImage: state.isFocused
        ? colors.v2.gradient.border
        : `linear-gradient(white, white),linear-gradient(${colors.v2.gray[300]}, ${colors.v2.gray[300]})`,
      '&:hover': {
        border: 'double 1px transparent',
        backgroundOrigin: 'border-box',
        backgroundClip: 'padding-box, border-box',
        backgroundImage: colors.v2.gradient.border,
      },
    }),
    option: (provided: CSSProperties, state: OptionProps<any, any>) => {
      const customFontFamily = useDataFonts ? state.data.value : fontFamily;
      return {
        ...provided,
        fontWeight: fontWeight ? fontWeight : provided.fontWeight,
        fontFamily: customFontFamily ? customFontFamily : provided.fontFamily,
        fontSize: '14px',
        color: state.isDisabled ? colors.v2.gray[300] : colors.v2.gray[400],
        background: state.isSelected ? colors.v2.gray[100] : 'transparent',
        borderRadius: 5,
        opacity: state.isDisabled ? 0.5 : 1,
        padding: 10,
        '&:hover': {
          backgroundColor: colors.v2.gray[100],
        },
      };
    },
    menu: (provided: CSSProperties) => {
      return {
        ...provided,
        marginTop: menuMarginTop ? menuMarginTop : 2,
        marginBottom: menuMarginBottom ? menuMarginBottom : provided.marginBottom,
        borderColor: colors.v2.gray[200],
        zIndex: 200,
        padding: 0,
      };
    },
    menuList: (provided: CSSProperties) => ({
      ...provided,
      maxHeight: '215px',
      padding: menuListPadding || '20px',
    }),
    dropdownIndicator: (provided: CSSProperties, state: IndicatorProps<any, any>) => ({
      ...provided,
      padding: '6px 8px',
      color: state.isDisabled ? colors.v2.gray[200] : colors.v2.gray[300],
      fontSize: 12,
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
      transition: 'all .2s ease',
    }),
    singleValue: (provided: CSSProperties, state: SingleValueProps<any>) => ({
      ...provided,
      color: state.isDisabled ? colors.v2.gray[200] : colors.v2.gray[400],
      marginLeft: 0,
      marginRight: 0,
    }),
    menuPortal: (provided: CSSProperties) => ({
      ...provided,
      zIndex: zIndex || 1,
    }),
  };
};
