import styled, { CSSProperties } from 'styled-components';
import { COMPARABLE_MODAL_MENU_SUBITEM_ID } from '../../../../../../types';

interface Props {
  children: React.ReactNode;
  title: string;
  titleId: COMPARABLE_MODAL_MENU_SUBITEM_ID;
  withHorizontalPadding?: boolean;
  titleStyles?: CSSProperties;
}

export const SubSection = ({
  children,
  title,
  titleId,
  withHorizontalPadding,
  titleStyles,
}: Props) => {
  return (
    <Container id={titleId} withHorizontalPadding={withHorizontalPadding}>
      <Title style={titleStyles}>{title}</Title>
      {children}
    </Container>
  );
};

const Container = styled.div<{ withHorizontalPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  ${({ withHorizontalPadding }) =>
    withHorizontalPadding &&
    `
    padding-left: 20px;
    padding-right: 20px;
  `}
  &:first-of-type {
    padding-top: 20px;
  }
`;

const Title = styled.h4`
  font-size: 12px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.v2.text.regular};
  text-transform: uppercase;
  margin-bottom: 10px;
`;
