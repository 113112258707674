import styled from 'styled-components';
import { ImagePreview } from './ImagePreview';
import { Button } from '../../../../../../../../../components/Common/V2/Button/Button';
import { useEffect, useRef, useState } from 'react';
import { TCustomComparableImage } from '../../../../../../../../../types';
import { getValidImages } from '../getValidImages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  images: TCustomComparableImage[];
  onUpdateImages: (images: TCustomComparableImage[]) => void;
}

export const ImagesCarousel = ({ images, onUpdateImages }: Props) => {
  const [index, setIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const itemsPerView = 3;
  const itemWidth = 100 / (itemsPerView + 0.3);
  const totalItems = images.length;

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.style.transform = `translateX(-${index * (100 / itemsPerView)}%)`;
    }
  }, [index]);

  const goNext = () => {
    if (index < totalItems - 1) {
      setIndex(index + 1);
    }
  };

  const goPrev = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  const setFavoriteImage = (imageUrl: string) => {
    onUpdateImages(
      images.map(img => {
        return {
          ...img,
          isFavorite: imageUrl === img?.url,
        };
      }),
    );
  };

  const onDeleteImage = (imageUrl: string) => {
    const filteredImages = images.filter(img => img?.url !== imageUrl);
    const updatedImages = getValidImages(filteredImages);

    onUpdateImages(updatedImages);
  };

  return (
    <CarouselContainer>
      <InnerContainer>
        <CarouselWrapper ref={containerRef}>
          {images.map((image, i) => (
            <Slide key={i} width={itemWidth}>
              <ImagePreview
                imageUrl={image?.url}
                isFavorite={image?.isFavorite}
                setIsFavorite={setFavoriteImage}
                onDeleteImage={onDeleteImage}
              />
            </Slide>
          ))}
        </CarouselWrapper>
      </InnerContainer>

      <ButtonContainer>
        <NavButton variant='secondary' onClick={goPrev} shouldHide={index === 0}>
          <FontAwesomeIcon icon={['fas', 'chevron-left']} />
        </NavButton>
        <NavButton
          variant='secondary'
          onClick={goNext}
          shouldHide={index >= totalItems - itemsPerView}
        >
          <FontAwesomeIcon icon={['fas', 'chevron-right']} />
        </NavButton>
      </ButtonContainer>
    </CarouselContainer>
  );
};

const CarouselContainer = styled.div`
  max-width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
`;

const InnerContainer = styled.div`
  margin: 0 20px;
  overflow: hidden;
`;

const CarouselWrapper = styled.div`
  display: flex;
  transition: transform 0.3s ease-in-out;
  gap: 10px;
`;

const Slide = styled.div<{ width: number }>`
  flex: 0 0 ${props => props.width}%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 15px);
  position: absolute;
  top: calc(50% - 20.5px);
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 1;
`;

const NavButton = styled(Button)<{ shouldHide: boolean }>`
  width: 35px;
  height: 35px;
  opacity: ${props => (props.shouldHide ? 0 : 1)};
`;
