import { DefaultTheme } from 'styled-components';
import { TProperty } from '../../../../../types';
import { MoneyFormatter } from '../../../../../pages/PresentationCreate/dash/services/moneyFormatter';

export const getInfoWindowContent = ({
  property,
  theme,
  hasOnMoreInfo,
  isViewModeProperty,
}: {
  property: TProperty;
  theme: DefaultTheme;
  hasOnMoreInfo?: boolean;
  isViewModeProperty?: boolean;
}) => {
  return `
  <div style="padding:4px 2px; width: 148px;">
    <h5 style="color: ${theme.colors.success};
              font-size: 11px;
              font-weight: 500;
              text-transform: uppercase;
              margin-bottom: 2px;">For Sale</h5>
    ${
      isViewModeProperty || !property.images.length
        ? ''
        : `<img style="width: 100%;" src=${property.images[0]} />`
    }
    <div style="font-size: 12px;
                font-weight: 500;
                color: #333;
                margin-top: 10px;
                margin-bottom: 5px;">${property.address.deliveryLine}</div>
    <div style="display: flex; margin-bottom: 5px;">
        <div style="font-size: 11px; color: #333;  margin-right: 16px">${MoneyFormatter.format(
          property.price,
        )}</div>
        <div style="font-size: 11px; color: #333;">${property.beds} beds</div>
    </div>
    <div style="font-size: 11px; color: #333;">Source: ${property.isCustom ? 'Agent' : 'MLS'}</div>
    ${
      hasOnMoreInfo
        ? `<div style="text-align: center;
                    color: ${theme.colors.v2.text.contrastText};
                    background: ${theme.colors.v2.primary};
                    padding: 4px 0;
                    border-radius: 4px;
                    margin-top: 5px;
                    font-weight: 500;
                    cursor: pointer;"
                 onclick="document.dispatchEvent(new CustomEvent('more-info', {detail: {id:'${property?.id}', status: '${property?.status}'}}))">More Info</div>`
        : ''
    }
  </div>
  `;
};
