import React from 'react';
import { TProperty } from '../../../../../../../types';
import { MoneyFormatter } from '../../../../../../../pages/PresentationCreate/dash/services/moneyFormatter';
import { createSvgWithText } from '../../../../../../../features/BuyerTour/components/Map/Markers/Markers.component';
import noPropertyImage from '../../../../../../../assets/images/no-property-image.png';
import {
  TableContainer,
  TableItem,
  ImageWrapper,
  Image,
  PropertyDetailsContainer,
  PropertyDetailsWrapper,
  PropertyDetails,
  PropertyDetail,
  Address,
  RatingContainer,
  Text,
  RatingWrapper,
  RatingText,
  Circle,
  CirclesContainer,
} from './ListingFeedbackTablePDF.styles';
import { ImageProxy } from '../../../../../services/ImageProxy';

interface ListingFeedbackTablePdfProps {
  properties: TProperty[];
}

export const ListingFeedbackTablePDF: React.FC<ListingFeedbackTablePdfProps> = ({ properties }) => {
  const markers = React.useMemo(
    () => Object.fromEntries(properties.map((p, i) => [i, createSvgWithText(i + 1)])),
    [properties],
  );

  return (
    <TableContainer>
      {properties.map((property, i) => (
        <TableItem key={property.id}>
          <PropertyDetailsContainer>
            <ImageWrapper>
              <img
                src={markers[i]}
                style={{
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  left: -5,
                  height: 25,
                }}
              />
              <Image
                src={
                  property?.images?.[0]
                    ? ImageProxy.optimize(property.images[0], { width: 500 })
                    : noPropertyImage
                }
              />
            </ImageWrapper>
            <PropertyDetailsWrapper>
              <PropertyDetails>
                <PropertyDetail>{MoneyFormatter.format(property?.price)}</PropertyDetail>
                <PropertyDetail>{property?.beds} beds</PropertyDetail>
                <PropertyDetail>{property?.baths} baths</PropertyDetail>
                <PropertyDetail>{property?.size} SQFT</PropertyDetail>
              </PropertyDetails>
              <Address>
                {property?.address?.deliveryLine} (Source: {property.isCustom ? 'Agent' : 'MLS'})
              </Address>
            </PropertyDetailsWrapper>
          </PropertyDetailsContainer>
          <RatingContainer>
            <Text>How do you feel about this listing?</Text>
            <RatingWrapper>
              <RatingText>Not Interested</RatingText>
              <CirclesContainer>
                {Array.from(Array(5).keys()).map(item => (
                  <Circle key={item} />
                ))}
              </CirclesContainer>
              <RatingText>Make An Offer</RatingText>
            </RatingWrapper>
          </RatingContainer>
        </TableItem>
      ))}
    </TableContainer>
  );
};
