import styled from 'styled-components';
import {
  COMPARABLE_MODAL_MENU_SUBITEM_ID,
  TCustomComparableImage,
} from '../../../../../../../../types';
import { SubSection } from '../../SubSection';
import { UploadImageArea } from './UploadImageArea';
import { useCallback, useEffect, useState } from 'react';
import { ImagesCarousel } from './ImagesCarousel/ImagesCarousel';
import { useUploadImages } from './useUploadImages';
import { useController, useFormContext } from 'react-hook-form';
import { getValidImages } from './getValidImages';

export const UploadImage = () => {
  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const uploadedImages = useUploadImages(files);
  const { control } = useFormContext();

  const { field } = useController({
    name: 'images',
    control,
    defaultValue: [],
  });

  const onImageUpload = useCallback(async (files: File[]) => {
    setFiles(files);
  }, []);

  useEffect(() => {
    if (!files.length) return;
    setIsLoading(true);
    uploadedImages?.forEach(image => {
      if (image?.isSuccess && image?.data) {
        const imageUrls = field.value?.map((val: TCustomComparableImage) => val.url) || [];

        if (imageUrls.includes(image.data as string)) return;

        const newImage = {
          url: image.data as string,
          isFavorite: false,
        };

        field.onChange(getValidImages([...(field.value || []), newImage]));
      }
    });

    const allFinished = uploadedImages.every(q => q.isSuccess || q.isError);

    if (allFinished) {
      setIsLoading(false);
      setFiles([]);
    }
  }, [field, files.length, uploadedImages, uploadedImages.length]);

  return (
    <SubSection
      title='Upload Image'
      titleId={COMPARABLE_MODAL_MENU_SUBITEM_ID.UPLOAD_IMAGE}
      titleStyles={{ paddingLeft: '20px' }}
    >
      <Description>
        Drag and drop images here to upload, or click the “Browse Files” button to select an image
        from your device. Click the star icon to set an image as the main image, or click the delete
        icon to remove it.
      </Description>

      <ImagesCarousel images={field.value} onUpdateImages={field.onChange} />

      <UploadAreaWrapper>
        <UploadImageArea onDrop={onImageUpload} isLoading={isLoading} />
      </UploadAreaWrapper>
    </SubSection>
  );
};

const Description = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.v2.text.regular};
  margin-bottom: 10px;
  padding: 0 20px;
`;

const UploadAreaWrapper = styled.div`
  padding: 0 20px;
`;
