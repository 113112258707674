import { http } from '../../services/http';
import { cmaApi } from '../cmaApiCommunicator';
import { GOOGLE_MAP_KEY } from '../../config/constants';
import { AddressApiMapper } from './AddressApiMapper';

import humps from 'humps';

export class AddressCommunicator {
  static async search(addressQuery, external) {
    const apiAddresses = await cmaApi.post('/address/search', {
      address: addressQuery,
      external,
    });

    return apiAddresses.map(AddressApiMapper.apiToEntity);
  }

  static async create(
    address,
    number,
    city,
    state,
    beds,
    size,
    totalSize,
    lat,
    lng,
    yearBuilt,
    baths,
  ) {
    const response = await cmaApi.post('/address/create', {
      address,
      number,
      city,
      state,
      beds,
      size,
      total_size: totalSize,
      lat,
      lng,
      year_built: yearBuilt,
      baths,
    });

    return {
      id: response.id,
      propertyType: response.property_type,
      status: response.sale_type,
      beds: response.beds,
      baths: response.total_baths,
      size: response.size,
      totalSize: response.total_size,
      yearBuilt: response.year_built,
      coordinates: {
        latitude: response.coordinates.latitude,
        longitude: response.coordinates.longitude,
      },
    };
  }

  static fetchGeocode(address, number, city, state) {
    const fullAddress = `${address} ${number} ${city} ${state}`;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${fullAddress}&key=${GOOGLE_MAP_KEY}`;

    return http.get(url);
  }

  static fetchLatLng(lat, lng) {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAP_KEY}`;

    return http.get(url);
  }
}

export const info = (address, number, city, state) =>
  cmaApi.post('/address/info', { address, number, city, state });

export const searchByMlsId = async (mlsId, propertyId) => {
  const payload = propertyId
    ? { id: String(mlsId), subject_id: String(propertyId) }
    : { id: String(mlsId) };

  const mlsApiProperties = await cmaApi.post('/address/get-by-mls-id', payload);

  return mlsApiProperties.map(mlsApiProperty => {
    const property = humps.camelizeKeys(mlsApiProperty);

    property.address = { ...property.fullAddress };
    delete property.fullAddress;

    return property;
  });
};

export const geocode = (address, number, city, state) => {
  const fullAddress = `${address} ${number} ${city} ${state}`;
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${fullAddress}&key=${GOOGLE_MAP_KEY}`;
  return http.get(url);
};
