import { Button } from '../V2/Button/Button';
import { ModalFooter } from './FitContentModal.styles';

interface Props {
  hasCancelBtn?: boolean;
  hasConfirmBtn?: boolean;
  loading?: boolean;
  disabled?: boolean;
  cancelBtnText?: string;
  confirmBtnText?: string;
  onConfirmClick?: () => void;
  onClose: () => void;
  prefixButtonsContent?: React.ReactNode;
  customButtons?: React.ReactNode;
}

export const Footer = ({
  hasCancelBtn,
  hasConfirmBtn,
  loading,
  disabled,
  cancelBtnText,
  confirmBtnText,
  onConfirmClick,
  onClose,
  prefixButtonsContent,
  customButtons,
}: Props) => {
  if (customButtons) {
    return <ModalFooter isCustom>{customButtons}</ModalFooter>;
  }

  if (!hasCancelBtn && !hasConfirmBtn) {
    return null;
  }

  return (
    <ModalFooter>
      {prefixButtonsContent}
      {hasCancelBtn && (
        <Button variant='secondary' onClick={onClose} disabled={loading}>
          {cancelBtnText || 'Cancel'}
        </Button>
      )}
      {hasConfirmBtn && (
        <Button variant='primary' onClick={onConfirmClick} disabled={disabled || loading}>
          {confirmBtnText}
        </Button>
      )}
    </ModalFooter>
  );
};
