import styled from 'styled-components';
import { useState } from 'react';
import { PastPresentationItem } from '../../../types';
import { SendEmailPopup } from '../../../../../../../components/Common/SendEmailPopup/SendEmailPopup.component';
import { useDeletePresentation } from '../../../hooks/useDeletePresentation';
import WarningConfirmPopup from '../../../../../../../components/Common/WarningConfirmPopup/WarningConfirmPopup';
import { Button } from './Button';
import { getPresentationTypeFromApi } from '../../../../../../../services/getPresentationTypeFromApi';

const ButtonWrapper = styled('div')({
  display: 'flex',
  gap: 8,
  height: '100%',
  alignItems: 'center',
});

interface Props {
  presentation: PastPresentationItem;
}

export const ActionButtonsCell = ({ presentation }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [presentationToMail, setPresentationToMail] = useState<PastPresentationItem>();
  const [presentationId, setPresentationId] = useState<string | null>(null);

  const { mutate: deletePresentation, isLoading } = useDeletePresentation();

  const onMailClick = () => {
    setIsOpen(true);
    setPresentationToMail(presentation);
  };

  const onDeletePresentation = async () => {
    if (!presentationId) return;
    deletePresentation(presentationId);
    setPresentationId(null);
  };

  return (
    <ButtonWrapper>
      <Button iconName='mail' onClick={onMailClick} />
      <Button iconName='remove' onClick={() => setPresentationId(presentation.id)} />

      {isOpen && (
        <SendEmailPopup
          address={presentation.address}
          presentationType={getPresentationTypeFromApi(presentation?.type)}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          presentationHash={presentationToMail?.hash ?? ''}
          client={{
            id: presentationToMail?.clientId,
            email: presentationToMail?.clientEmail,
            name: presentationToMail?.clientName,
          }}
          isPdfGenerating={presentation.buildInProgress}
          pdfUrl={presentation.pdf}
        />
      )}
      {!!presentationId && (
        <WarningConfirmPopup
          open={!!confirm}
          heading='Delete this presentation?'
          description='The presentation data will be deleted!'
          isLoading={isLoading}
          onConfirm={onDeletePresentation}
          onCancel={() => setPresentationId(null)}
          onClose={() => setPresentationId(null)}
        />
      )}
    </ButtonWrapper>
  );
};
