import { flexRender, Table as TTable } from '@tanstack/react-table';
import styled from 'styled-components';
import { Icon } from './Common';
import { CSSProperties } from 'react';
import { TableColumnDef } from '../types';

interface Props {
  table: TTable<any>;
  tableWrapperStyles?: CSSProperties;
  tableHeight?: string;
  isScrollableVertically?: boolean;
}

export const Table = ({
  table,
  tableWrapperStyles,
  isScrollableVertically,
  tableHeight,
}: Props) => {
  return (
    <TableWrapper
      style={tableWrapperStyles}
      isScrollableVertically={isScrollableVertically}
      tableHeight={tableHeight}
    >
      <StyledTable isScrollableVertically={isScrollableVertically}>
        <THead isScrollableVertically={isScrollableVertically}>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <TH
                    key={header.id}
                    colSpan={header.colSpan}
                    onClick={header.column.getToggleSortingHandler()}
                    style={{
                      cursor: header.column.getCanSort() ? 'pointer' : 'auto',
                      ...((header.column.columnDef.meta as TableColumnDef)?.thStyles || {}),
                    }}
                  >
                    <HeaderTextWrapper>
                      <HeaderText>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </HeaderText>
                      {header.column.getCanSort() && (
                        <Icon iconName='sort' style={{ marginLeft: 4 }} />
                      )}
                    </HeaderTextWrapper>
                  </TH>
                );
              })}
            </tr>
          ))}
        </THead>
        <tbody>
          {table.getRowModel().rows.map(row => {
            return (
              <TableBodyRow key={row.id}>
                {row.getVisibleCells().map(cell => {
                  return (
                    <TD
                      key={cell.id}
                      style={(cell.column.columnDef.meta as TableColumnDef)?.tdStyles}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TD>
                  );
                })}
              </TableBodyRow>
            );
          })}
        </tbody>
      </StyledTable>
    </TableWrapper>
  );
};

const TableWrapper = styled.div<{ tableHeight?: string; isScrollableVertically?: boolean }>`
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.colors.gray[200]}`};
  display: flex;
  font-family: Figtree;
  max-height: ${({ tableHeight, isScrollableVertically }) =>
    tableHeight && isScrollableVertically ? tableHeight : 'auto'};
  overflow: ${({ isScrollableVertically }) => (isScrollableVertically ? 'auto' : 'hidden')};
`;

const StyledTable = styled.table<{ isScrollableVertically?: boolean }>`
  background-color: #fff;
  overflow: ${({ isScrollableVertically }) => (isScrollableVertically ? 'auto' : 'hidden')};
  flex: 1;
  border-collapse: collapse;
`;

const THead = styled.thead<{ isScrollableVertically?: boolean }>`
  background-color: ${({ theme }) => theme.colors.gray[100]};
  ${({ isScrollableVertically }) =>
    isScrollableVertically &&
    `
    position: sticky;
    top: 0;
    z-index: 10;
  `}
`;

const TH = styled.th`
  padding: 17px 18px;

  &:first-of-type {
    padding-left: 52px;
  }
`;

const HeaderTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderText = styled.p`
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.gray[400]};
  text-align: left;
  line-height: 1.2;
`;

const TableBodyRow = styled.tr`
  &:not(:first-of-type) {
    border-top: ${({ theme }) => `1px solid ${theme.colors.gray[200]}`};
  }
`;

const TD = styled.td`
  padding: 14px 18px;
  text-align: left;
`;
