import React, { TextareaHTMLAttributes } from 'react';
import styled from 'styled-components';

type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & { error?: string };

export const Textarea: React.FC<TextareaProps> = ({ rows = 4, ...props }) => {
  return <TextareaEl rows={rows} {...props}></TextareaEl>;
};

const TextareaEl = styled.textarea<{ error?: string }>`
  border: 1px solid
    ${({ theme, error }) => (error ? theme.colors.v2.status.error : theme.colors.gray[200])};
  border-radius: 2px;
  padding: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: normal;
  min-width: 100%;
  max-width: 100%;
  color: #444;
  resize: none;

  ::placeholder {
    color: #b1b1b1;
  }

  &:focus {
    border: double 1px transparent;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-image: ${props => props.theme.colors.v2.gradient.border};
  }
`;
