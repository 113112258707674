import { connect } from 'react-redux';
import { useEffect } from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import styles from './CompResults.module.scss';

import { PropertyModal } from '../../../../../../../features/report/components/PropertyModal/PropertyModal';

import { isPropertyModalOpenAtom } from '../../../../../../../features/report/state/isPropertyModalOpenAtom';
import { useSyncStateWithRedux } from '../../../../../../../features/report/hooks/useSyncStateWithRedux';
import { ResultsHeader } from '../../../../../../../features/report/results-header/ResultsHeader';
import { ScaleOverviewReport } from '../../../../../../../features/report/scale-overview/ScaleOverviewReport';
import { useSetDefaultHighlights } from '../../../../../../../features/report/hooks/useSetDefaultHighlights';
import { MarketAssessmentReport } from '../../../../../../../features/report/market-assessment/MarketAssessmentReport';
import { useSetDefaultCategories } from '../../../../../../../features/report/hooks/useSetDefaultCategories';
import { customEstimatesAtom } from '../../../../../../../features/report/state/customEstimatesAtom';
import { usePresentationCreationQuery } from '../../../../../../../hooks/usePresentationCreationQuery';
import { highlightedPropertiesAtom } from '../../../../../../../features/report/state/highlighted-properties/highlightedPropertiesAtom';
import { selectedCategoriesAtom } from '../../../../../../../features/report/state/selectedCategoriesAtom';

export const CompResultsComponent = ({ reportDetailed }) => {
  const [isPropertyModalOpen, setIsPropertyModalOpen] = useAtom(isPropertyModalOpenAtom);
  const setCustomEstimates = useSetAtom(customEstimatesAtom);
  const highlights = useAtomValue(highlightedPropertiesAtom);
  const selectedCategories = useAtomValue(selectedCategoriesAtom);

  const setDefaultHighlights = useSetDefaultHighlights();
  const setDefaultCategories = useSetDefaultCategories();

  const { isRebuild } = usePresentationCreationQuery();

  useSyncStateWithRedux();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!isRebuild && highlights.length === 0 && selectedCategories.length === 0) {
      setCustomEstimates([]);
      setDefaultHighlights(reportDetailed);
      setDefaultCategories(reportDetailed);
    }
  }, []);

  return (
    <>
      <ResultsHeader />
      <div className={styles.compResults}>
        <ScaleOverviewReport />
        <MarketAssessmentReport />

        <PropertyModal
          isModalOpen={isPropertyModalOpen}
          onCloseModal={() => setIsPropertyModalOpen(false)}
        />
      </div>
    </>
  );
};

const stateToProps = ({ report }) => ({
  reportDetailed: report.reportDetailed,
});

export const CompResults = connect(stateToProps)(CompResultsComponent);
