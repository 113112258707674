import { Active, Over } from '@dnd-kit/core';

export const getDndSections = ({ active, over }: { active: Active; over: Over | null }) => {
  const sourceSection = active?.data?.current?.isSection
    ? active.id
    : active?.data?.current?.sectionId;
  const destinationSection = over?.data?.current?.isSection
    ? over.id
    : over?.data?.current?.sectionId;

  return {
    sourceSection,
    destinationSection,
  };
};
