import { AutosuggestClients } from '../../../AutosuggestClients/AutosuggestClients.component';
import { Input } from '../../../V2/Input';
import styled from 'styled-components';
import { Textarea } from '../../../V2/Textarea';
import { Checkbox } from '../../../V2/Checkbox';
import { Controller, useFormContext } from 'react-hook-form';
import { SignatureInput } from './SignatureInput';
import { InputLabel, InputWrapper } from './common';
import { useUser } from '../../../../Slide/providers/UserProvider';
import Tooltip from '../../../Tooltip/Tooltip.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Container = styled.div`
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.v2.gray[200]};
`;

const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SignatureLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const TooltipWrapper = styled.div`
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;

const SignatureLink = styled.a`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.v2.text.link};
  text-decoration: none;
`;

export const EmailForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const user = useUser();

  return (
    <Container>
      <Controller
        name='client'
        control={control}
        render={({ field }) => (
          <AutosuggestClients
            initialValue={field?.value?.id ? field?.value?.name : ''}
            setClient={client => field.onChange(client)}
            placeholder='Enter client name'
            suggestionsContainerStyles={{ width: '100%', position: 'absolute', zIndex: 1 }}
            label='Client:'
            formFieldError={
              (errors?.client as any)?.email?.message ? 'Client is required field' : ''
            }
          />
        )}
      />

      <Controller
        name='subject'
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            inputWrapperStyle={{ width: '100%' }}
            label='Subject:'
            error={errors.subject ? (errors.subject?.message as string) : undefined}
            hideErrorMessage
          />
        )}
      />

      <InputWrapper>
        <InputLabel>Message:</InputLabel>
        <Controller
          name='message'
          control={control}
          render={({ field }) => (
            <Textarea
              {...field}
              rows={10}
              id={'message'}
              style={{ borderRadius: '5px' }}
              error={errors.message ? (errors.message?.message as string) : undefined}
            />
          )}
        />
      </InputWrapper>

      <Controller
        name='isPdfIncluded'
        control={control}
        render={({ field }) => (
          <Checkbox
            value={field.value}
            onChange={field.onChange}
            label='Include Downloadable PDF Presentation'
          />
        )}
      />

      <Divider />
      <SignatureInput />

      <BottomWrapper>
        <Controller
          name='isAgentPhotoIncluded'
          control={control}
          render={({ field }) => (
            <Checkbox
              value={field.value}
              onChange={field.onChange}
              label='Include Agent Photo in Signature'
            />
          )}
        />

        <SignatureLinkWrapper>
          <SignatureLink
            href={`https://app.boldtrail.com/profiles/agents/${user.kvcoreUserId}#signature`}
            target='_blank'
            rel='noopener noreferrer'
          >
            Manage Default Outgoing Email Signature
          </SignatureLink>
          <TooltipWrapper>
            <Tooltip
              text='Signature changes made in your BoldTrail profile will apply after you close and reopen Present.'
              tooltipContentStyle={{ width: '200px' }}
            >
              <FontAwesomeIcon icon={['fas', 'info-circle']} />
            </Tooltip>
          </TooltipWrapper>
        </SignatureLinkWrapper>
      </BottomWrapper>
    </Container>
  );
};
