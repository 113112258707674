import React, { Fragment } from 'react';
import { useParams } from 'react-router';

import { TPresentation } from '../../../../types';
import { usePreviewMode } from '../providers/PreviewModeProvider';
import { Container, LeftCol, RightCol, Description } from './PresentationCreationBody.styles';
import { SlideAndPresentationActions } from './TopBodyContent/SlideAndPresentationActions';
import PreviewSlide from '../../../../components/PreviewSlide/PreviewSlide.component';
import { useUiConfigTheme } from '../../../../providers/providers/UiConfigThemeProvider';
import { useSelectedSlide } from '../../../../providers/providers/SelectedSlideProvider';
import { useUiConfigInfoPlacement } from '../../../../providers/providers/UiConfigInfoPlacementProvider';
import { useActiveSection } from '../../../../components/Slide/providers/ActiveSectionProvider';
import { RegularDynamicSections } from './RegularDynamicSections/RegularDynamicSections.component';
import { usePresentationMode } from '../../../../providers/providers/PresentationModeProvider';
import { CMADynamicSections } from './CMADynamicSections/CMADynamicSections.component';
import { OneSheeterSections } from './OneSheeterDynamicSections/OneSheeterSections';
import { SectionsInfo } from './TopBodyContent/SectionsInfo.component';
import { useSlidePreviewWrappers } from '../../../../components/PreviewSlide/useSlidePreviewWrappers';

interface PresentationCreationBodyProps {
  presentation: TPresentation;
}

export const PresentationCreationBody: React.FC<PresentationCreationBodyProps> = ({
  presentation,
}) => {
  const { previewMode } = usePreviewMode();
  const { theme } = useUiConfigTheme();
  const { infoPlacement } = useUiConfigInfoPlacement();
  const { selectedSlide } = useSelectedSlide();
  const { activeSection } = useActiveSection();
  const { presentationMode } = usePresentationMode();

  const { hash } = useParams<{ hash: string }>();

  const Sections = React.useMemo(() => {
    const map = {
      cma: CMADynamicSections,
      oneSheeter: OneSheeterSections,
      regular: RegularDynamicSections,
    };

    if (!presentationMode) return () => null;

    return map[presentationMode];
  }, [presentationMode]);

  const { OuterSlideWrapper, InnerSlideWrapper } = useSlidePreviewWrappers();

  return (
    <Container>
      <LeftCol>
        {previewMode === 'web' && <SectionsInfo />}
        <Description>Drag and drop thumbnails to reorder slides in your presentation</Description>
        <Sections key={presentationMode} presentation={presentation} />
      </LeftCol>

      <RightCol>
        <SlideAndPresentationActions />
        <OuterSlideWrapper>
          <InnerSlideWrapper>
            {selectedSlide?.id && (
              <PreviewSlide
                key={`${selectedSlide.id}-${previewMode}`}
                platform={previewMode}
                slide={selectedSlide}
                theme={theme}
                infoPlacement={infoPlacement}
                activeSection={activeSection || ''}
                presentationHash={hash}
                hideProgressTracker={presentationMode !== 'regular'}
                style={{ marginBottom: 20 }}
                showMultiPages
              />
            )}
          </InnerSlideWrapper>
        </OuterSlideWrapper>
      </RightCol>
    </Container>
  );
};
