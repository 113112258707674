import React from 'react';
import styled from 'styled-components';
import { useUiConfigColorsSecondary } from '../../../../../providers/providers/UiConfigColorProvider';
import { useUiConfigBodyLetterSpacing } from '../../../../../providers/providers/UiConfigFontProvider';
import { Typography } from '../../../../Common';
import { ColorOpacityApplier } from '../../../services/ColorOpacityApplier';
import { GraphWrapper } from '../../components/common/GraphWrapper';
import { useHasComparableAdjustments } from '../../../../../hooks/useHasComparableAdjustments';

type WithReversed = {
  isReversed?: boolean;
};

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '20px 0 20px 0',
  height: '100%',
  overflow: 'hidden',
});

const GraphWrapperContainer = styled('div')({
  width: '100%',
  height: '61%',
});

const InfoContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  height: '36%',
});

const HighlighBox = styled('div')<WithReversed>(({ isReversed }) => ({
  width: '41%',
  borderRadius: 5,
  border: '1px solid #ddd',
  backgroundColor: ColorOpacityApplier.hex(useUiConfigColorsSecondary(), '.1'),
  padding: '50px 32px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

const TitleWrapper = styled.div<WithReversed>`
  display: flex;
  flex-direction: column;
  flex-direction: ${({ isReversed }) => (isReversed ? 'column-reverse' : 'column')};
  justify-content: ${({ isReversed }) => (isReversed ? 'flex-end' : 'flex-start')};
`;

const DescriptionWrapper = styled('div')({
  width: '55%',
});

const Description = styled('div')({
  marginBottom: 30,
});

const DescriptionTitle = styled('h4')(({ theme }) => ({
  fontSize: 16,
  textTransform: 'uppercase',
  color: theme.colors.gray[400],
  fontWeight: 500,
  marginBottom: 15,
  lineHeight: '29.1px',
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

const DescriptionText = styled('p')(({ theme }) => ({
  fontSize: 16,
  color: theme.colors.gray[400],
  lineHeight: '26.5px',
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

const Disclaimer = styled.span`
  color: #626262;
  font-size: 11px;
  font-style: italic;
  font-weight: 400;
  line-height: 14px;
`;

type TDescription = {
  title: string;
  text: string;
};

interface SlideWithGraphWrapperPdfProps {
  heading: string;
  summary?: string[];
  children: React.ReactNode;
  title: string;
  subtitle: string;
  description: TDescription[];
  graphPadding?: string | number;
  headerDescription?: string;
  isReversed?: boolean;
  noDisclaimer?: boolean;
}

export const SlideWithGraphWrapperPdf: React.FC<SlideWithGraphWrapperPdfProps> = ({
  heading,
  summary,
  children,
  title,
  subtitle,
  description,
  graphPadding,
  headerDescription,
  isReversed,
  noDisclaimer,
}) => {
  const hasAdjustments = useHasComparableAdjustments();

  return (
    <Wrapper>
      <GraphWrapperContainer>
        <GraphWrapper
          heading={heading}
          summary={summary}
          isPdf
          graphPadding={graphPadding}
          headerDescription={headerDescription}
        >
          {children}
        </GraphWrapper>
      </GraphWrapperContainer>
      <InfoContainer>
        <HighlighBox>
          <TitleWrapper isReversed={isReversed}>
            <Typography variant='h2' color='400' style={{ fontWeight: 900, margin: '5px 0' }}>
              {title}
            </Typography>
            <Typography variant='h4' color='400' style={{ fontWeight: 500, margin: '5px 0' }}>
              {subtitle}
            </Typography>
          </TitleWrapper>
          {!noDisclaimer && hasAdjustments && (
            <Disclaimer>The plotted price is the actual price, not the adjusted.</Disclaimer>
          )}
        </HighlighBox>
        <DescriptionWrapper>
          {description.map(({ title, text }, i) => {
            return (
              <Description key={i}>
                <DescriptionTitle>{title}</DescriptionTitle>
                <DescriptionText>{text}</DescriptionText>
              </Description>
            );
          })}
        </DescriptionWrapper>
      </InfoContainer>
    </Wrapper>
  );
};
