import styled from 'styled-components';
import { FitContentModal } from '../../../components/Common';
import { TProperty } from '../../../types';
import { ReviewComparablesTable } from './ReviewComparablesTable';
import { useEffect } from 'react';
import { useUpdateBulkComparables } from '../hooks/useUpdateBulkComparables';
import { FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import { LISTING_STATUS_OPTIONS } from '../constants/options';

interface Props {
  offMlsComparables: TProperty[];
  isOpen: boolean;
  onClose: () => void;
  presentationId?: number;
  onUpdate?: () => void;
}

export type ComparableFormValues = {
  address: string;
  status: {
    label: string;
    value: string;
  };
  salePrice?: string;
  price: string;
  sourceId: string;
};

export type FormValues = {
  customComparables: ComparableFormValues[];
};

export const ReviewOffMlsComparablesModal = ({
  offMlsComparables,
  isOpen,
  onClose,
  presentationId,
  onUpdate,
}: Props) => {
  const { mutateAsync: updateComparables, isLoading } = useUpdateBulkComparables();

  const methods = useForm<FormValues>({
    defaultValues: { customComparables: [] },
    resolver: yupResolver(schema),
  });

  const { control, handleSubmit, reset } = methods;

  const { fields } = useFieldArray({
    control,
    name: 'customComparables',
  });

  useEffect(() => {
    const comparablesData = offMlsComparables.map(item => {
      const selectedOption = LISTING_STATUS_OPTIONS.find(option => option.value === item.status);
      return {
        address: item.address.deliveryLine?.split(',')[0],
        status: selectedOption,
        salePrice: item.salePrice ? String(item.salePrice) : '',
        price: item.price ? String(item.price) : '',
        sourceId: item.sourceId || item.id,
      };
    });
    reset({ customComparables: comparablesData });
  }, [offMlsComparables, reset]);

  const onConfirm = handleSubmit(async data => {
    const bulkPayload = data?.customComparables?.map(item => ({
      comparable_property_id: item.sourceId || '',
      status: item.status.value,
      price: item.price ? Number(item.price) : 0,
      sale_price: item.salePrice ? Number(item.salePrice) : undefined,
    }));

    await updateComparables({ payload: bulkPayload, presentationId: presentationId || 0 });
    onUpdate && onUpdate();
    reset();
    onClose();
  });

  return (
    <FormProvider {...methods}>
      <FitContentModal
        isOpen={isOpen}
        headerTitle='Update Off-MLS Comparables'
        onClose={onClose}
        confirmBtnText='Update Comparables'
        loading={isLoading}
        hasCancelBtn
        modalContainerStyles={{ maxWidth: 800, width: '80%' }}
        onConfirmClick={onConfirm}
        contentDisplay='flex'
        disabled={!fields.length}
        cancelBtnText='Continue Without Updating'
        disableOutsideClick
      >
        <Container>
          <Title>Review Your Off-MLS Comparables</Title>
          <Description>
            This presentation includes one or more agent-added comparables that are not updated by
            the MLS. Would you like to review and update them before creating a new version?
          </Description>
          <ReviewComparablesTable comparables={fields} onUpdate={onUpdate} />
        </Container>
      </FitContentModal>
    </FormProvider>
  );
};

const Container = styled.div`
  padding: 20px;
`;

const Title = styled.h5`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.v2.text.regular};
  font-weight: 700;
`;

const Description = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.v2.text.regular};
  font-weight: 500;
  line-height: 21px;
  margin-top: 10px;
`;
