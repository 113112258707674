import styled from 'styled-components';
import { OffMLSComparables } from '../../../../../../../../../../features/manual-comparables/OffMLSComparables/OffMLSComparables';
import { OnMLSComparables } from '../../../../../../../../../../features/manual-comparables/OnMLSComparables/OnMLSComparables';
import { ComparableCard } from '../../../../../../../../../../features/manual-comparables/components/ComparableCard';
import { SearchCriteria } from '../../../../../../../../../../types';

interface Props {
  mlsIds: string[];
  setMlsIds: (ids: string[]) => void;
  subjectId: string;
  onPartial: (criteria: SearchCriteria) => void;
}

export const ManualComparableCards = ({ mlsIds, setMlsIds, subjectId, onPartial }: Props) => {
  return (
    <CardsWrapper>
      <ComparableCard
        title='Add On-MLS Comparable'
        info='Input the MLS Number to automatically pull property details from the MLS database.'
      >
        <OnMLSComparables
          mlsIds={mlsIds}
          setMlsIds={setMlsIds}
          subjectId={subjectId}
          onPartial={onPartial}
        />
      </ComparableCard>
      <ComparableCard
        title='Add Off-MLS Comparable'
        info='Add a property not listed on the MLS by entering its details manually. Use this option to include non-MLS properties in your comparisons.'
      >
        <OffMLSComparables onPartial={onPartial} />
      </ComparableCard>
    </CardsWrapper>
  );
};

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
