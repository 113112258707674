import { TProperty } from '../../../../types';

export const getShelflifeAverage = (properties: TProperty[]) => {
  const activeProperties = properties.filter(prop => prop.status === 'active' && !prop.excluded);

  if (!activeProperties.length) return 0;

  const daysOnSite = activeProperties.map(p => p.daysOnMarket ?? 0) || [];

  const avgDaysOnSite = daysOnSite.reduce((a, b) => a + b, 0) / daysOnSite.length;

  return Math.round(avgDaysOnSite);
};
