import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { enGB } from 'date-fns/locale';
import { registerLocale } from 'react-datepicker';
import { DatePickerInput } from './DatePickerInput';
import { DatePickerHeader } from './DatePickerHeader';
import { forwardRef } from 'react';

const customLocale: Locale = {
  ...enGB,
  localize: {
    ...(enGB.localize as any),
    day: (n: any) => ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][n],
  },
};

registerLocale('custom', customLocale);

interface Props {
  date: Date | null | null;
  setDate: (date: Date | null | null) => void;
  label: string;
}

export const DatePicker = forwardRef<ReactDatePicker, Props>(({ date, setDate, label }, ref) => {
  const handleSelect = (selectedDate: Date | null) => {
    setDate(selectedDate);
  };

  return (
    <DatePickerWrapper>
      <ReactDatePicker
        ref={ref}
        onChange={date => handleSelect(date)}
        locale='custom'
        dateFormat={'M/d/yyyy'}
        selected={date}
        shouldCloseOnSelect
        customInput={<DatePickerInput label={label} />}
        renderCustomHeader={({ decreaseMonth, increaseMonth, date }) => (
          <DatePickerHeader
            date={date}
            decreaseMonth={decreaseMonth}
            increaseMonth={increaseMonth}
          />
        )}
      />
    </DatePickerWrapper>
  );
});

DatePicker.displayName = 'DatePicker';

const DatePickerWrapper = styled.div`
  & .react-datepicker {
    border: ${({ theme }) => `1px solid ${theme.colors.v2.gray[200]}`};
    border-radius: 5px;
    font-family: 'Figtree';
  }
  & .react-datepicker__header {
    border-bottom: none;
    background-color: ${({ theme }) => theme.colors.v2.gray[100]};
    padding-top: 15px;
    padding-bottom: 0;
  }
  & .react-datepicker__day-names {
    border-top: ${({ theme }) => `1px solid ${theme.colors.v2.gray[200]}`};
    margin: 15px 10px 0 10px;
  }

  & .react-datepicker__day-name {
    color: #848484;
    font-weight: 500;
  }

  & .react-datepicker__week {
    border-top: ${({ theme }) => `1px solid ${theme.colors.v2.gray[200]}`};

    &:last-of-type {
      border-bottom: ${({ theme }) => `1px solid ${theme.colors.v2.gray[200]}`};
    }
  }

  & .react-datepicker__month {
    margin: 10px;
  }

  & .react-datepicker__triangle {
    display: none;
  }

  & .react-datepicker__day--outside-month {
    color: ${({ theme }) => theme.colors.v2.gray[300]};
  }

  & .react-datepicker__day.react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.colors.v2.primary};
    color: ${({ theme }) => theme.colors.v2.text.contrastText};
  }
  & .react-datepicker__day.react-datepicker__day--selected:hover {
    background-color: ${({ theme }) => theme.colors.v2.primary};
    color: ${({ theme }) => theme.colors.v2.text.contrastText};
  }

  & .react-datepicker__day.react-datepicker__day--keyboard-selected {
    background: transparent;
  }

  & .react-datepicker__day:hover {
    background-color: ${({ theme }) => theme.colors.gray[100]};
    color: ${({ theme }) => theme.colors.v2.text.regular};
  }
`;
