import styled from 'styled-components';

export const Content = styled.div<{ contentDisplay?: string }>(({ theme, contentDisplay }) => ({
  padding: 0,
  backgroundColor: theme.colors.v2.background,
  minHeight: 206,
  position: 'relative',
  overflow: 'auto',
  flex: 1,
  display: contentDisplay || 'block',
}));

export const ModalFooter = styled('div')<{ isCustom?: boolean }>(({ theme, isCustom }) => ({
  backgroundColor: theme.colors.v2.background,
  padding: 20,
  borderTop: isCustom ? 'none' : `1px solid ${theme.colors.gray[200]}`,
  borderRadius: '0px 0px 8px 8px',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '10px',
}));
