import { DefaultTooltipContent } from 'recharts';
import { MoneyFormatter } from '../../../../pages/PresentationCreate/dash/services/moneyFormatter';
import { TooltipPayloadProps } from '../../../../types';
import { Adjusted, PriceWrapper, TooltipWrapper } from '../components/UI';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';

interface ActiveShelfLifeTooltipProps {
  active: boolean;
  payload: TooltipPayloadProps[];
}

export const ActiveShelfLifeTooltip = ({
  active,
  payload,
  ...props
}: ActiveShelfLifeTooltipProps) => {
  if (!active) {
    return <DefaultTooltipContent payload={payload as Payload<number, string>[]} {...props} />;
  }

  const dos = payload[0].value;
  const price = payload[0].payload.price;
  const listDate = payload[0].payload.listDateHuman;
  const address = payload[0].payload.address.deliveryLine;
  const adjustedPrice = payload?.[0]?.payload?.adjustedPrice
    ? payload[0].payload.adjustedPrice
    : null;
  const isCustom = payload?.[0]?.payload?.isCustom;

  return (
    <TooltipWrapper>
      <p>{address}</p>
      <p>List Date: {listDate}</p>

      <PriceWrapper>
        <p
          style={{
            color: payload[0].color,
          }}
        >{`Price: ${MoneyFormatter.format(price)}`}</p>
        {Boolean(adjustedPrice) && (
          <Adjusted>ADJ Price: {MoneyFormatter.format(adjustedPrice)}</Adjusted>
        )}
      </PriceWrapper>
      <p
        style={{
          color: payload[0].color,
        }}
      >{`Days on Site: ${dos}`}</p>
      <p>Source: {isCustom ? 'Agent' : 'MLS'}</p>
    </TooltipWrapper>
  );
};
