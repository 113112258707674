import * as yup from 'yup';

export type TRequiredComparableFormData = yup.InferType<typeof schema>;

export const schema = yup
  .object({
    address: yup.string().required(),
    city: yup.string().required(),
    isCanadaUser: yup.boolean().required(),
    province: yup.string().when('isCanadaUser', ([isCanadaUser], schema) => {
      return isCanadaUser ? schema.required() : schema.notRequired();
    }),
    state: yup
      .object({
        label: yup.string(),
        value: yup.string(),
      })
      .nullable()
      .when('isCanadaUser', ([isCanadaUser], schema) => {
        return !isCanadaUser
          ? schema.required().test('state', 'state is invalid', function ({ value }) {
              if (!value) {
                return this.createError({
                  message: 'state is invalid',
                });
              }
              return true;
            })
          : schema.notRequired();
      }),
    zip: yup
      .string()
      // .matches(/^\d{5}(-\d{4})?$/)
      .required(),
    listingStatus: yup
      .object({
        label: yup.string().required(),
        value: yup.string().required(),
      })
      .required()
      .test('listingStatus', 'listingStatus is invalid', function ({ value }) {
        if (!value) {
          return this.createError({
            message: 'listingStatus is invalid',
          });
        }
        return true;
      }),
    propertyType: yup
      .object({
        label: yup.string().required(),
        value: yup.string().required(),
      })
      .nullable()
      .required()
      .test('propertyType', 'propertyType is invalid', function ({ value }) {
        if (!value) {
          return this.createError({
            message: 'propertyType is invalid',
          });
        }
        return true;
      }),
    listPrice: yup.string().min(1).required(),
    soldPrice: yup
      .string()
      .min(1)
      .when('listingStatus', {
        is: ({ value }: { value: string }) => value === 'sold',
        then: schema => schema.required(),
        otherwise: schema => schema.notRequired(),
      }),
    bedroomCount: yup.number().min(1).required(),
    livingAreaSqft: yup.string().when('isCanadaUser', ([isCanadaUser], schema) => {
      return isCanadaUser ? schema.notRequired() : schema.required().min(1);
    }),
    markerPosition: yup
      .object({
        lat: yup.number().required(),
        lng: yup.number().required(),
      })
      .required()
      .test('markerPosition', 'markerPosition is invalid', function ({ lat, lng }) {
        if (!lat || !lng) {
          return this.createError({
            message: 'markerPosition is invalid',
          });
        }
        return true;
      }),
    virtualTour: yup
      .string()
      .notRequired()
      .nullable()
      .matches(/(youtube|youtu|loom)\.(com|be)\/((watch\?v=([-\w]+))|(share\/([-\w]+))|([-\w]+))/, {
        excludeEmptyString: true,
        message: 'Invalid virtual tour link',
      }),
  })
  .required();
