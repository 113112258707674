import { useQuery } from '@tanstack/react-query';
import { corePresentApi } from '../../../../../api/CorePresentApi';
import { SendEmailPayload } from '../../../../../types';

const fetchEmailPreview = async (payload: SendEmailPayload | null) => {
  if (!payload) return;
  const {
    client_id,
    subject,
    message,
    signature,
    include_agent_photo,
    pdf_url,
    presentation_hash,
  } = payload || {};
  const preview = await corePresentApi.get('/clients/email-preview', {
    params: {
      client_id,
      subject,
      include_agent_photo: include_agent_photo ? 1 : 0,
      signature,
      pdf_url,
      message,
      presentation_hash,
    },
  });

  return preview;
};

export const useEmailPreview = (payload: SendEmailPayload | null) => {
  const {
    client_id,
    subject,
    message,
    signature,
    include_agent_photo,
    pdf_url,
    presentation_hash,
  } = payload || {};
  return useQuery(
    [
      'email-preview',
      presentation_hash,
      client_id,
      subject,
      message,
      signature,
      include_agent_photo,
      pdf_url,
    ],
    () => fetchEmailPreview(payload),
    {
      enabled: Boolean(client_id) && Boolean(subject) && Boolean(message),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
    },
  );
};
