import { Dispatch, SetStateAction, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { PropertyCommunicator } from '../../../../../../pages/PresentationCreate/dash/communicators/Property/PropertyCommunicator';
import { propertyTypeOptions } from '../../../../../../pages/PresentationCreate/dash/view/pages/Authenticated/PropertySearch/PropertyDetails/PropertySearchCriteria/Criterias/PropertyType/propertyTypeOptions';
import { AddressCommunicator } from '../../../../../../pages/PresentationCreate/dash/communicators/Address/AddressCommunicator';
import { Autocomplete } from '../../../../../../components/Common/Autocomplete/Autocomplete';
import { useUser } from '../../../../../../components/Slide/providers/UserProvider';
import { TAddress } from '../../../../../../types';
import { STATES_OPTIONS } from '../../../../constants/options';
import { useAtomValue, useSetAtom } from 'jotai';
import { customComparableToEditAtom } from '../../../../state/customComparableToEditAtom';
import { without } from 'lodash';
import { defaultMapCenterAtom } from '../../../../state/defaultMapCenterAtom';

interface AddressOption extends TAddress {
  label: string;
  value: string;
}

interface AutosuggestAddressesProps {
  setMarkerPosition: Dispatch<SetStateAction<google.maps.LatLngLiteral | null>>;
  setShowInfoText: (value: boolean) => void;
  isOnMls?: boolean;
}

export const AutosuggestAddresses = ({
  setMarkerPosition,
  setShowInfoText,
  isOnMls,
}: AutosuggestAddressesProps) => {
  const [addressOptions, setAddressOptions] = useState<AddressOption[]>([]);
  const customComparableToEdit = useAtomValue(customComparableToEditAtom);

  const setDefaultCenter = useSetAtom(defaultMapCenterAtom);

  const {
    control,
    setValue,
    clearErrors,
    reset,
    formState: { errors },
    trigger,
  } = useFormContext();

  const { isCanadaUser } = useUser();

  const getAddressOptions = async (address: string) => {
    if (isCanadaUser) {
      return [];
    }
    const addresses: TAddress[] = await AddressCommunicator.search(address, true);

    const autocompleteAddresses = addresses.map((address: TAddress) => ({
      ...address,
      label: address.fullAddress,
      value: address.fullAddress,
    }));

    setAddressOptions(autocompleteAddresses);
  };

  const onAddressChange = async (addressQuery: string) => {
    const addressOption = addressOptions.find(({ value }) => value === addressQuery);

    if (!addressOption) {
      return;
    }

    try {
      const propertyInfo = await PropertyCommunicator.search({
        deliveryLine: addressOption.deliveryLine,
        city: addressOption.city,
        state: addressOption.state,
        external: true,
        withoutDefault: true,
      });

      const fullPropertyInfo = {
        ...propertyInfo,
        address: {
          city: addressOption.city,
          deliveryLine: addressOption.deliveryLine,
          state: addressOption.state,
          zip: propertyInfo?.address?.zip,
        },
      };

      const onMlsValues = propertyInfo
        ? {
            propertyType: customComparableToEdit?.propertyType || fullPropertyInfo.propertyType,
            price: customComparableToEdit?.price || fullPropertyInfo.price,
            salePrice: customComparableToEdit?.salePrice || fullPropertyInfo.salePrice,
            beds: customComparableToEdit?.beds || fullPropertyInfo.beds,
            size: customComparableToEdit?.size || fullPropertyInfo.size,
            baths: customComparableToEdit?.baths || fullPropertyInfo.baths,
            totalBaths: customComparableToEdit?.totalBaths || fullPropertyInfo.totalBaths,
            totalSize: customComparableToEdit?.totalSize || fullPropertyInfo.totalSize,
            yearBuilt: customComparableToEdit?.yearBuilt || fullPropertyInfo.yearBuilt,
            coordinates: {
              latitude:
                customComparableToEdit?.coordinates?.latitude ||
                fullPropertyInfo.coordinates.latitude,
              longitude:
                customComparableToEdit?.coordinates?.longitude ||
                fullPropertyInfo.coordinates.longitude,
            },
            address: {
              city: customComparableToEdit?.address?.city || fullPropertyInfo.address.city,
              state: customComparableToEdit?.address?.state || fullPropertyInfo.address.state,
              zip: customComparableToEdit?.address?.zip || fullPropertyInfo.address.zip,
            },
          }
        : null;

      const property =
        isOnMls || customComparableToEdit?.isCreatedFromInvalidListing
          ? onMlsValues
          : fullPropertyInfo;

      if (property) {
        setShowInfoText(true);
        setDefaultCenter({
          lat: property.coordinates.latitude,
          lng: property.coordinates.longitude,
        });
      }

      const state = STATES_OPTIONS.find(({ value }) => value === property?.address?.state) || null;

      if (property?.coordinates?.latitude && property?.coordinates?.longitude) {
        setMarkerPosition({
          lat: property?.coordinates?.latitude,
          lng: property?.coordinates.longitude,
        });
      }
      if (addressOption.city) {
        setValue('city', property?.address?.city);
        clearErrors('city');
      }
      if (state) {
        setValue('state', state);
        clearErrors('state');
      }

      if (property?.address?.zip) {
        setValue('zip', property?.address?.zip);
        clearErrors('zip');
      }
      if (property?.propertyType) {
        const propertyType =
          propertyTypeOptions.find(({ value }) => value === property?.propertyType) || null;
        setValue('propertyType', propertyType);
        clearErrors('propertyType');
      }
      if (property?.price) {
        setValue('listPrice', property?.price);
        clearErrors('listPrice');
      }
      if (property?.salePrice) {
        setValue('soldPrice', property?.salePrice);
        clearErrors('soldPrice');
      }
      if (property?.beds) {
        setValue('bedroomCount', property?.beds);
        clearErrors('bedroomCount');
      }
      if (property?.size) {
        setValue('livingAreaSqft', property?.size);
        clearErrors('livingAreaSqft');
      }

      if (property?.baths) {
        setValue('baths', property?.baths);
        clearErrors('bathroomCount');
      }

      if (property?.totalBaths) {
        setValue('totalBaths', property?.totalBaths);
        clearErrors('totalBaths');
      }

      if (property?.totalSize) {
        setValue('totalSize', property?.totalSize);
        clearErrors('totalSize');
      }

      if (property?.yearBuilt) {
        setValue('yearBuilt', property?.yearBuilt);
        clearErrors('yearBuilt');
      }
    } catch {
      setShowInfoText(false);
      if (isOnMls || customComparableToEdit?.isCreatedFromInvalidListing) return;

      reset({
        isCanadaUser,
        city: '',
        state: null,
        zip: '',
        propertyType: null,
        listPrice: '',
        bedroomCount: '',
        livingAreaSqft: '',
        baths: '',
        totalBaths: '',
        totalSize: '',
        yearBuilt: '',
        mlsListingId: customComparableToEdit?.id || '',
      });
      trigger();
      setValue('address', addressOption.fullAddress, { shouldValidate: true });
    }
  };

  const isDisabled = Boolean(isOnMls && customComparableToEdit?.address?.deliveryLine);

  return (
    <Controller
      name='address'
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          label='Address:'
          required={!isDisabled}
          onChange={e => {
            field.onChange(e);
            onAddressChange(e);
          }}
          suggestions={addressOptions}
          asyncOptionsProvider={getAddressOptions}
          error={errors.address ? (errors.address.message as string) : undefined}
          hideErrorMessage
          disabled={isDisabled}
        />
      )}
    />
  );
};
