import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Icon, Typography } from './DynamicSection.styles';
import { TDynamicSection, TSection } from '../../../../../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDndContext } from '@dnd-kit/core';

interface ExpandableStyles {
  root?: React.CSSProperties;
}

export interface OrderableSlidesComponentProps {
  section: TDynamicSection;
  open: boolean;
}

interface Props {
  open: boolean;
  section: TDynamicSection;
  styles?: ExpandableStyles;
  index: number;
  onToggle: (sectionId: TSection) => void;
  OrderableSlidesComponent: React.FC<OrderableSlidesComponentProps>;
}

export const DynamicSection: React.FC<Props> = ({
  open,
  section,
  styles,
  index,
  onToggle,
  OrderableSlidesComponent,
}) => {
  const dndContext = useDndContext();

  const isSectionMove = dndContext.active?.data?.current?.isSection;

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: section.id,
    data: {
      isSection: true,
    },
    disabled: isSectionMove,
  });

  const style = isSectionMove
    ? {
        transform: CSS.Transform.toString(transform),
        transition,
      }
    : {};

  const isCoverSlidesSection = section?.id === 'startingSlides';

  return (
    <Container
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={styles?.root ? { ...styles.root, ...style } : style}
    >
      <Head
        onClick={e => {
          e.stopPropagation();
          onToggle(section.id as TSection);
        }}
      >
        <TextWrapper>
          {!isCoverSlidesSection && <Label>Section</Label>}
          {section.label}
        </TextWrapper>
        <FontAwesomeIcon icon={['fas', 'caret-up']} rotation={open ? 180 : undefined} />
      </Head>
      <OrderableSlidesComponent section={section} open={open} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* background-color: ${({ theme }) => theme.colors.v2.background}; */
  border: 1px solid ${({ theme }) => theme.colors.v2.gray[200]};
  border-top: none;
  color: ${({ theme }) => theme.colors.v2.text.regular};
  /* overflow: hidden; */
  width: 100%;
  flex-shrink: 0;
  font-weight: 700;
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 20px 25px;
  cursor: pointer;
  flex-shrink: 0;
`;

const Label = styled.span`
  font-size: 10px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.v2.gray[300]};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
`;
