import styled from 'styled-components';
import Loader from '../../../../Loader/Loader';

interface Props {
  preview: string;
  isLoading?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const EmailPreview = ({ preview, isLoading }: Props) => {
  if (isLoading)
    return (
      <Wrapper>
        <Loader size='3x' />
      </Wrapper>
    );

  return (
    <Wrapper>
      <iframe
        title='Email Preview'
        srcDoc={preview}
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          backgroundColor: 'white',
        }}
      />
    </Wrapper>
  );
};
