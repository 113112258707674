import React, { createContext, useContext, useEffect, useState } from 'react';

import type { TProperty } from '../../types';
import { getPropertyId } from '../../features/report/services/getPropertyId';
import { FEATURES, useHasFeatureFlag } from '../../hooks/useFeatureFlags';

type TPropertiesContext = {
  properties: TProperty[];
  setProperties: (properties: TProperty[]) => void;
  addProperty: (property: TProperty) => void;
  updateProperty: (property: TProperty) => void;
  removeProperty: (id: string) => void;
};

const PropertiesContext = createContext<TPropertiesContext | undefined>(undefined);

export function useProperties() {
  const context = useContext(PropertiesContext);

  if (!context) {
    throw new Error('useProperties must be used within a PropertiesContext');
  }

  return context;
}

export const PropertiesProvider: React.FC = ({ children }) => {
  const [properties, setProperties] = useState<TProperty[]>([]);
  const hasCustomComparables = useHasFeatureFlag(FEATURES.CpCustomComparables);

  const addProperty = React.useCallback(function addProperty(...property: TProperty[]) {
    // .filter((p, i, self) => {
    //   const _p = JSON.stringify(p);
    //   return i === self.findIndex(_theP => JSON.stringify(_theP) === _p);
    // }),

    setProperties(prev => {
      const active = [...prev, ...property].filter(p => p.status === 'active');
      const inactive = [...prev, ...property].filter(p => p.status !== 'active');
      return [...active, ...inactive];
    });
  }, []);

  const removeProperty = React.useCallback(
    function removeProperty(id: string) {
      setProperties(prev => {
        return prev.filter(prevProperty => {
          const propertyId = hasCustomComparables ? prevProperty.id : getPropertyId(prevProperty);
          return propertyId !== id;
        });
      });
    },
    [hasCustomComparables],
  );

  const updateProperty = React.useCallback(
    function updateProperty(property: TProperty) {
      const propertyId = hasCustomComparables ? property.id : getPropertyId(property);
      setProperties(prev => prev.map(p => (p.id === propertyId ? { ...property } : p)));
    },
    [hasCustomComparables],
  );

  const value = React.useMemo(
    () => ({
      properties,
      setProperties,
      addProperty,
      removeProperty,
      updateProperty,
    }),
    [addProperty, properties, removeProperty, updateProperty],
  );

  return <PropertiesContext.Provider value={value}>{children}</PropertiesContext.Provider>;
};
