import styled from 'styled-components';
import { ManualEntryContent } from './ManualEntryContent';
import { ManualEntryMap } from '../../../../../components/ManualEntryMap/ManualEntryMap';
import { useMemo, useState } from 'react';
import {
  ADDRESS_CREATION_FIELDS,
  TAddressCreationFields,
  TManualAddress,
  TProperty,
  TSubject,
} from '../../../../../types';
import { useAtomValue } from 'jotai';
import { manualAddressAtom } from '../../state/manualAddressAtom';
import { CurrentUserService } from '../../../../../services/CurrentUserService';
import { useCreateAddress } from './hooks/useCreateAddress';
import { PresentationCreationCTA } from '../../../components/PresentationCreationCTA';
import { FEATURES, useHasFeatureFlag } from '../../../../../hooks/useFeatureFlags';
import { useSetInitialConfig } from '../hooks/useSetInitialConfig';
import { useOnAddressContinue } from '../hooks/useOnAddressContinue';

export const SubjectManualEntry = () => {
  const [defaultCenter, setDefaultCenter] = useState({ lat: 36.778259, lng: -119.417931 });
  const manualAddress = useAtomValue(manualAddressAtom);
  const setInitialConfig = useSetInitialConfig();
  const onAddressContinue = useOnAddressContinue();

  const hasBathCountFF = useHasFeatureFlag(FEATURES.CpSubjectBathCount);

  const { mutateAsync: createAddress, isLoading } = useCreateAddress();

  const [fields, setFields] = useState<TAddressCreationFields>({
    [ADDRESS_CREATION_FIELDS.ADDRESS]: manualAddress?.address || '',
    [ADDRESS_CREATION_FIELDS.APT_NUMBER]: manualAddress?.number || '',
    [ADDRESS_CREATION_FIELDS.CITY]: manualAddress?.city || '',
    [ADDRESS_CREATION_FIELDS.STATE]: '',
    [ADDRESS_CREATION_FIELDS.BEDS]: manualAddress?.beds ? String(manualAddress.beds) : '',
    [ADDRESS_CREATION_FIELDS.SIZE]: manualAddress?.size ? String(manualAddress.size) : '',
    [ADDRESS_CREATION_FIELDS.YEAR_BUILT]: '',
    [ADDRESS_CREATION_FIELDS.BATHS]: manualAddress?.baths
      ? String(manualAddress.baths)
      : hasBathCountFF
        ? ''
        : '0',
  });
  const [errors, setErrors] = useState<TAddressCreationFields>({
    [ADDRESS_CREATION_FIELDS.ADDRESS]: '',
    [ADDRESS_CREATION_FIELDS.APT_NUMBER]: '',
    [ADDRESS_CREATION_FIELDS.CITY]: '',
    [ADDRESS_CREATION_FIELDS.STATE]: '',
    [ADDRESS_CREATION_FIELDS.BEDS]: '',
    [ADDRESS_CREATION_FIELDS.SIZE]: '',
    [ADDRESS_CREATION_FIELDS.YEAR_BUILT]: '',
    [ADDRESS_CREATION_FIELDS.BATHS]: '',
  });

  const [markerPosition, setMarkerPosition] = useState<google.maps.LatLngLiteral | null>(null);

  const startBuildingReport = (address: TManualAddress, property: TSubject) => {
    const searchCriteria = setInitialConfig(address, property);

    return onAddressContinue(searchCriteria);
  };

  const onFieldChange = (field: ADDRESS_CREATION_FIELDS) => {
    return (value: string) => {
      if (
        field === ADDRESS_CREATION_FIELDS.YEAR_BUILT ||
        field === ADDRESS_CREATION_FIELDS.BEDS ||
        field === ADDRESS_CREATION_FIELDS.SIZE ||
        field === ADDRESS_CREATION_FIELDS.BATHS
      ) {
        const numValue = Number(value);
        setErrors({ ...errors, [field]: numValue < 0 || isNaN(numValue) ? 'Invalid value' : '' });
      }

      setFields({ ...fields, [field]: value });
    };
  };

  const completeManualProperty = async () => {
    const { address, number, state, city } = fields;

    const addressData = { address, number, state, city };
    const searchCriteria = await createAddress({ fields, markerPosition });

    startBuildingReport(addressData, searchCriteria);
  };

  const isEverythingPopulated = useMemo(() => {
    const nonRequired = ['number', 'yearBuilt', 'totalSize', 'state'];
    if (CurrentUserService.isCanadaUser()) {
      nonRequired.push('size');
    }

    if (!hasBathCountFF) {
      nonRequired.push('baths');
    }

    const fieldsKeys = Object.keys(fields) as ADDRESS_CREATION_FIELDS[];
    const nonPopulatedFields = fieldsKeys.filter(
      field =>
        !nonRequired.includes(field) &&
        (fields[field] === '' || fields[field] === undefined || errors[field]),
    );
    return nonPopulatedFields.length === 0;
  }, [errors, fields, hasBathCountFF]);

  return (
    <Container>
      <Text>
        Title data was not found and property info will need to be entered manually to proceed. An
        incident report is being sent to our product team to help ensure that this is not a
        recurring issue for you.
        <br />
        <br />
        We apologize for the inconvenience.
      </Text>

      <Wrapper>
        <ManualEntryContent fields={fields} errors={errors} onFieldChange={onFieldChange} />
        <ManualEntryMap
          address={manualAddress}
          markerPosition={markerPosition}
          defaultCenter={defaultCenter}
          setMarkerPosition={setMarkerPosition}
          setDefaultCenter={setDefaultCenter}
        />
        <FooterWrapper>
          <PresentationCreationCTA
            nextText='Next: Comparable Details'
            onNext={completeManualProperty}
            disabled={!isEverythingPopulated || isLoading || !!errors.yearBuilt}
            loading={isLoading}
          />
        </FooterWrapper>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div``;

const Text = styled.p`
  color: ${({ theme }) => theme.colors.v2.gray[400]};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 30px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const FooterWrapper = styled.div`
  margin-top: 50px;
`;
