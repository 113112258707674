import { getCompositeKey } from '../../../services/getCompositeKey';
import { transformOnMlsCompositeId } from '../../../services/transformOnMlsCompositeId';
import { PropertyGroups } from '../../../types';

export const extractOnMlsComparables = (properties: PropertyGroups, mlsIds: string[]) => {
  if (!properties) return [];

  return Object.values(properties)
    .flatMap(group => group)
    .filter(property => {
      const transformedMlsIds = mlsIds?.map(mls => transformOnMlsCompositeId(mls)?.listingId);

      return (
        (!property?.isCustom && transformedMlsIds.includes(property?.id || '')) ||
        property?.isCreatedFromInvalidListing
      );
    });
};
