import { useMemo, useState } from 'react';

import styled, { useTheme } from 'styled-components';

import { SendEmailPopup } from '../../../../../../../../components/Common/SendEmailPopup/SendEmailPopup.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePresentationPdf } from '../../../../../../../../hooks/usePresentationPdf';

type FollowUpProps = {
  followUp: string[];
  presentation?: any;
};

export function FollowUp({ followUp, presentation }: FollowUpProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [chosenFollowUp, setChosenFollowUp] = useState('');
  const { colors } = useTheme();

  const onOpenEmailPopup = (followUp: string) => {
    setIsOpen(true);
    setChosenFollowUp(followUp);
  };

  const onCloseEmailPopup = () => {
    setIsOpen(false);
    setChosenFollowUp('');
  };

  const { data, isLoading } = usePresentationPdf(presentation?.id, presentation?.hash);

  const icon = useMemo(() => {
    if (!presentation?.clientId) return 'circle-info';

    return isOpen ? 'lightbulb-on' : 'lightbulb';
  }, [isOpen, presentation?.clientId]);

  return (
    <Container>
      <FontAwesomeIcon fontSize={18} color={'#fff'} icon={icon} />
      <Wrapper>
        <FollowUpText>Suggested Follow-up:</FollowUpText>
        {followUp.map(followUpText => (
          <TextWrapper key={followUpText}>
            {presentation?.clientId ? (
              <EmailPopupCircle onClick={() => onOpenEmailPopup(followUpText)}>
                {followUpText === chosenFollowUp && (
                  <FontAwesomeIcon icon='check' color={colors.v2.primary} />
                )}
              </EmailPopupCircle>
            ) : null}
            <Text>{followUpText}</Text>
          </TextWrapper>
        ))}
      </Wrapper>
      <SendEmailPopup
        address={presentation?.reportData?.report?.reportDetailed?.subject?.address?.deliveryLine}
        presentationType={presentation?.type}
        isOpen={!!isOpen}
        onClose={onCloseEmailPopup}
        headerTitle={'Send Email'}
        initialSubject='Follow up regarding your presentation'
        initialMessage={chosenFollowUp}
        presentationHash={presentation?.hash ?? ''}
        client={{
          id: presentation?.clientId,
          name: presentation?.clientName,
          email: presentation?.clientEmail,
        }}
        pdfUrl={data?.pdf || ''}
        isPdfGenerating={data?.isBuildInProgress || isLoading}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  gap: 10px;
  padding: 12px 20px;
  z-index: 2;
  background: ${({ theme }) => theme.colors.v2.text.regular};
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
`;

const FollowUpText = styled.div`
  width: 100%;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${({ theme }) => theme.colors.v2.text.contrastText};
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 5px;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.colors.v2.text.contrastText};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const EmailPopupCircle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 100px;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.v2.background};
  border: 1px solid ${({ theme }) => theme.colors.v2.gray[300]};
  display: flex;
  align-items: center;
  justify-content: center;
`;
