import { PropertyGroups, TProperty } from '../../../types';
import { getPropertyId } from './getPropertyId';

export const extractExcluded = (propertyGroups: PropertyGroups) => {
  const properties = Object.values(propertyGroups)
    .flatMap(group => group)
    .filter(property => property?.excluded)
    .map(property => {
      if (!property) return null;
      if (property?.isCustom) {
        return property.sourceId || property.id;
      }

      return getPropertyId(property);
    });

  return properties.filter(Boolean) as TProperty['id'][];
};
