import axios from 'axios';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';

import { CATEGORIES } from '../../../constants/categories';
import { corePresentApi } from '../../../api/CorePresentApi';
import { StorageService } from '../../../services/storageService';
import { PRESENTATION_TYPES } from '../../../constants/PresentationTypes';
import { TClient, TPresentation, TPresentationType, TProperty, TSlide } from '../../../types';
import { SlidesConfigToNewProvidersAdapter } from '../../../services/SlidesConfigToNewProvidersAdapter';
import { getCompositeKey } from '../../../services/getCompositeKey';

function extendWithRegularPresentationConfig(data: any) {
  if (data.regular || data.type === PRESENTATION_TYPES.BUYER_TOUR) return data;

  const { slides, sections, order } = SlidesConfigToNewProvidersAdapter.adapt(data.slides);

  data.regular = {
    sections,
    order,
  };

  data.slidesMap = slides;

  return data;
}

export class PresentationApi {
  static async fetch(hash: string): Promise<TPresentation> {
    try {
      const res = await corePresentApi.get(`/presentations/${hash}`);

      if (!res.data.category) {
        res.data.category = CATEGORIES.TRADITIONAL;
      }

      const data = extendWithRegularPresentationConfig(res.data);

      const presentationData = {
        ...data,
        user: res.user,
        hash: res?.hash,
        agreementLink: res?.agreement_link,
        videoLink: data.videoLink || res?.video_link,
        clientId: res?.client_id,
        clientName: res?.client_name,
        clientEmail: res?.client_email,
        id: res?.id,
        createdAt: (res.created_at_timestamp ?? 0) * 1000,
        mode: res?.data.mode ?? 'regular',
        comparableProperties: res?.comparable_properties,
        version: res?.version ?? 1,
        graphs: res?.graphs,
      };

      return presentationData;
    } catch (e: any) {
      console.log(e);
      throw new Error(e.message);
    }
  }

  static async fetchAll(): Promise<
    {
      id: number;
      hash: string;
      data: TPresentation;
      createdAt: string;
      build_in_progress: boolean;
      pdf: string;
    }[]
  > {
    const res = await corePresentApi.get(`/presentations`);
    return res;
  }

  static async updateClient(id: string, client: TClient) {
    return corePresentApi.put(`/presentations/${id}`, {
      client_name: client.name,
      client_id: client.id,
      client_email: client.email,
    });
  }

  static async create(
    presentation: any,
    clientArg: any = null,
    agreement: string | null = null,
    shouldBeSaved = true,
  ): Promise<{ id: string; hash: string; client: any }> {
    try {
      const getPresentationType = (type: TPresentationType) => {
        const typesMap = {
          [PRESENTATION_TYPES.WIN_THE_LISTING]: 'seller',
          [PRESENTATION_TYPES.WIN_THE_OFFER]: 'buyer',
          [PRESENTATION_TYPES.PRESENT_INFO]: 'info',
          [PRESENTATION_TYPES.BUYER_TOUR]: PRESENTATION_TYPES.BUYER_TOUR,
          [PRESENTATION_TYPES.WIN_THE_REPRESENTATION]: PRESENTATION_TYPES.WIN_THE_REPRESENTATION,
        };

        return typesMap[type] ?? type;
      };

      const res = await corePresentApi.post(`/presentations`, {
        data: JSON.stringify(presentation),
        type: getPresentationType(presentation.type),
        agreement,
        should_be_saved: shouldBeSaved,
        ...(presentation.mode ? { mode: presentation.mode } : {}),
      });
      const id = res.id;
      const hash = res.hash;
      let client = {
        id: null,
        email: null,
        name: null,
      };
      if (clientArg && id) {
        await PresentationApi.updateClient(id, clientArg);

        client = clientArg;
      }

      return { id, hash, client };
    } catch (e: any) {
      console.error(e);
      if (e.status >= 400) {
        toast(
          'We had trouble creating your presentation. Please try again. If the issue persists, please contact our support team.',
          {
            type: 'error',
            position: 'bottom-center',
          },
        );
      }
      throw new Error(e.message);
    }
  }

  static async update(id: string, presentation: any, shouldBeSaved = true): Promise<TPresentation> {
    try {
      const res = await corePresentApi.put(`/presentations/${id}`, {
        data: JSON.stringify(presentation),
        mode: presentation.mode,
        should_be_saved: shouldBeSaved,
      });

      return res;
    } catch (e: any) {
      console.error(e);
      throw new Error(e.message);
    }
  }
  static async updateBT({
    id,
    presentation,
    shouldBeSaved,
    properties,
    client,
  }: {
    id: string;
    presentation: any;
    shouldBeSaved: boolean;
    properties: TProperty[];
    client: TClient;
  }): Promise<TPresentation> {
    try {
      const res = await corePresentApi.put(`/presentations/${id}`, {
        data: JSON.stringify(presentation),
        property_ids: properties?.map((p: TProperty) => {
          if (p.isCustom) return p.id;

          return getCompositeKey(p);
        }),
        mode: presentation.mode,
        should_be_saved: shouldBeSaved,
        client_name: client.name,
        client_id: client.id,
        client_email: client.email,
      });

      return res;
    } catch (e: any) {
      console.error(e);
      throw new Error(e.message);
    }
  }

  static async delete(id: string): Promise<void> {
    try {
      await corePresentApi.delete(`/presentations/${id}`);
    } catch (e: any) {
      console.error(e);
      throw new Error(e.message);
    }
  }

  static async getPresentationPdf(hash: string) {
    const res = await corePresentApi.get(`/presentations/${hash}`);

    return {
      pdf: res.pdf,
      isBuildInProgress: !!res.build_in_progress,
    };
  }

  static triggerPdfGeneration(uuid: string): Promise<boolean> {
    const token = StorageService.read('token');

    try {
      return new Promise(resolve => {
        axios
          .get(`${import.meta.env.VITE_APP_CP_PDF}/generate-pdf?type=presentation&uuid=${uuid}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(() => resolve(true))
          .catch(e => console.log(e));

        setTimeout(() => resolve(true), 1500);
      });
    } catch (e: any) {
      console.log(e);
    }

    return Promise.reject();
  }

  static async singlePdfGenerate(data: any, slide: TSlide) {
    const token = StorageService.read('token');

    const encodedData = encodeURIComponent(JSON.stringify(data));

    try {
      const pdfGeneratorResponse = await axios.get(
        `${import.meta.env.VITE_APP_CP_PDF}/generate-pdf?type=single_slide&data=${encodedData}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const pdfResponse = await axios.get(pdfGeneratorResponse.data.url, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
      });

      const blob = new Blob([pdfResponse.data]);
      saveAs(blob, slide.label + '.pdf');
    } catch (e: any) {
      throw new Error(e);
    }
  }

  static async downloadPresentation(pdf: string, presentationHash: string) {
    try {
      const pdfResponse = await axios.get(pdf, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
      });

      const blob = new Blob([pdfResponse.data]);
      saveAs(blob, presentationHash + '.pdf');
    } catch (e: any) {
      throw new Error(e);
    }
  }
}
