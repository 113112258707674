import { Controller, useFormContext } from 'react-hook-form';

import { COMPARABLE_MODAL_MENU_SUBITEM_ID } from '../../../../../../../types';
import { Input } from '../../../../../../../components/Common/V2/Input';
import { SubSection } from '../SubSection';

export const VirtualTour = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <SubSection
      title='Virtual Tour'
      titleId={COMPARABLE_MODAL_MENU_SUBITEM_ID.VIRTUAL_TOUR}
      withHorizontalPadding
    >
      <Controller
        name='virtualTour'
        control={control}
        defaultValue={null}
        render={({ field }) => (
          <Input
            placeholder='Virtual Tour Link'
            {...field}
            error={errors.virtualTour ? (errors.virtualTour.message as string) : undefined}
          />
        )}
      />
    </SubSection>
  );
};
