import { TablePropertyStatus } from '../../../../types';

const tableConfig = Object.freeze({
  address: 'Address',
  price: 'List price',
  salePrice: 'Sale price',
  bd: 'BR',
  bth: 'BA',
  sqft: 'Sqft',
  pricePerSq: '$/Sq',
  built: 'Built',
  listDate: 'List Date',
  cdos: 'DOS',
  source: 'SRC',
});

export const getTableColumns = (type: TablePropertyStatus, isDTC: boolean) => {
  if (type === 'sold') {
    return { ...tableConfig, listDate: 'Sold Date', cdos: isDTC ? 'Days To Close' : 'DOS' };
  }

  if (type === 'pending') {
    return { ...tableConfig, cdos: '' };
  }

  return tableConfig;
};

export const getTableTitle = (type: TablePropertyStatus) => {
  const titles = {
    active: 'Active',
    pending: 'Pending',
    sold: 'Closed',
    comingSoon: 'Coming Soon',
  };

  return titles[type];
};
