import { getCompositeKey } from '../../../../../../../services/getCompositeKey';
import { listingTypeOptions } from './PropertyDetails/PropertySearchCriteria/Criterias/ListingType/listingTypeOptions';
import { propertyTypeOptions } from './PropertyDetails/PropertySearchCriteria/Criterias/PropertyType/propertyTypeOptions';

export const selectOptions = (options, values) => {
  if (typeof value !== 'string' && !Array.isArray(values)) {
    values = `${values}`;
  }

  return options.filter(option => values.includes(option.value));
};

export const rebuildSearchCriteriaFromReport = (d, subject, searchCriteria, properties) => {
  const propertiesList = properties ? Object.values(properties)?.flatMap(p => p) : [];

  const manualMlsIds = searchCriteria?.manualMlsIds?.map(mlsId => {
    const property = propertiesList.find(property => property.mlsListingId === mlsId);
    return getCompositeKey(property) || mlsId;
  });

  const additionalIds = d.additional_ids?.map(mlsId => {
    const property = propertiesList.find(property => property.mlsListingId === mlsId);
    return getCompositeKey(property) || mlsId;
  });

  const reportType = !d?.report_type
    ? {
        additional: additionalIds?.filter(mlsId => !manualMlsIds?.includes(mlsId)) ?? [],
        manualMlsIds: manualMlsIds ?? [],
      }
    : {
        reportType: d.report_type,
        manualMlsIds: additionalIds ?? [],
      };

  return {
    ...reportType,
    id: subject.id,
    propertyType: subject.property_type,
    status: subject.sale_type,
    beds: subject.beds,
    baths: subject.baths,
    totalBaths: subject.total_baths || subject.baths,
    size: subject.size,
    totalSize: subject.total_size,
    yearBuilt: subject.year_built,
    totalSizeUnit: d.total_size_unit,
    coordinates: subject.coordinates,
    propertyTypeSelections: selectOptions(propertyTypeOptions, d.property_type),
    statusSelections: selectOptions(listingTypeOptions, d.sale_type),
    bedsRange: [d.bed_from - parseInt(subject.beds), d.bed_to - parseInt(subject.beds)],
    bathsRange: [d.total_baths_from || 1, d.total_baths_to || 10],
    saleDateLimitRange: [d.sale_date_limit_from, d.sale_date_limit_to],
    sizeRange: [d.size_from, d.size_to],
    totalSizeSet: d.total_size_set,
    totalSizeRange: [d.total_size_from, d.total_size_to],
    ignoreSize: d.ignoreSize,
    mapMode: searchCriteria.mapMode,
    mapCoordinates: d.polygon ? d.polygon : [{ lat: d.lat, lon: d.lon }],
    radius: d.radius ? { label: d.radius, value: d.radius } : { label: 1, value: 1 },
    exclusions: d?.excluded_ids ?? [],
    areaDetails: searchCriteria.areaDetails,
    areaType: searchCriteria.areaType,
  };
};

export const rebuildAddressFromSubject = ({ full_address }) => {
  const { city, state, delivery_line } = full_address;

  return {
    address: delivery_line,
    number: '',
    city,
    state: {
      value: state,
      label: state,
    },
  };
};
