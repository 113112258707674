import { Input } from '../../../components/Common/V2/Input';
import { Button } from '../../../components/Common/V2/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { MultiplePropertySourcesPopup } from '../../../pages/PresentationCreate/dash/view/pages/Authenticated/PropertySearch/PropertyDetails/ManualMLSList/ManualMLS/MultiplePropertySourcesPopup/MultiplePropertySourcesPopup';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
  ManualMLSErrors,
  ManualMlsMultipleProperties,
  ON_MLS_ERRORS,
  TProperty,
} from '../../../types';
import { useFetchProperties } from './hooks/useFetchProperties';
import styled from 'styled-components';
import { getCompositeKey } from '../../../services/getCompositeKey';

const valueToUniqueIds = (value: string) => {
  if (!value) return [];

  const csvIds = String(value)
    .trim()
    .replace(/\s+|,+/g, ',');

  return [...new Set(csvIds.split(','))].filter(_m => _m && _m.trim());
};

interface Props {
  onAdd: (ids: string[], properties: TProperty[]) => void;
  isInvalidMLS?: boolean;
  mlsIds: string[];
  subjectId?: string;
  setErrors: Dispatch<SetStateAction<ManualMLSErrors>>;
  mls?: string;
  onModalOpen: (id?: string) => void;
  isSingleMlsInputError?: boolean;
  setIsSingleMlsInputError?: Dispatch<SetStateAction<boolean>>;
  setSingleMlsInputErrorProperty?: Dispatch<SetStateAction<TProperty | null>>;
}

export const OnMLSInput = ({
  onAdd,
  isInvalidMLS,
  mlsIds,
  setErrors,
  subjectId = '',
  mls,
  onModalOpen,
  isSingleMlsInputError,
  setIsSingleMlsInputError,
  setSingleMlsInputErrorProperty,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [innerValue, setInnerValue] = useState(mls || '');
  const [multipleResultError, setMultipleResultError] = useState<ManualMLSErrors>({});

  const hasError = isInvalidMLS || isSingleMlsInputError;

  const [modalProperties, setModalProperties] = useState<ManualMlsMultipleProperties>({});
  const fetchProperties = useFetchProperties();

  const hasMultipleResults = useMemo(
    () => !!Object.keys(modalProperties ?? {}).length,
    [modalProperties],
  );

  useEffect(() => {
    if (!hasError) setInnerValue('');
  }, [hasError]);

  const errorsCleanup = (updatedErrors: ManualMLSErrors, mls?: string) => {
    if (!mls) return updatedErrors;
    if (updatedErrors[mls]) delete updatedErrors[mls];
    return updatedErrors;
  };

  const triggerAddMls = async (id: string) => {
    if (!id) return;
    const ids = valueToUniqueIds(id).filter(_m => !mlsIds.includes(_m));

    setLoading(true);

    try {
      const { properties, errors, hasErrors, multipleResults, hasMultipleResults } =
        await fetchProperties(ids, subjectId);

      const filteredIds = ids
        .filter(_id => !errors[_id])
        ?.map(_id => {
          const property = properties.find(p => p.id === _id);
          return getCompositeKey(property);
        });

      onAdd(
        filteredIds.filter(_id => !(multipleResults as any)[_id]),
        properties,
      );

      if (hasMultipleResults) {
        setModalProperties(multipleResults as any);
        setMultipleResultError(errors as any);
        return;
      }

      if (hasErrors) {
        setLoading(false);

        if (ids?.length === 1) {
          setIsSingleMlsInputError?.(true);
          setSingleMlsInputErrorProperty?.(properties[0]);
          return;
        }

        setIsSingleMlsInputError?.(false);
        setSingleMlsInputErrorProperty?.(null);
        setErrors(prev => {
          const updatedErrors = { ...prev, ...errors };
          return errorsCleanup(updatedErrors, mls);
        });

        setInnerValue('');
        return;
      }

      setLoading(false);
      setInnerValue('');
      setIsSingleMlsInputError?.(false);
      setSingleMlsInputErrorProperty?.(null);
      setErrors(prev => {
        const updatedErrors = { ...prev, ...errors };
        return errorsCleanup(updatedErrors, mls);
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onClosePropertyModal = () => {
    setModalProperties({});
    setInnerValue('');
    setLoading(false);
  };

  const onConfirmPropertyModal = (selectedProperties: { uuid: string; property: TProperty }[]) => {
    const ids = selectedProperties.map(selection => selection?.uuid);
    const _mlsIds = ids.filter(_m => !mlsIds.includes(_m));
    const invalidProperties = selectedProperties.filter(
      selection => !selection?.property.isValidMlsListing,
    );

    const inputValueIds = valueToUniqueIds(innerValue).filter(_m => !mlsIds.includes(_m));
    const isSingleIdInput = inputValueIds.length === 1;

    if (invalidProperties.length) {
      if (!isSingleIdInput) {
        invalidProperties.forEach(invalidProperty => {
          setErrors(prev => {
            const updatedErrors = {
              ...prev,
              [invalidProperty.property.id]: {
                property: invalidProperty.property,
                error: ON_MLS_ERRORS.INVALID,
              },
            };
            return errorsCleanup(updatedErrors, mls);
          });
        });
      }

      if (isSingleIdInput) {
        setIsSingleMlsInputError?.(true);
        setSingleMlsInputErrorProperty?.(invalidProperties[0]?.property);
      }
    }

    if (!invalidProperties.length) {
      setIsSingleMlsInputError?.(false);
      setSingleMlsInputErrorProperty?.(null);
    }

    const filteredIds = _mlsIds.filter(_id => !invalidProperties.map(p => p.uuid).includes(_id));

    onAdd(
      filteredIds,
      selectedProperties.map(selection => selection?.property),
    );

    setModalProperties({});
    (!isSingleIdInput || !invalidProperties?.length) && setInnerValue('');
    setLoading(false);

    if (multipleResultError) {
      setErrors(prev => {
        const updatedErrors = { ...prev, ...multipleResultError };
        return errorsCleanup(updatedErrors, mls);
      });
    }
  };

  return (
    <Wrapper>
      <Input
        type='text'
        value={innerValue || ''}
        error={hasError ? 'Invalid MLS ID!' : ''}
        placeholder='Enter MLS IDs'
        onChange={({ target }) => setInnerValue(target.value)}
        wrapperStyle={{ marginBottom: hasError ? '20px' : 0 }}
        innerSufix={
          <Button
            variant='primary'
            style={{ borderRadius: '0px 5px 5px 0px' }}
            isLoading={loading}
            onClick={() => triggerAddMls(innerValue)}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </Button>
        }
      />
      {hasError && <Error onClick={() => onModalOpen(innerValue)}>Create Custom Comparable?</Error>}
      <MultiplePropertySourcesPopup
        properties={modalProperties}
        open={hasMultipleResults}
        onConfirm={onConfirmPropertyModal}
        onClose={onClosePropertyModal}
        onCancel={onClosePropertyModal}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const Error = styled.a`
  color: ${({ theme }) => theme.colors.v2.text.link};
  font-size: 11px;
  font-weight: 500;
  position: absolute;
  right: 10px;
  bottom: 6px;
  cursor: pointer;
`;
