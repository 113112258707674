import * as yup from 'yup';

export type TEmailFormData = yup.InferType<typeof schema>;

export const schema = yup.object({
  client: yup
    .object({
      email: yup.string().required(),
      id: yup.number().required(),
      name: yup.string().required(),
    })
    .required(),
  subject: yup.string().required(),
  message: yup.string().required(),
  isPdfIncluded: yup.boolean().notRequired(),
  signature: yup.string().notRequired(),
  isAgentPhotoIncluded: yup.boolean().notRequired(),
  useDefaultSignature: yup.boolean().notRequired(),
});
