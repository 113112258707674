import styled from 'styled-components';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const CustomComparableInfo = () => {
  return (
    <Wrapper>
      <FontAwesomeIcon icon={faCircleInfo} color='#F0B019' fontSize='18px' />
      <Description>
        This property was manually added by the Agent and was not sourced from the MLS. All
        information is deemed reliable but subject to verification.
      </Description>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.v2.status.info};
`;

const Description = styled.span`
  color: ${({ theme }) => theme.colors.v2.gray[400]};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;
