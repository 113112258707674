import styled from 'styled-components';
import { Children, useState } from 'react';
import { ManualMLSErrors, ON_MLS_ERRORS, SearchCriteria, TProperty } from '../../../types';
import { Droppable } from 'react-beautiful-dnd';
import { ManualComparablesContent } from '../components/ManualComparablesContent';
import { OnMLSInput } from './OnMLSInput';
import { OnMlsComparable } from './OnMlsComparable';
import { CustomComparablesModal } from '../OffMLSComparables/CustomComparablesModal/CustomComparablesModal';
import { useAtomValue, useSetAtom } from 'jotai';
import { customComparableToEditAtom } from '../state/customComparableToEditAtom';
import { onMlsComparablesAtom } from '../state/onMlsComparablesAtom';
import { SearchCombiner } from '../../../pages/PresentationCreate/dash/view/pages/Authenticated/PropertySearch/SearchCombiner';

const DroppableWrapper = ({ children }: { children: React.ReactNode }) => (
  <Droppable droppableId={'manual-mls-list'} type={'manual-mls-list'}>
    {(provided, snapshot) => (
      <SimpleListWrapper
        ref={provided.innerRef}
        {...provided.droppableProps}
        style={{ height: Children.count(children) * 60, width: '100%' }}
      >
        {children}
      </SimpleListWrapper>
    )}
  </Droppable>
);

const SimpleListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
`;

interface Props {
  mlsIds: string[];
  setMlsIds?: (ids: string[]) => void;
  subjectId?: string;
  withDnD?: boolean;
  onlyActive?: boolean;
  addProperty?: (...properties: TProperty[]) => void;
  removeProperty?: (id: string) => void;
  activeProperties?: TProperty[];
  onPartial: (criteria: SearchCriteria) => void;
}

export const OnMLSComparables = ({
  mlsIds,
  setMlsIds,
  subjectId,
  withDnD,
  onlyActive,
  addProperty,
  removeProperty,
  activeProperties,
  onPartial,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errors, setErrors] = useState<ManualMLSErrors>({});

  const [isSingleMlsInputError, setIsSingleMlsInputError] = useState(false);
  const [singleMlsInputErrorProperty, setSingleMlsInputErrorProperty] = useState<TProperty | null>(
    null,
  );
  const [creatingFromInvalidListing, setCreatingFromInvalidListing] = useState(false);

  const onMlsComparables = useAtomValue(onMlsComparablesAtom);

  const setCustomComparableToEdit = useSetAtom(customComparableToEditAtom);
  const onAdd = (ids: string[], properties: TProperty[]) => {
    const finalIds = [...mlsIds, ...ids.filter(_id => _id.trim().length)];
    setMlsIds?.(finalIds);
    addProperty && addProperty(...properties);
  };

  const handleClose = () => {
    setCustomComparableToEdit(null);
    setIsModalOpen(false);
    setCreatingFromInvalidListing(false);
  };

  const handleEditOpenOnError = (id?: string) => {
    if (!id) return;
    const customComparableToCreate = errors[id]?.property || singleMlsInputErrorProperty;
    setCustomComparableToEdit(customComparableToCreate || { id: id });
    setCreatingFromInvalidListing(true);
    setIsModalOpen(true);
  };

  const handleEditOpen = (id?: string) => {
    if (!id) return;
    const customComparableToCreate = onMlsComparables?.find(p => p.id === id);
    setCustomComparableToEdit(customComparableToCreate || { id: id });
    setIsModalOpen(true);
  };

  const ListWrapper = withDnD ? DroppableWrapper : SimpleListWrapper;

  const notFoundMls = Object.keys(errors).filter(
    key =>
      errors[key]?.error === ON_MLS_ERRORS.NOT_FOUND ||
      errors[key]?.error === ON_MLS_ERRORS.INVALID,
  );

  return (
    <ManualComparablesContent description='Property is On-MLS: Manually enter MLS IDs to add comparables. Separate multiple IDs with commas.'>
      <>
        {Boolean(notFoundMls.length) && (
          <>
            {notFoundMls.map((mls, i) => {
              return (
                <OnMLSInput
                  key={mls}
                  onAdd={onAdd}
                  mlsIds={mlsIds}
                  subjectId={subjectId}
                  setErrors={setErrors}
                  mls={mls}
                  isInvalidMLS
                  onModalOpen={() => handleEditOpenOnError(mls)}
                  setSingleMlsInputErrorProperty={setSingleMlsInputErrorProperty}
                  setIsSingleMlsInputError={setIsSingleMlsInputError}
                />
              );
            })}
          </>
        )}

        {Boolean(onMlsComparables?.length) && (
          <ListWrapper>
            {onMlsComparables?.map((property, i) => {
              return (
                <OnMlsComparable
                  key={property?.id}
                  mlsIds={mlsIds}
                  property={property}
                  setMlsIds={setMlsIds}
                  removeProperty={removeProperty}
                  index={i}
                  withDnD={withDnD}
                  notActive={!activeProperties?.some(p => p?.id === property.id)}
                  propertyMlsId={activeProperties?.find(p => p?.id === property.id)?.mlsId}
                  onlyActive={onlyActive}
                  onEdit={() => handleEditOpen(property.id)}
                  onDeleteSuccess={() => onPartial(SearchCombiner.combine())}
                />
              );
            })}
          </ListWrapper>
        )}

        <OnMLSInput
          onAdd={onAdd}
          mlsIds={mlsIds}
          subjectId={subjectId}
          setErrors={setErrors}
          onModalOpen={handleEditOpenOnError}
          isSingleMlsInputError={isSingleMlsInputError}
          setIsSingleMlsInputError={setIsSingleMlsInputError}
          setSingleMlsInputErrorProperty={setSingleMlsInputErrorProperty}
        />
      </>
      <CustomComparablesModal
        isOpen={isModalOpen}
        onClose={handleClose}
        onSuccess={(data: TProperty) => {
          const createdId = data.mlsListingId;
          onPartial(SearchCombiner.combine());
          setErrors?.(prev => {
            delete prev[createdId as string];
            return prev;
          });
          setIsSingleMlsInputError(false);
        }}
        creatingFromInvalidListing={creatingFromInvalidListing}
      />
    </ManualComparablesContent>
  );
};
