import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { Button } from '../../../../../../../../../components/Common/V2/Button/Button';
import { TCustomComparableImage } from '../../../../../../../../../types';

interface Props {
  imageUrl: string;
  isFavorite?: boolean;
  setIsFavorite: (imageUrl: string) => void;
  onDeleteImage: (imageUrl: string) => void;
}

export const ImagePreview = ({ imageUrl, isFavorite, setIsFavorite, onDeleteImage }: Props) => {
  return (
    <Container>
      <ImageWrapper>
        <Img src={imageUrl} alt='' />
      </ImageWrapper>
      <ActionButtons>
        <ActionButton
          variant={isFavorite ? 'primary' : 'secondary'}
          onClick={e => {
            e.stopPropagation();
            setIsFavorite(imageUrl);
          }}
        >
          <FontAwesomeIcon icon={['fas', 'star']} />
        </ActionButton>
        <ActionButton
          variant='secondary'
          onClick={e => {
            e.stopPropagation();
            onDeleteImage(imageUrl);
          }}
        >
          <FontAwesomeIcon icon={['fas', 'trash']} />
        </ActionButton>
      </ActionButtons>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 111px;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const ActionButton = styled(Button)`
  width: 35px;
  height: 35px;
`;
