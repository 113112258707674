import { useDispatch } from 'react-redux';

import {
  ADDRESS_SET,
  REPORT_CLIENTS_SET,
  SEARCH_CRITERIA_SET,
} from '../pages/PresentationCreate/dash/store/search/types';
import {
  rebuildAddressFromSubject,
  rebuildSearchCriteriaFromReport,
} from '../pages/PresentationCreate/dash/view/pages/Authenticated/PropertySearch/SearchRebuild';
import { corePresentApi } from '../api/CorePresentApi';
import { REBUILD_DATA } from '../pages/PresentationCreate/dash/store/report/types';
import { TPresentationType } from '../types';
import { useSetAtom } from 'jotai';
import { customEstimatesAtom } from '../features/report/state/customEstimatesAtom';
import { customMarkersAtom } from '../features/report/state/customMarkersAtom';
import { excludedPropertyIdsAtom } from '../features/report/state/excludedPropertyIdsAtom';
import { highlightedPropertiesAtom } from '../features/report/state/highlighted-properties/highlightedPropertiesAtom';
import { selectedCategoriesAtom } from '../features/report/state/selectedCategoriesAtom';

function stateSubjectToReportSubject(stateSubject: any) {
  return {
    baths: stateSubject.baths,
    total_baths: stateSubject.totalBaths || stateSubject.baths,
    beds: stateSubject.beds,
    coordinates: stateSubject.coordinates,
    full_address: {
      delivery_line: stateSubject.address?.deliveryLine,
      city: stateSubject.address?.city,
      state: stateSubject.address?.state,
      zip: stateSubject.address?.zip,
    },
    id: stateSubject.id,
    property_type: stateSubject.propertyType,
    size: stateSubject.size,
    total_size: stateSubject.totalSize,
    year_built: stateSubject.yearBuilt,
  };
}

export const useRebuildUtils = () => {
  const dispatch = useDispatch();
  const setCustomEstimates = useSetAtom(customEstimatesAtom);
  const setCustomMarkers = useSetAtom(customMarkersAtom);
  const setExcluded = useSetAtom(excludedPropertyIdsAtom);
  const setHighlighted = useSetAtom(highlightedPropertiesAtom);
  const setCategories = useSetAtom(selectedCategoriesAtom);

  const rebuildPresentationWithReport = async (data: any, id: string) => {
    const reportData = data?.data?.reportData;
    if (!reportData?.search?.api) return;

    const properties =
      data.version === 2
        ? data.comparable_properties
        : reportData?.report?.reportDetailed?.properties;

    const reportResult = reportData.result;

    const customEstimates = reportResult.customEstimates;
    const customMarkers = reportResult.customMarkers;
    const excluded = reportResult.excluded;
    const highlighted = reportResult.highlighted;
    const selectedCategories = reportResult.selectedCategories;

    setCustomEstimates(customEstimates);
    setCustomMarkers(customMarkers);
    setExcluded(excluded);
    setHighlighted(highlighted);
    setCategories(selectedCategories);

    const request = { ...reportData.search?.api, excluded_ids: excluded };
    const subject = stateSubjectToReportSubject(reportData.report.reportDetailed.subject);

    const createdAt = Math.floor(new Date().getTime() / 1000);

    let searchCriteria: any = rebuildSearchCriteriaFromReport(
      request,
      subject,
      reportData?.search?.searchCriteria,
      properties,
    );

    searchCriteria = {
      ...searchCriteria,
      reportCreatedAt: createdAt,
      yearBuiltFrom: request.year_built_from,
      yearBuiltTo: request.year_built_to,
      yearBuiltRangeSet: request.year_built_range_set,
      ignoreSize: request.ignore_size,
    };

    const address = rebuildAddressFromSubject(subject);

    dispatch({ type: ADDRESS_SET, payload: address });
    dispatch({ type: SEARCH_CRITERIA_SET, payload: searchCriteria });
    dispatch({ type: REPORT_CLIENTS_SET, payload: {} });

    if (data.client_id) {
      dispatch({
        type: REPORT_CLIENTS_SET,
        payload: {
          name: data.client_name,
          id: data.client_id,
          email: data.client_email,
        },
      });
    }
  };

  const fetchPresentationAndSetToStore = async (hash: string, type: TPresentationType) => {
    const data: any = await corePresentApi.get(`/presentations/${hash}`);

    try {
      if (!data) {
        throw new Error('There is no presentation!');
      }

      return rebuildPresentationWithReport(data, hash);
    } catch (err) {
      console.error('ERROR ON REBUILD', err);
    }
  };

  return { fetchPresentationAndSetToStore };
};
