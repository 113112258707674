import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useCallback, useMemo, useState } from 'react';
import { Table } from '../../../components/Table';
import { Select } from '../../../components/Common';
import NumberFormat from 'react-number-format';
import { Input } from '../../../components/Common/V2/Input';
import styled from 'styled-components';
import { Button } from '../../../components/Common/V2/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Controller, useFormContext } from 'react-hook-form';
import { ComparableFormValues } from './ReviewCustomComparablesModal';
import { LISTING_STATUS_OPTIONS } from '../constants/options';
import { useDeleteCustomComparable } from '../hooks/useDeleteCustomComparable';
import { toast } from 'react-toastify';
import { useUrlSearchParam } from '../../../hooks/useQueryParams';
import { PRESENTATION_TYPES } from '../../../constants/PresentationTypes';

const columnHelper = createColumnHelper<ComparableFormValues>();

interface Props {
  comparables: ComparableFormValues[];
  onUpdate?: () => void;
}

export const ReviewComparablesTable = ({ comparables, onUpdate }: Props) => {
  const { control, watch } = useFormContext();
  const [loading, setLoading] = useState<string | null>(null);

  const { mutateAsync: deleteCustomComparable } = useDeleteCustomComparable();
  const presentationType = useUrlSearchParam('type');

  const handleDelete = useCallback(
    async (id: string) => {
      setLoading(id);
      await deleteCustomComparable(id, {
        onSuccess: async () => {
          onUpdate?.();
          setLoading(null);
        },
        onError: e => {
          console.error(e);
          toast('Failed to delete off-mls comparable.', {
            type: 'error',
            position: 'bottom-center',
          });
        },
      });
    },
    [deleteCustomComparable, onUpdate],
  );

  const columns = useMemo(() => {
    const allValues = watch('customComparables');

    return [
      columnHelper.accessor('address', {
        cell: info => {
          return <Address>{info.getValue()}</Address>;
        },
        header: 'Off-MLS Listing',
        enableSorting: false,
        meta: {
          tdStyles: {
            width: '23.5%',
            padding: '12px 0 12px 10px',
          },
          thStyles: {
            padding: '17px 10px',
          },
        },
      }),
      columnHelper.accessor('status', {
        cell: info => {
          return (
            <Controller
              name={`customComparables.${info.row.index}.status`}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={
                    presentationType === PRESENTATION_TYPES.BUYER_TOUR
                      ? LISTING_STATUS_OPTIONS?.filter(status => status.value === 'active')
                      : LISTING_STATUS_OPTIONS
                  }
                  defaultValue={field.value}
                  selectedOption={field.value}
                  menuPortalTarget={document.body}
                  styles={{ zIndex: 51 }}
                />
              )}
            />
          );
        },
        header: 'LISTING STATUS',
        enableSorting: false,
        meta: {
          tdStyles: {
            width: '23.5%',
            padding: '12px 0 12px 10px',
          },
          thStyles: {
            padding: '17px 10px',
          },
        },
      }),
      columnHelper.accessor('price', {
        cell: info => {
          return (
            <Controller
              name={`customComparables.${info.row.index}.price`}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <NumberFormat
                    customInput={Input}
                    value={field.value}
                    onValueChange={({ floatValue }) => {
                      field.onChange(floatValue || '');
                    }}
                    onBlur={field.onBlur}
                    thousandSeparator={true}
                    prefix={'$'}
                    error={fieldState.error ? (fieldState.error.message as string) : undefined}
                    hideErrorMessage
                  />
                );
              }}
            />
          );
        },
        header: 'LIST PRICE',
        enableSorting: false,
        meta: {
          tdStyles: {
            width: '23.5%',
            padding: '12px 0 12px 10px',
          },
          thStyles: {
            padding: '17px 10px',
          },
        },
      }),
      columnHelper.accessor('salePrice', {
        cell: info => {
          const isSold = allValues[info.row.index].status.value === 'sold';
          return (
            <Controller
              name={`customComparables.${info.row.index}.salePrice`}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <NumberFormat
                    customInput={Input}
                    value={isSold ? field.value : ''}
                    onValueChange={({ floatValue }) => {
                      field.onChange(floatValue || '');
                    }}
                    thousandSeparator={true}
                    onBlur={field.onBlur}
                    prefix={'$'}
                    hideErrorMessage
                    disabled={!isSold}
                    placeholder={isSold ? '' : 'n/a'}
                    error={fieldState.error ? (fieldState.error.message as string) : undefined}
                  />
                );
              }}
            />
          );
        },
        header: 'SOLD PRICE',
        enableSorting: false,
        meta: {
          tdStyles: {
            width: '23.5%',
            padding: '12px 0 12px 10px',
          },
          thStyles: {
            padding: '17px 10px',
          },
        },
      }),
      columnHelper.display({
        id: 'delete',
        cell: info => {
          return (
            <Button
              variant='primary'
              onClick={() => handleDelete(info.row.original.sourceId)}
              isLoading={loading === info.row.original.sourceId}
            >
              <FontAwesomeIcon icon={['fas', 'trash']} />
            </Button>
          );
        },
        meta: {
          tdStyles: {
            width: '6%',
            padding: '12px 10px 12px 10px',
          },
          thStyles: {
            padding: '17px 10px',
          },
        },
      }),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [control, handleDelete, loading, watch, comparables]);

  const table = useReactTable({
    data: comparables,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (!comparables.length) {
    return (
      <Wrapper>
        <NoComparablesText>There are no OFF-MLS Comparables</NoComparablesText>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Table table={table} isScrollableVertically tableHeight='300px' />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 20px 0;
`;

const Address = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;

const NoComparablesText = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;
