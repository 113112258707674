import { cloneDeep } from 'lodash';

import {
  TSlide,
  TSlideId,
  TCategory,
  TFullOrder,
  TSlideTheme,
  TDynamicOrder,
  InheritanceLevel,
  TPresentationMode,
  TPresentationUiConfig,
  TPresentationModeConfig,
  TBuyerDemandInterestData,
} from '../../../types';
import {
  useGetExclusions,
  useAllSearchCriteriaGetter,
} from '../dash/ReportFacade/data/useSearchCriterias';
import { useCreatePresentation } from './sharedHooks';
import { usePredefinedProperties } from './usePredefinedProperties';
import { useSubjectInfoGetter } from '../dash/ReportFacade/data/useSubjectInfo';
import { useUser } from '../../../components/Slide/providers/UserProvider';
import { useLevelDynamicSlides } from '../../../hooks/useLevelDynamicSlides';
import { DefaultConfigProvider } from '../../../services/DefaultConfigProvider';
import { PersonalizedSlidesInjector } from '../services/PersonalizedSlidesInjector';
import { useUserCustomizations } from '../../../hooks/useUserCategoryCustomizations';
import { usePresentationCreationQuery } from '../../../hooks/usePresentationCreationQuery';
import { OrderValidator } from '../../../services/validators/OrderValidator';
import { criteriaToApiMapper } from '../dash/view/pages/Authenticated/PropertySearch/SearchApiMapper';
import { AdaptSlidesWithBuyerInterestData } from '../../../services/AdaptSlidesWithBuyerInterestData';
import { config as oneSheeterConfig } from '../../../components/Slide/slides/whyIAmTheRightFitSection/oneSheeter';
import { config as buyerDemandConfig } from '../../../components/Slide/slides/marketingAndBuyerActivitySection/buyerDemand';
import { config as buyerInterestConfig } from '../../../components/Slide/slides/marketingAndBuyerActivitySection/BuyerInterest';
import { PresentationConfigsFactory } from '../../PresentationEdit/PresentationCreationContent/PresentationCreationBody/AddSlides/services/PresentationSlidesFactory';
import { useReportData } from '../useReportData';
import { extractReportProperties } from '../../../features/report/services/extractReportProperties';

type TModeConfigs = {
  regular: TPresentationModeConfig;
  cma?: TPresentationModeConfig;
  oneSheeter?: TPresentationModeConfig;
};

export function usePresentationCreator() {
  const { hash, isCanadaUser, useSpecialBranding } = useUser()!;

  const getSubject = useSubjectInfoGetter();
  const exclusions = useGetExclusions();
  const getSearchCriteria = useAllSearchCriteriaGetter();

  const getReportData = useReportData();

  const createPresentation = useCreatePresentation();

  const {
    data: configs,
    level: inheritanceLevel,
    isFetching: isFetchingConfigs,
    isLoading: isLoadingConfigs,
  } = useUserCustomizations(hash, null, useSpecialBranding);

  const dynamicSlides = useLevelDynamicSlides(hash, useSpecialBranding);

  const {
    isRebuild,
    presentation: rebuildPresentationHash,
    type: presentationType,
  } = usePresentationCreationQuery();

  const { getPredefinedProperties, buildPredefinedProperties, getCategory } =
    usePredefinedProperties(presentationType);

  const hasMissingTypeOrLoading = () => {
    return !presentationType || isFetchingConfigs || isLoadingConfigs;
  };

  const getUIConfigs = (theme?: TSlideTheme): TPresentationUiConfig => {
    const category = getCategory();

    if (!configs?.[category]) {
      return DefaultConfigProvider.getConfigs()[category as TCategory].ui as TPresentationUiConfig;
    }

    const ui = configs?.[category].ui as TPresentationUiConfig;

    if (theme) {
      ui.theme = theme;
    }

    return ui;
  };

  const addBuyerDataToSlides = (
    buyerData: TBuyerDemandInterestData,
    slides: Record<TSlideId | string, TSlide>,
  ) => {
    const slidesMap = cloneDeep(slides);

    if (!slidesMap['buyerInterest']) {
      slidesMap['buyerInterest'] = { ...buyerInterestConfig, includeSlide: false };
    }

    slidesMap['buyerInterest'] = {
      ...slidesMap['buyerInterest'],
      buyerInterestData: buyerData.interest?.data,
    };

    if (!slidesMap['buyerDemand']) {
      slidesMap['buyerDemand'] = { ...buyerDemandConfig, includeSlide: false };
    }

    slidesMap['buyerDemand'] = {
      ...slidesMap['buyerDemand'],
      buyerDemandData: {
        zipData: buyerData.interest?.zipData,
        properties: buyerData.demand,
      },
    };

    if (!slidesMap['oneSheeter']) {
      slidesMap['oneSheeter'] = { ...oneSheeterConfig, includeSlide: false };
    }

    slidesMap['oneSheeter'] = {
      ...slidesMap['oneSheeter'],
      buyerInterestData: buyerData.interest?.data,
    };

    return slidesMap;
  };

  const getSlides = async (
    mode: TPresentationMode,
    modeConfigs: TModeConfigs,
    id?: number,
  ): Promise<Record<TSlideId | string, TSlide>> => {
    if (!presentationType) {
      throw new Error('Presentation type missing!');
    }

    if (dynamicSlides.isLoading) {
      throw new Error('Dynamic slides loading in progress!');
    }

    const predefinedSlides = getPredefinedProperties().slides ?? {};

    const defaultSlides = PresentationConfigsFactory.create(presentationType, mode).getSlides();
    const allUserSlides = { ...defaultSlides, ...predefinedSlides, ...dynamicSlides.data };

    const sectionSlides = (modeConfigs[mode] as TPresentationModeConfig).order.sectionsSlide;
    const slidesToInclude = Object.values(sectionSlides).flatMap(slides => slides);

    const slidesMap = slidesToInclude.reduce(
      (map, slide) => {
        if (!allUserSlides[slide]) return map;

        return {
          ...map,
          [slide]: allUserSlides[slide],
        };
      },
      {} as Record<TSlideId | string, TSlide>,
    );

    if (isCanadaUser) return slidesMap;

    const buyerData = await fetchBuyerDemand(id);

    return addBuyerDataToSlides(buyerData, slidesMap);
  };

  const getSections = (mode: TPresentationMode) => {
    if (!presentationType) {
      throw new Error('Presentation type missing!');
    }

    return PresentationConfigsFactory.create(presentationType, mode).getSections();
  };

  const getApiOrderByMode = (category: TCategory, mode: TPresentationMode) => {
    function customizationToPresentationOrder(order?: TFullOrder): TDynamicOrder | null {
      if (!order) return null;

      const sectionsSlide = Object.keys(order.sectionsSlide).reduce(
        (map, section) => ({
          ...map,
          [section]: order.sectionsSlide[section].filter(s => s.includeSlide).map(s => s.id),
        }),
        {},
      );

      return {
        sections: order.sections,
        sectionsSlide,
      };
    }

    const predefinedProperties = getPredefinedProperties();

    if (mode === 'regular') {
      return predefinedProperties[mode]?.order?.sections?.length
        ? predefinedProperties[mode]?.order
        : customizationToPresentationOrder(
            configs?.[category]?.orderByType?.[predefinedProperties?.type],
          );
    }

    return predefinedProperties[mode]?.order;
  };

  const getOrder = (report: any, mode: TPresentationMode): TDynamicOrder => {
    if (!presentationType) {
      throw new Error('Presentation type missing!');
    }

    const category = getCategory();
    const predefinedProperties = getPredefinedProperties();

    const defaultOrder = PresentationConfigsFactory.create(
      presentationType,
      mode,
    ).getDefaultOrder();

    const rawApiOrder = getApiOrderByMode(category, mode);

    const validator = new OrderValidator(predefinedProperties.type, mode);

    const sections = rawApiOrder
      ? validator.getPartialSectionsOrder(rawApiOrder?.sections)
      : [...defaultOrder.sections];

    let sectionsSlide = { ...defaultOrder.sectionsSlide };
    if (rawApiOrder) {
      sectionsSlide = {
        ...sectionsSlide,
        ...validator.getPartialSectionsSlidesOrder(rawApiOrder?.sectionsSlide, dynamicSlides.data),
      };
    }

    if (!rawApiOrder) {
      sectionsSlide = PersonalizedSlidesInjector.inject(
        predefinedProperties.type,
        predefinedProperties.mode,
        sectionsSlide,
        dynamicSlides.data,
      );
    }

    const order = {
      sections,
      sectionsSlide,
    };

    return order;
  };

  const getReportCriteriasAndExclusions = () => {
    const searchCriteria = getSearchCriteria();
    const api: any = criteriaToApiMapper(searchCriteria);
    const exclusionsFromMap = searchCriteria.exclusions || [];

    const allExclusions = [...exclusions.excluded_ids, ...exclusionsFromMap];

    return {
      api,
      allExclusions,
    };
  };

  const fetchBuyerDemand = async (id?: number) => {
    const report = getReportCriteriasAndExclusions();
    const reportData = getReportData();

    const properties = reportData?.report?.reportDetailed?.properties || [];
    const propertiesList = extractReportProperties(properties);

    const allPropertiesPrices = propertiesList.map(prop => prop.property.price);
    const customMarkers = reportData?.result?.customMarkers || [];

    const averageFunc = (arr: number[]) => arr.reduce((p, c) => p + c, 0) / arr.length;
    const averagePrice =
      allPropertiesPrices.length > 0 ? Math.round(averageFunc(allPropertiesPrices)) : 0;
    const agentEstimate = customMarkers.length > 0 ? customMarkers[0]?.price : 0;
    const price = agentEstimate || averagePrice;

    return AdaptSlidesWithBuyerInterestData.loadBuyerDemandAndInterestData(
      getSubject(),
      price,
      hash,
      report,
      id,
    );
  };

  function getModesConfig(mode: TPresentationMode): TModeConfigs {
    const predefinedProperties = getPredefinedProperties();

    function getOrderAndConfig(mode: TPresentationMode) {
      if (
        predefinedProperties[mode]?.sections?.length &&
        predefinedProperties[mode]?.order?.sections?.length &&
        Object.keys(predefinedProperties[mode]?.order?.sectionsSlide ?? {})?.length
      ) {
        return predefinedProperties[mode];
      }

      const reportData = getReportData();

      return {
        order: getOrder(reportData, mode),
        sections: getSections(mode),
      };
    }

    const regular = getOrderAndConfig('regular');

    if (mode === 'oneSheeter') {
      const oneSheeter = getOrderAndConfig('oneSheeter');
      return { regular, oneSheeter };
    }

    if (mode === 'cma') {
      const cma = getOrderAndConfig('cma');
      return { regular, cma };
    }

    return { regular };
  }

  const adaptModeConfigsAndSlidesForCanadaUsers = (
    slidesMap: Record<TSlideId | string, TSlide>,
    modeConfigs: TModeConfigs,
  ) => {
    if (!isCanadaUser) return { slidesMap, modeConfigs };

    const excludedSlides = ['buyerInterest', 'buyerDemand'];
    delete slidesMap.buyerInterest;
    const availableModes = Object.keys(modeConfigs) as TPresentationMode[];
    availableModes.forEach(mode => {
      if (modeConfigs[mode] && mode !== 'cma') {
        const mbaSection = modeConfigs[mode]!.order.sectionsSlide.marketingAndBuyerActivity;
        if (mbaSection) {
          modeConfigs[mode]!.order.sectionsSlide.marketingAndBuyerActivity = mbaSection.filter(
            slideId => !excludedSlides.includes(slideId),
          );
        }
      }
    });
  };

  const getCreatePresentationPayload = async (primaryMode?: TPresentationMode, id?: number) => {
    if (hasMissingTypeOrLoading()) {
      throw new Error('No presentation type');
    }

    const {
      type,
      mode,
      category,
      theme,
      videoLink,
      agreementLink,
      meta: metaPredefined,
    } = await buildPredefinedProperties(isRebuild, rebuildPresentationHash);

    const modeConfigs = getModesConfig(primaryMode ?? mode);

    const ui = getUIConfigs(theme);
    const slidesMap = await getSlides(primaryMode ?? mode, modeConfigs, id);

    adaptModeConfigsAndSlidesForCanadaUsers(slidesMap, modeConfigs);

    const meta = {
      ...metaPredefined,
      inheritanceLevel:
        metaPredefined?.inheritanceLevel || inheritanceLevel || InheritanceLevel.AGENT,
    };

    const reportData = getReportData();

    return {
      ...modeConfigs,
      type,
      mode: primaryMode ?? mode,
      category,
      slidesMap,
      ui,
      reportData,
      videoLink,
      agreementLink,
      meta,
    };
  };

  const onCreate = async (client: any, primaryMode?: TPresentationMode) => {
    const presentation = await getCreatePresentationPayload(primaryMode);

    return createPresentation(presentation, client);
  };

  return {
    onCreate,
    getCreatePresentationPayload,
  };
}
