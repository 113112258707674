import { useState, useEffect, PropsWithChildren } from 'react';

import { toast } from 'react-toastify';
import { CSSObject, useTheme } from 'styled-components';

import {
  AutosuggestLabel,
  AutoSuggestWrapper,
  Container,
  Spacer,
  Wrapper,
} from './SendEmailPopup.styles';
import { TClient, TPresentationType } from '../../../types';
import Loader from '../../Loader/Loader';
import { URLS } from '../../../constants/urls';
import { corePresentApi } from '../../../api/CorePresentApi';
import { useUser } from '../../Slide/providers/UserProvider';
import { FitContentModal } from '../FitContentModal/FitContentModal';
import { DashboardInput } from '../DashboardInput/DashboardInput.component';
import { DashboardTextarea } from '../DashboardTextarea/DashboardTextarea.component';
import { AutosuggestClients } from '../AutosuggestClients/AutosuggestClients.component';
import { Button } from '../V2/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FEATURES, useHasFeatureFlag } from '../../../hooks/useFeatureFlags';
import { EmailPopupOld } from './EmailPopupOld';
import { EmailPopup } from './EmailPopup/EmailPopup';

type EmailPopupProps = {
  isOpen: boolean;
  client: TClient;
  onClose: () => void;
  presentationHash: string;
  presentationType?: TPresentationType;
  headerTitle?: string;
  initialMessage?: string;
  initialSubject?: string;
  modalContainerStyles?: CSSObject;
  address?: string;
  isPdfGenerating?: boolean;
  pdfUrl?: string;
};

export function SendEmailPopup({
  isOpen,
  onClose,
  modalContainerStyles,
  initialSubject = '',
  initialMessage = '',
  headerTitle = 'Share Presentation via Email',
  presentationHash,
  presentationType,
  client,
  address,
  isPdfGenerating,
  pdfUrl,
}: PropsWithChildren<EmailPopupProps>) {
  const isIncludePdfInEmail = useHasFeatureFlag(FEATURES.CpEmailStylingIncludePdf, true);

  if (!isIncludePdfInEmail) {
    return (
      <EmailPopupOld
        isOpen={isOpen}
        client={client}
        onClose={onClose}
        presentationHash={presentationHash}
        presentationType={presentationType}
        headerTitle={headerTitle}
        initialMessage={initialMessage}
        initialSubject={initialSubject}
        modalContainerStyles={modalContainerStyles}
        address={address}
      />
    );
  }

  return (
    <EmailPopup
      isOpen={isOpen}
      client={client}
      onClose={onClose}
      presentationHash={presentationHash}
      headerTitle={headerTitle}
      initialMessage={initialMessage}
      initialSubject={initialSubject}
      modalContainerStyles={modalContainerStyles}
      address={address}
      isPdfGenerating={isPdfGenerating}
      pdfUrl={pdfUrl}
    />
  );
}
