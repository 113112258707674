import { mapColorFromStatus } from '../../../../pages/PresentationCreate/dash/config/constants';
import { MoneyFormatter } from '../../../../pages/PresentationCreate/dash/services/moneyFormatter';
import { NumberFormatter } from '../../../../pages/PresentationCreate/dash/services/numberFormatter';
import { getPropertyStatus } from '../../../../pages/PresentationCreate/dash/view/services/getPropertyStatus';
import { CurrentUserService } from '../../../../services/CurrentUserService';
import { getCompositeKey } from '../../../../services/getCompositeKey';
import { TProperty } from '../../../../types';
import { getPropertyId } from '../../services/getPropertyId';

interface Args {
  property: TProperty;
  subjectSize: number;
  hasBathCountFF?: boolean;
}

export const getInfoWindowContent = ({ property, subjectSize, hasBathCountFF }: Args) => {
  const status = property.status;
  const s = getPropertyStatus(status);
  const adjustedPrice = property?.adjustedPrice
    ? MoneyFormatter.format(property.adjustedPrice)
    : null;

  const color = mapColorFromStatus(status);

  const propertyId = property?.isCustom ? property.sourceId : getPropertyId(property);
  const propertyAddId = property?.isCustom ? property.sourceId : getCompositeKey(property);
  const clickEvent =
    property.partialMatch || property.excluded
      ? `document.dispatchEvent(new CustomEvent('add-comp', {detail: '${propertyAddId}'}))`
      : `document.dispatchEvent(new CustomEvent('remove-comp', {detail: '${propertyId}'}))`;
  let percentDiff: string | number = (property.size / subjectSize) * 100;
  if (percentDiff > 100) {
    percentDiff = Math.round(percentDiff - 100) + '% larger';
  } else {
    percentDiff = Math.round(100 - percentDiff) + '% smaller';
  }

  const infoWindowButtonStyles = `
  "text-decoration: none;
  text-align: center;
  display: inline-block;
  background: var(--color-primary);
  border: none;
  border-radius: 5px;
  width: 100%;
  padding: 5px;
  color: white;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 10px;
  text-transform: uppercase;"`;

  return `
    <div>
      <div style="color: ${color};font-weight: bold">
            <img style="max-height: 15px" src="../../../../assets/images/map-pointer-${s}.png" alt="" />
            ${property.address.deliveryLine}
       </div>
      <hr>
      <div style= "margin-top: 5px;margin-bottom: 5px;font-weight: bold;">${
        status === 'sold'
          ? 'Sale price: ' + MoneyFormatter.format(property.salePrice)
          : 'List price: ' + MoneyFormatter.format(property.price)
      } <br>
      <div style="${adjustedPrice ? 'display: block;' : 'display: none;'}">
      ${status === 'sold' ? 'ADJ Sale Price: ' + adjustedPrice : 'ADJ List Price: ' + adjustedPrice}
      </div>
${
  status === 'sold'
    ? 'Sale date: ' + `${property.saleDateHuman ? property.saleDateHuman : 'Unavailable'}`
    : 'List date: ' + `${property.listDateHuman ? property.listDateHuman : 'Unavailable'}`
} <br>
    ${property.beds} Beds ${
      hasBathCountFF ? `| ${property.totalBaths || '-'} Total Baths <br />` : '<br />'
    }
    ${
      !CurrentUserService.isCanadaUser()
        ? NumberFormatter.format(property.size) + ' sqft (' + percentDiff + ')'
        : ''
    }
    </div>
     <button
        id="infoWindow${propertyId}"
        style=${infoWindowButtonStyles}
        onclick="${clickEvent}"
      >${property.partialMatch || property.excluded ? 'Add to comps' : 'Remove from comps'}</button>
      <button
        id="infoWindow${propertyId}"
        style=${infoWindowButtonStyles}
        onclick="document.dispatchEvent(new CustomEvent('more-info', {detail: {id: '${propertyId}', status: '${
          property.status
        }'}}))"
      >More Info</button>
  `;
};
