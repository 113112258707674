import styled from 'styled-components';
import { Button } from '../../../components/Common/V2/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Draggable } from 'react-beautiful-dnd';
import React, { CSSProperties } from 'react';
import { createSvgWithText } from '../../BuyerTour/components/Map/Markers/Markers.component';
import { Icon } from '../../../components/Common';

interface Props {
  comparableTitle: string;
  onEdit?: () => void;
  onDelete: () => void;
  isDisabled?: boolean;
  draggableId?: string;
  index?: number;
  notActive?: boolean;
  withDnD?: boolean;
  errorMessage?: string;
}

export const ManualComparable = ({
  comparableTitle,
  onDelete,
  onEdit,
  isDisabled,
  draggableId,
  index,
  notActive,
  withDnD,
  errorMessage,
}: Props) => {
  const DraggableWrapper = ({ children }: { children: React.ReactNode }) => {
    if (!draggableId) return null;

    return (
      <Draggable draggableId={draggableId} index={index || 0} isDragDisabled={notActive}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            {children}
          </div>
        )}
      </Draggable>
    );
  };

  const Container = withDnD ? DraggableWrapper : React.Fragment;
  const icon = withDnD && !notActive ? createSvgWithText((index || 0) + 1) : null;
  const commonStyles = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  } as CSSProperties;

  return (
    <Container>
      <OuterWrapper>
        {withDnD && !notActive && <Icon iconName='drag' />}
        {/* {icon && <img src={icon} />} */}
        <Wrapper>
          <Title>{comparableTitle}</Title>
          {onEdit && (
            <Button
              variant='primary'
              style={{ width: 36, height: 36, maxHeight: 36 }}
              onClick={onEdit}
              disabled={isDisabled}
            >
              <FontAwesomeIcon icon={faPen} />
            </Button>
          )}
          <Button
            variant='primary'
            style={{ width: 36, height: 36, maxHeight: 36 }}
            onClick={onDelete}
            disabled={isDisabled}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </Wrapper>
      </OuterWrapper>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Container>
  );
};

const OuterWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 5px;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 5px;
  height: 36px;
  width: 100%;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.v2.gray[400]};
  padding: 7px 8px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.v2.gray[200]};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  flex: 1;
`;

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.v2.status.error};
  font-size: 14px;
  font-weight: 500;
`;
