import styled, { useTheme } from 'styled-components';
import { ManualComparablesContent } from '../../../../../manual-comparables/components/ManualComparablesContent';
import { Button } from '../../../../../../components/Common/V2/Button/Button';
import { OnMLSInput } from '../../../../../manual-comparables/OnMLSComparables/OnMLSInput';
import { ComparableCard } from '../../../../../manual-comparables/components/ComparableCard';
import { useProperties } from '../../../../../../providers/providers/PropertiesProvider';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { ManualMLSErrors, TProperty } from '../../../../../../types';

interface Props {
  onOpenModal: () => void;
  setOnMlsErrors: Dispatch<SetStateAction<ManualMLSErrors>>;
  onAddOnMls: (ids: string[], properties: TProperty[]) => void;
  handleEditOpenOnError: (id?: string) => void;
  setIsSingleMlsInputError?: Dispatch<SetStateAction<boolean>>;
  isSingleMlsInputError?: boolean;
  setSingleMlsInputErrorProperty?: Dispatch<SetStateAction<TProperty | null>>;
}

export const AddProperties = ({
  onOpenModal,
  setOnMlsErrors,
  onAddOnMls,
  handleEditOpenOnError,
  setIsSingleMlsInputError,
  isSingleMlsInputError,
  setSingleMlsInputErrorProperty,
}: Props) => {
  const { colors } = useTheme();

  const { properties } = useProperties();

  const mlsIds = useMemo(() => properties.map(p => p.id), [properties]);

  const cardStyles = {
    border: 'none',
    borderRadius: 0,
  };

  return (
    <Container>
      <ComparableCard
        title='Add On-MLS Comparable'
        info='Input the MLS Number to automatically pull property details from the MLS database.'
        cardStyles={{ ...cardStyles, borderBottom: `1px solid ${colors.v2.gray[200]}` }}
      >
        <ManualComparablesContent description='Property is On-MLS: Manually enter MLS IDs to add comparables. Separate multiple IDs with commas.'>
          <OnMLSInput
            onAdd={onAddOnMls}
            mlsIds={mlsIds}
            setErrors={setOnMlsErrors}
            onModalOpen={handleEditOpenOnError}
            setIsSingleMlsInputError={setIsSingleMlsInputError}
            isSingleMlsInputError={isSingleMlsInputError}
            setSingleMlsInputErrorProperty={setSingleMlsInputErrorProperty}
          />
        </ManualComparablesContent>
      </ComparableCard>
      <ComparableCard
        title='Add Off-MLS Comparable'
        info='Add a property not listed on the MLS by entering its details manually. Use this option to include non-MLS properties in your comparisons.'
        cardStyles={cardStyles}
      >
        <ManualComparablesContent description='Property is Off-MLS: Manually add address and details.'>
          <Button variant='primary' style={{ width: '100%' }} onClick={onOpenModal}>
            Add Off-MLS Comparable
          </Button>
        </ManualComparablesContent>
      </ComparableCard>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: ${({ theme }) => `1px solid ${theme.colors.v2.gray[200]}`};
  border-radius: 5px;
`;
