import { useMutation } from '@tanstack/react-query';
import { corePresentApi } from '../../../api/CorePresentApi';

type BulkPayload = {
  comparable_property_id: string;
  status: string;
  price: number;
  sale_price?: number;
};

async function updateBulkComparables({
  presentationId,
  payload,
}: {
  presentationId: number;
  payload: BulkPayload[];
}) {
  const res = corePresentApi.put(`/comparable-property/bulk-update`, {
    presentation_id: presentationId,
    comparable_properties: payload,
  });

  return res;
}

export const useUpdateBulkComparables = () => {
  return useMutation(updateBulkComparables, {
    onError: err => {
      console.error('Error while updating bulk comparables', err);
    },
  });
};
