import styled from 'styled-components';
import React, { useEffect, useState } from 'react';

import {
  ModalActiveSectionProvider,
  useModalActiveSection,
} from '../providers/ModalActiveSectionProvider';
import { useSelectableSectionSlides } from './useSelectableSectionSlides';
import { useTagsInSection } from '../../../../../../hooks/dynamicSlides/useTags';
import { SectionsNavigation } from '../AddSlideRegularMode/SectionsNavigation';
import {
  TTag,
  TSlide,
  TDynamicSectionId,
  TSlideId,
  TAddSlideBtnStyle,
} from '../../../../../../types';
import { withProviders } from '../../../../../../hoc/withProviders';
import { useOrder } from '../../../../../../providers/providers/OrderProvider';
import { AddButtonWithModal } from '../components/AddButtonWithModal/AddButtonWithModal';
import { useSections } from '../../../../../../providers/providers/SectionsProvider';
import { SelectableSlidesGrid } from '../components/SelectableSlidesGrid/SelectableSlidesGrid';
import { SlidesTags } from '../../../../../../components/Common/SlidesTags/SlidesTags.component';
import { ModalVisibilityProvider, useModalVisibility } from '../providers/ModalVisibilityProvider';
import { Button } from '../../../../../../components/Common/V2/Button/Button';
import { cloneDeep } from 'lodash';

const Container = styled('div')(({ theme }) => ({
  padding: '26px 28px 24px 30px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.colors.gray[100],
  flexShrink: 0,
}));

interface Props {
  section: TDynamicSectionId;
  withSectionsNavigation?: boolean;
  btnStyle?: TAddSlideBtnStyle;
}

export const AddSlideToOrderConfig: React.FC<Props> = withProviders<Props>(
  ModalActiveSectionProvider,
  ModalVisibilityProvider,
)(({ section, withSectionsNavigation = false, btnStyle = 'add' }) => {
  const { sections: sectionsOrder, sectionsSlide, setSectionSlidesOrder } = useOrder();

  const { sections } = useSections();
  const { closeModal } = useModalVisibility();

  const { modalActiveSection, setModalActiveSection, resetModalActiveSection } =
    useModalActiveSection();

  const [selectedTags, setSelectedTags] = useState<TTag[]>([]);
  const tags = useTagsInSection('allSlides');
  const tagsList = React.useMemo(() => Object.values(tags), [tags]);

  const [localSectionsSlide, setLocalSectionsSlide] = useState(cloneDeep(sectionsSlide));

  const slides = useSelectableSectionSlides(selectedTags, modalActiveSection);

  useEffect(() => {
    setSelectedTags([]);
  }, [modalActiveSection]);

  useEffect(() => {
    if (JSON.stringify(sectionsSlide) === JSON.stringify(localSectionsSlide)) return;

    setLocalSectionsSlide(sectionsSlide);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionsSlide]);

  const onAddSelectedSlide = () => {
    setSectionSlidesOrder(
      modalActiveSection as TDynamicSectionId,
      localSectionsSlide[modalActiveSection as TDynamicSectionId],
    );
    closeModal();
  };

  const onSlideClick = React.useCallback(
    function onSlideClick(slide: TSlide) {
      setLocalSectionsSlide(prev => {
        let newActiveSectionOrder = prev[modalActiveSection as TDynamicSectionId];

        const orderWithoutClicked = prev[modalActiveSection as TDynamicSectionId].filter(
          sId => sId !== slide.id,
        );

        if (orderWithoutClicked.length < prev[modalActiveSection as TDynamicSectionId].length) {
          newActiveSectionOrder = orderWithoutClicked;
        } else {
          newActiveSectionOrder = [...orderWithoutClicked, slide.id] as TSlideId[];
        }

        return {
          ...prev,
          [modalActiveSection as TDynamicSectionId]: newActiveSectionOrder,
        };
      });
    },
    [modalActiveSection],
  );

  const selected = React.useMemo(
    () =>
      Object.values(localSectionsSlide)
        .flatMap(slide => slide)
        .reduce((map, sId) => ({ ...map, [sId]: true }), {}),
    [localSectionsSlide],
  );

  return (
    <AddButtonWithModal
      onClose={resetModalActiveSection}
      onAddClick={onAddSelectedSlide}
      onOpen={() => setModalActiveSection(section)}
    >
      {withSectionsNavigation && <SectionsNavigation order={sectionsOrder} sections={sections} />}

      <SlidesTags
        tagsList={tagsList}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
      />

      <Container>
        <SelectableSlidesGrid
          selected={selected}
          slides={slides}
          hasTags={!!tagsList.length}
          onClick={onSlideClick}
        />
      </Container>
    </AddButtonWithModal>
  );
});
