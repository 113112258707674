import { useEffect } from 'react';

import { TProperty, TSection } from '../../../types';
import { TBuyerTourPresentation } from '../../../types';
import { useOrder } from '../../../providers/providers/OrderProvider';
import { useClient } from '../../../providers/providers/ClientProvider';
import { OrderValidator } from '../../../services/validators/OrderValidator';
import { useSlides } from '../../../providers/providers/SlidesProvider';
import { useSections } from '../../../providers/providers/SectionsProvider';
import { useCategory } from '../../../providers/providers/CategoryProvider';
import { useCreatedAt } from '../../../providers/providers/CreatedAtProvider';
import { useProperties } from '../../../providers/providers/PropertiesProvider';
import { useSetUiConfigs } from '../../../providers/providers/UiConfigProvider';
import { SlidesValidator } from '../../../services/validators/SlidesValidator';
import { usePresentationType } from '../../../providers/providers/PresentationTypeProvider';
import { useActiveSection } from '../../../components/Slide/providers/ActiveSectionProvider';
import { SectionsValidator } from '../../../services/validators/SectionsValidator';

export function useSetBuyerTourPresentationToProviders(presentation: TBuyerTourPresentation) {
  const { setClient } = useClient();
  const { setSlides } = useSlides();
  const setUiConfigs = useSetUiConfigs();
  const { setSections } = useSections();
  const { setCategory } = useCategory();
  const { setProperties } = useProperties();
  const { setActiveSection } = useActiveSection();
  const { setPresentationType } = usePresentationType();
  const { setSectionsOrder, setSectionsSlideOrder } = useOrder();
  const { setCreatedAt } = useCreatedAt();

  useEffect(
    function setBuyerTourDefaultPresentationConfig() {
      const BTProperties =
        presentation?.version === 1
          ? presentation?.properties
          : presentation?.comparableProperties?.active?.sort(
              (a: TProperty, b: TProperty) => (a.order || 0) - (b.order || 0),
            );

      setUiConfigs(presentation.ui);
      setProperties(BTProperties || []);
      setPresentationType(presentation.type);
      setCategory(presentation.category);
      setCreatedAt(presentation.createdAt);

      const slideValidator = new SlidesValidator(presentation.type);
      setSlides(slideValidator.getPartialConfig(presentation.slides));

      const sectionsValidator = new SectionsValidator(presentation.type);
      setSections(sectionsValidator.getPartialConfig(presentation.sections));

      const validator = new OrderValidator(presentation.type);
      const sectionOrder = validator.getPartialSectionsOrder(presentation.order.sections);
      const sectionsSlideOrder = validator.getPartialSectionsSlidesOrder(
        presentation.order.sectionsSlide,
        presentation.slides,
      );

      setSectionsOrder(sectionOrder);
      setSectionsSlideOrder(sectionsSlideOrder);

      setClient({
        id: presentation.clientId,
        name: presentation.clientName,
        email: presentation.clientEmail,
      });

      setActiveSection(sectionOrder[0] as TSection);
    },
    [
      presentation,
      setActiveSection,
      setCategory,
      setClient,
      setCreatedAt,
      setPresentationType,
      setProperties,
      setSections,
      setSectionsOrder,
      setSectionsSlideOrder,
      setSlides,
      setUiConfigs,
    ],
  );
}
