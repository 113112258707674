import { useEffect, useState } from 'react';

import { useOrder } from '../../../../providers/providers/OrderProvider';
import { useClient } from '../../../../providers/providers/ClientProvider';
import { TBuyerTourPresentation, TProperty } from '../../../../types';
import { useSlides } from '../../../../providers/providers/SlidesProvider';
import { TSection } from '../../../../types';
import { useSections } from '../../../../providers/providers/SectionsProvider';
import { useProperties } from '../../../../providers/providers/PropertiesProvider';
import { useCategory } from '../../../../providers/providers/CategoryProvider';
import { useSetUiConfigs } from '../../../../providers/providers/UiConfigProvider';
import { SlidesValidator } from '../../../../services/validators/SlidesValidator';
import { usePresentationType } from '../../../../providers/providers/PresentationTypeProvider';
import { useSetDynamicSlidesToSlidesProvider } from '../../hooks/useSetDynamicSlidesToSlidesProvider';
import { useActiveSection } from '../../../../components/Slide/providers/ActiveSectionProvider';
import { SectionsValidator } from '../../../../services/validators/SectionsValidator';
import { OrderValidator } from '../../../../services/validators/OrderValidator';
import { PRESENTATION_TYPES } from '../../../../constants/PresentationTypes';
import { useCreatedAt } from '../../../../providers/providers/CreatedAtProvider';

export function useSetBuyerTourPresentationFullConfigToProviders(
  presentation: TBuyerTourPresentation,
) {
  const { setClient } = useClient();
  const { setSlides } = useSlides();
  const setUiConfigs = useSetUiConfigs();
  const { setSections } = useSections();
  const { setCategory } = useCategory();
  const { setProperties } = useProperties();
  const { setActiveSection } = useActiveSection();
  const { setPresentationType } = usePresentationType();
  const { setSectionsOrder, setSectionsSlideOrder } = useOrder();
  const { setCreatedAt } = useCreatedAt();
  const [isPresentationSet, setIsPresentationSet] = useState(false);

  const { data: dynamicSlides, isFetching, isLoading } = useSetDynamicSlidesToSlidesProvider();

  useEffect(
    function setBuyerTourDefaultPresentationConfig() {
      if (isFetching || isLoading || isPresentationSet) return;

      const BTProperties =
        presentation?.version === 1
          ? presentation?.properties
          : presentation?.comparableProperties?.active?.sort(
              (a: TProperty, b: TProperty) => (a.order || 0) - (b.order || 0),
            );

      setUiConfigs(presentation.ui);
      setProperties(BTProperties || []);
      setPresentationType(presentation.type);
      setCategory(presentation.category);
      setCreatedAt(presentation.createdAt);

      const slides = { ...presentation.slides, ...dynamicSlides };
      const slidesValidator = new SlidesValidator(presentation.type);
      setSlides(slidesValidator.getFullConfig(slides));

      const sectionsValidator = new SectionsValidator(presentation.type);
      setSections(sectionsValidator.getFullConfig(presentation.sections));

      const validator = new OrderValidator(PRESENTATION_TYPES.BUYER_TOUR);
      const sectionOrder = validator.getFullSectionsOrder(presentation.order.sections);
      const sectionsSlideOrder = validator.getPartialSectionsSlidesOrder(
        presentation.order.sectionsSlide,
        dynamicSlides,
      );

      setSectionsOrder(sectionOrder);
      setSectionsSlideOrder(sectionsSlideOrder);

      setClient({
        id: presentation.clientId,
        name: presentation.clientName,
        email: presentation.clientEmail,
      });

      setActiveSection(sectionOrder[0] as TSection);
      setIsPresentationSet(true);
    },
    [
      presentation,
      dynamicSlides,
      isFetching,
      isLoading,
      setUiConfigs,
      setProperties,
      setPresentationType,
      setCategory,
      setCreatedAt,
      setSlides,
      setSections,
      setSectionsOrder,
      setSectionsSlideOrder,
      setClient,
      setActiveSection,
      isPresentationSet,
    ],
  );
}
