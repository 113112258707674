import { ManualComparable } from '../components/ManualComparable';
import { useEffect, useState } from 'react';
import { searchByMlsId } from '../../../pages/PresentationCreate/dash/communicators/Address/AddressCommunicator';
import { transformOnMlsCompositeId } from '../../../services/transformOnMlsCompositeId';
import { useDeleteCustomComparable } from '../hooks/useDeleteCustomComparable';
import { TProperty } from '../../../types';

interface Props {
  mlsIds: string[];
  setMlsIds?: (ids: string[]) => void;
  removeProperty?: (id: string) => void;
  property: TProperty;
  index: number;
  notActive?: boolean;
  withDnD?: boolean;
  propertyMlsId?: string | number;
  onlyActive?: boolean;
  onEdit?: () => void;
  onDeleteSuccess?: (property: TProperty) => void;
}

export const OnMlsComparable = ({
  mlsIds,
  setMlsIds,
  removeProperty,
  property,
  index,
  notActive,
  withDnD,
  propertyMlsId,
  onlyActive,
  onEdit,
  onDeleteSuccess,
}: Props) => {
  const [isInactive, setIsInactive] = useState(false);
  const { mutateAsync: deleteCustomComparable } = useDeleteCustomComparable();

  const onRemove = (property: TProperty) => {
    if (property.isCustom) {
      deleteCustomComparable(property.sourceId || property.id, {
        onSuccess: () => {
          onDeleteSuccess && onDeleteSuccess(property);
        },
      });
      return;
    }

    const ids = mlsIds.filter(mls => transformOnMlsCompositeId(mls).listingId !== property.id);
    setMlsIds?.(ids);
    removeProperty && removeProperty(property.id);
  };

  const mlsComparable = transformOnMlsCompositeId(property.id);
  const { listingId } = mlsComparable;

  useEffect(() => {
    const fetchAndMarkInactive = async () => {
      if (!listingId) return;

      if (property?.isCustom) {
        return setIsInactive(property?.status !== 'active');
      }

      try {
        const listings = await searchByMlsId(property?.mlsListingId || listingId);
        const listing = listings?.find((l: any) => l.mlsId === propertyMlsId) || listings?.[0];

        if (listing?.status === 'active') return;

        setIsInactive(true);
      } catch (e) {
        setIsInactive(property?.status !== 'active');
      }
    };

    if (!onlyActive) return;

    fetchAndMarkInactive();
  }, [
    onlyActive,
    propertyMlsId,
    mlsComparable.listingId,
    property?.mlsListingId,
    listingId,
    property?.status,
    property?.isCustom,
  ]);

  return (
    <ManualComparable
      comparableTitle={property?.mlsListingId || listingId}
      onDelete={() => onRemove(property)}
      draggableId={property.id}
      index={index}
      notActive={notActive}
      withDnD={withDnD}
      errorMessage={isInactive ? 'Property not active' : ''}
      onEdit={property.isCustom ? onEdit : undefined}
    />
  );
};
