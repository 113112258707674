import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  background: ${({ theme }) => theme.colors.v2.gradient.background};
  display: flex;
  flex: 1;
  overflow: hidden;
  gap: 20px;
`;

export const LeftCol = styled('div')({
  width: 278,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: 10,
});

export const RightCol = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  gap: 10,
});

export const Description = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.v2.gray[400]};
`;
