export function getPropertyId({
  mlsListingId,
  id,
  isCustom,
  sourceId,
}: {
  mlsListingId?: string;
  id: string;
  isCustom?: boolean;
  sourceId?: string;
}) {
  if (isCustom) return mlsListingId || id || sourceId || '';

  return mlsListingId || id;
}
