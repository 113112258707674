import styled from 'styled-components';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxHeight: 'calc(100vh - 300px)',
  minHeight: 'calc(100vh - 300px)',
  width: 278,
  flexShrink: 0,
  overflowY: 'auto',
  borderTop: `1px solid ${theme.colors.v2.gray[200]}`,
  '&::-webkit-scrollbar': {
    width: '.3em',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.colors.gray[200],
    outline: 'none',
  },
  paddingBottom: '200px',
  'scroll-behavior': 'auto !important',
  '-webkit-overflow-scrolling': 'touch',
}));
