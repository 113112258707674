import { library } from '@fortawesome/fontawesome-svg-core';

// FREE

import {
  faStar as faStarSolidFree,
  faBold as faBoldSolidFree,
  faItalic as faItalicSolidFree,
  faListUl as faListUlSolidFree,
  faCaretUp as faCaretUpSolidFree,
  faEnvelope as faEnvelopeSolidFree,
  faBullseye as faBullseyeSolidFree,
  faAlignLeft as faAlignLeftSolidFree,
  faAngleLeft as faAngleLeftSolidFree,
  faCaretDown as faCaretDownSolidFree,
  faAlignRight as faAlignRightSolidFree,
  faCirclePlay as faCirclePlaySolidFree,
  faCirclePlus as faCirclePlusSolidFree,
  faPaperPlane as faPaperPlaneSolidFree,
  faChevronLeft as faChevronLeftSolidFree,
  faAlignCenter as faAlignCenterSolidFree,
  faChevronRight as faChevronRightSolidFree,
  faChevronDown as faChevronDownSolidFree,
  faMagnifyingGlass as faMagnifyingGlassSolidFree,
  faFileCirclePlus as faFileCirclePlusSolidFree,
  faSquarePollVertical as faSquarePollVerticalSolidFree,
  faPenRuler as faPenRulerSolidFree,
  faPrint as faPrintSolidFree,
  faQuoteLeft as faQuoteLeftSolidFree,
  faCircleXmark as faCircleXmarkSolidFree,
  faFilePdf as faFilePdfSolidFree,
  faDollarSign as faDollarSignSolidFree,
  faPercent as faPercentSolidFree,
  faRotateRight as faRotateRightSolidFree,
  faCalendar as faCalendarSolidFree,
  faCaretLeft,
  faCaretRight,
  faTrash as faTrashSolidFree,
} from '@fortawesome/free-solid-svg-icons';

// PRO

import {
  faCog as faCogLight,
  faPlus as faPlusLight,
  faSave as faSaveLight,
  faUndo as faUndoLight,
  faLink as faLinkLight,
  faInfo as faInfoLight,
  faStar as faStarLight,
  faCheck as faCheckLight,
  faGlobe as faGlobeLight,
  faRuler as faRulerLight,
  faPollH as faPollHLight,
  faTimes as faTimesLight,
  faPrint as faPrintLight,
  faSearch as faSearchLight,
  faTrashAlt as faTrashAltLight,
  faChartBar as faChartBarLight,
  faEnvelope as faEnvelopeLight,
  faExchange as faExchangeLight,
  faThumbsUp as faThumbsUpLight,
  faHomeLgAlt as faHomeLgAltLight,
  faInfoCircle as faInfoCircleLight,
  faChevronDown as faChevronDownLight,
  faUserHeadset as faUserHeadsetLight,
  faMapMarkerAlt as faMapMarkerAltLight,
  faGlobeAmericas as faGlobeAmericasLight,
  faSlidersVSquare as faSlidersVSquareLight,
  faQuestionCircle as faQuestionCircleLight,
  faBullseyePointer as faBullseyePointerLight,
  faCircleQuestion as faCircleQuestionLight,
} from '@fortawesome/pro-light-svg-icons';

import {
  faEye as faEyeSolid,
  faPen as faPenSolid,
  faUndo as faUndoSolid,
  faUser as faUserSolid,
  faLock as faLockSolid,
  faStar as faStarSolid,
  faFont as faFontSolid,
  faCheck as faCheckSolid,
  faTimes as faTimesSolid,
  faPhone as faPhoneSolid,
  faImage as faImageSolid,
  faSidebar as faSidebarSolid,
  faPalette as faPaletteSolid,
  faTextSize as faTextSizeSolid,
  faThumbsUp as faThumbsUpSolid,
  faPowerOff as faPowerOffSolid,
  faEnvelope as faEnvelopeSolid,
  faStopwatch as faStopwatchSolid,
  faChartLine as faChartLineSolid,
  faCaretDown as faCaretDownSolid,
  faAngleDown as faAngleDownSolid,
  faBorderTop as faBorderTopSolid,
  faVideoPlus as faVideoPlusSolid,
  faFileCheck as faFileCheckSolid,
  faLineHeight as faLineHeightSolid,
  faUserCircle as faUserCircleSolid,
  faInfoCircle as faInfoCircleSolid,
  faSquareUser as faSquareUserSolid,
  faPlusSquare as faPlusSquareSolid,
  faMinusSquare as faMinusSquareSolid,
  faTableLayout as faTableLayoutSolid,
  faHandPointer as faHandPointerSolid,
  faGripDotsVertical as faGripDotsVerticalSolid,
  faExclamationCircle as faExclamationCircleSolid,
  faChartSimpleHorizontal as faChartSimpleHorizontalSolid,
  faArrowUpRightFromSquare as faArrowUpRightFromSquareSolid,
  faMemo as faMemoSolid,
  faXmark as faXmarkSolid,
  faBooks as faBooksSolid,
  faPlus as faPlusSolid,
  faRectangleHistoryCirclePlus as faRectangleHistoryCirclePlusSolid,
  faUnlock as faUnlockSolid,
  faSquare as faSquareSolid,
  faCirclePlus as faCirclePlusSolid,
  faLightbulb as faLightbulbSolid,
  faLightbulbOn as faLightbulbOnSolid,
  faGear as faGearSolid,
  faUpload as faUploadSolid,
  faPresentationScreen as faPresentationScreenSolid,
  faCloudArrowUp as faCloudArrowUpSolid,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faSign as faSignRegular,
  faPhone as faPhoneRegular,
  faGlobe as faGlobeRegular,
  faTimes as faTimesRegular,
  faHomeAlt as faHomeAltRegular,
  faPennant as faPennantRegular,
  faEnvelope as faEnvelopeRegular,
  faDoNotEnter as faDoNotEnterRegular,
  faCircleCheck as faCircleCheckRegular,
  faCircleNotch as faCircleNotchRegular,
  faPhoneOffice as faPhoneOfficeRegular,
  faChevronRight as faChevronRightRegular,
  faMapMarkerAlt as faMapMarkerAltRegular,
  faClipboardList as faClipboardListRegular,
  faMobileAndroid as faMobileAndroidRegular,
  faArrowDown as faArrowDownRegular,
  faPenLine as faPenLineRegular,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faYoutube as faYoutubeBrand,
  faTwitter as faTwitterBrand,
  faInstagram as faInstagramBrand,
  faFacebookF as faFacebookFBrand,
} from '@fortawesome/free-brands-svg-icons';

export const provideFaIcons = () => {
  library.add(
    //  solid free

    faStarSolidFree,
    faBoldSolidFree,
    faItalicSolidFree,
    faListUlSolidFree,
    faCaretUpSolidFree,
    faEnvelopeSolidFree,
    faBullseyeSolidFree,
    faAlignLeftSolidFree,
    faAngleLeftSolidFree,
    faCaretDownSolidFree,
    faAlignRightSolidFree,
    faCirclePlaySolidFree,
    faPaperPlaneSolidFree,
    faCirclePlusSolidFree,
    faChevronLeftSolidFree,
    faAlignCenterSolidFree,
    faChevronRightSolidFree,
    faChevronDownSolidFree,
    faMagnifyingGlassSolidFree,
    faFileCirclePlusSolidFree,
    faSquarePollVerticalSolidFree,
    faPenRulerSolidFree,
    faCirclePlaySolidFree,
    faPrintSolidFree,
    faQuoteLeftSolidFree,
    faCircleXmarkSolidFree,
    faFilePdfSolidFree,
    faDollarSignSolidFree,
    faPercentSolidFree,
    faRotateRightSolidFree,
    faCalendarSolidFree,
    faCaretLeft,
    faCaretRight,
    faTrashSolidFree,

    // light

    faCogLight,
    faPlusLight,
    faSaveLight,
    faUndoLight,
    faLinkLight,
    faInfoLight,
    faStarLight,
    faCheckLight,
    faGlobeLight,
    faRulerLight,
    faPollHLight,
    faTimesLight,
    faPrintLight,
    faSearchLight,
    faTrashAltLight,
    faChartBarLight,
    faEnvelopeLight,
    faExchangeLight,
    faThumbsUpLight,
    faHomeLgAltLight,
    faInfoCircleLight,
    faChevronDownLight,
    faUserHeadsetLight,
    faMapMarkerAltLight,
    faGlobeAmericasLight,
    faSlidersVSquareLight,
    faQuestionCircleLight,
    faBullseyePointerLight,
    faCircleQuestionLight,

    // solid

    faEyeSolid,
    faPenSolid,
    faUndoSolid,
    faUserSolid,
    faLockSolid,
    faStarSolid,
    faFontSolid,
    faCheckSolid,
    faImageSolid,
    faTimesSolid,
    faPhoneSolid,
    faSidebarSolid,
    faPaletteSolid,
    faTextSizeSolid,
    faThumbsUpSolid,
    faPowerOffSolid,
    faEnvelopeSolid,
    faChartLineSolid,
    faBorderTopSolid,
    faStopwatchSolid,
    faCaretDownSolid,
    faFileCheckSolid,
    faVideoPlusSolid,
    faAngleDownSolid,
    faLineHeightSolid,
    faUserCircleSolid,
    faInfoCircleSolid,
    faPlusSquareSolid,
    faSquareUserSolid,
    faMinusSquareSolid,
    faHandPointerSolid,
    faTableLayoutSolid,
    faGripDotsVerticalSolid,
    faExclamationCircleSolid,
    faChartSimpleHorizontalSolid,
    faArrowUpRightFromSquareSolid,
    faMemoSolid,
    faTableLayoutSolid,
    faXmarkSolid,
    faBooksSolid,
    faPlusSolid,
    faLightbulbSolid,
    faLightbulbOnSolid,
    faRectangleHistoryCirclePlusSolid,
    faUploadSolid,
    faPresentationScreenSolid,
    faUnlockSolid,
    faSquareSolid,
    faGearSolid,
    faCirclePlusSolid,
    faCloudArrowUpSolid,

    // regular

    faSignRegular,
    faPhoneRegular,
    faGlobeRegular,
    faTimesRegular,
    faHomeAltRegular,
    faPennantRegular,
    faEnvelopeRegular,
    faDoNotEnterRegular,
    faCircleCheckRegular,
    faCircleNotchRegular,
    faPhoneOfficeRegular,
    faChevronRightRegular,
    faMapMarkerAltRegular,
    faClipboardListRegular,
    faMobileAndroidRegular,
    faArrowDownRegular,
    faPenLineRegular,

    // brands

    faYoutubeBrand,
    faTwitterBrand,
    faInstagramBrand,
    faFacebookFBrand,
  );
};
