import { SearchCriteria } from '../../../../../../../../../types';
import { ManualComparableCards } from './ManualComparablesCards/ManualComparableCards';
import { ManualComparableTabs } from './ManualComparableTabs/ManualComparableTabs';

interface Props {
  mlsIds: string[];
  setMlsIds: (ids: string[]) => void;
  subjectId: string;
  isManualView?: boolean;
  onPartial: (criteria: SearchCriteria) => void;
}

export const ManualComparablesSection = ({
  mlsIds,
  setMlsIds,
  subjectId,
  isManualView,
  onPartial,
}: Props) => {
  if (isManualView)
    return (
      <ManualComparableCards
        mlsIds={mlsIds}
        setMlsIds={setMlsIds}
        subjectId={subjectId}
        onPartial={onPartial}
      />
    );

  return (
    <ManualComparableTabs
      mlsIds={mlsIds}
      setMlsIds={setMlsIds}
      subjectId={subjectId}
      onPartial={onPartial}
    />
  );
};
