import styled from 'styled-components';
import { COMPARABLE_MODAL_MENU_SUBITEM_ID } from '../../../../../../../types';
import { SubSection } from '../SubSection';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Checkbox } from '../../../../../../../components/Common/V2/Checkbox';
import NumberFormat from 'react-number-format';
import { Input } from '../../../../../../../components/Common/V2/Input';
import { Select } from '../../../../../../../components/Common';
import { HOA_FEE_FREQUENCY_OPTIONS } from '../../../../../constants/options';

export const OtherFeatures = () => {
  const { control } = useFormContext();

  const hoaFee = useWatch({ name: 'hoaFee' });

  return (
    <SubSection
      title='Other Features'
      titleId={COMPARABLE_MODAL_MENU_SUBITEM_ID.OTHER_FEATURES}
      withHorizontalPadding
    >
      <Wrapper>
        <Controller
          name='isFixerUpper'
          control={control}
          defaultValue={false}
          render={({ field }) => <Checkbox {...field} label='Fixer Upper' />}
        />
        <Controller
          name='isGreenEnergyStar'
          control={control}
          defaultValue={false}
          render={({ field }) => <Checkbox {...field} label='Green/Energy Star' />}
        />
        <Controller
          name='isNewConstruction'
          control={control}
          defaultValue={false}
          render={({ field }) => <Checkbox {...field} label='New Construction' />}
        />
        <Controller
          name='isHorseProperty'
          control={control}
          defaultValue={false}
          render={({ field }) => <Checkbox {...field} label='Horse Property' />}
        />
        <Controller
          name='isAdult55'
          control={control}
          defaultValue={false}
          render={({ field }) => <Checkbox {...field} label='Adult 55+' />}
        />
        <Controller
          name='isGolfCourse'
          control={control}
          defaultValue={false}
          render={({ field }) => <Checkbox {...field} label='Golf Course' />}
        />
        <Controller
          name='hoaFee'
          control={control}
          defaultValue={false}
          render={({ field }) => <Checkbox {...field} label='HOA' />}
        />
      </Wrapper>
      {hoaFee && (
        <HOAWrapper>
          <Controller
            name='hoaFeeValue'
            control={control}
            render={({ field }) => (
              <NumberFormat
                customInput={Input}
                label='HOA Fee:'
                value={field.value || null}
                onValueChange={({ floatValue }) => {
                  field.onChange(floatValue);
                }}
                onBlur={field.onBlur}
                thousandSeparator={true}
                prefix={'$'}
                hideErrorMessage
              />
            )}
          />
          <Controller
            name='hoaFeeFreq'
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label='HOA Payment Frequency:'
                defaultValue={field.value}
                options={HOA_FEE_FREQUENCY_OPTIONS}
              />
            )}
          />
        </HOAWrapper>
      )}
    </SubSection>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 10px;
`;

const HOAWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 10px;
  margin-top: 10px;
`;
