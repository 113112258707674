import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import React, { useRef } from 'react';

import Slide from '../Slide/Slide';
import {
  SlideContentFactoryProps,
  TSlideId,
  TSlidePlatform,
  TWhiteLabelSlidesProp,
} from '../../types';
import { SlidePropsProvider } from '../Slide/providers/SlideProvider';
import { generateMultiPageSlide, isMultiPageSlide } from '../../services/MultislidesBuilder';
import { ScaleFactorProvider } from './ScaleFactorProvider';
import { SlideFactory } from '../Slide/services/SlideFactory';
import { CATEGORIES } from '../../constants/categories';
import { useCategory } from '../../providers/providers/CategoryProvider';
import { useCalculateScaleFactor } from '../../hooks/useCalculateScaleFactor';

interface Props extends SlideContentFactoryProps {
  showMultiPages?: boolean;
  width?: string | number;
  isEditable?: boolean;
  isDefaultConfig?: boolean;
  platform?: TSlidePlatform;
  style?: React.CSSProperties;
  hideProgressTracker?: boolean;
  presentationHash?: string;
  whiteLabelSettings?: TWhiteLabelSlidesProp | null;
}

const ScaleMe = styled('div')<{ platform?: TSlidePlatform }>(({ theme, platform }) => ({
  height: 'max-content',
  width: 'max-content',
  transformOrigin: '0 0',
  border: platform === 'pdf' ? 'none' : `1px solid ${theme.colors.gray[200]}`,
  filter: platform === 'pdf' ? 'none' : 'drop-shadow(2px 4px 12px rgba(0, 0, 0, 0.05))',
}));

const withUserAndSlideProvider = (Component: React.FC<Props>) => {
  const WithUserAndSlideProvider: React.FC<Props> = props => {
    const {
      slide,
      isEditable,
      isDefaultConfig,
      presentationHash,
      hideProgressTracker,
      whiteLabelSettings,
    } = props;

    return (
      <SlidePropsProvider
        slide={slide}
        isEditable={isEditable}
        isDefaultConfig={isDefaultConfig}
        presentationHash={presentationHash}
        hideProgressTracker={hideProgressTracker}
        whiteLabelSettings={whiteLabelSettings}
      >
        <Component {...(props as Props)} />
      </SlidePropsProvider>
    );
  };

  return WithUserAndSlideProvider;
};

interface ScalableContainerProps {
  width?: string | number;
  platform?: TSlidePlatform;
  style?: React.CSSProperties;
}
const ScalableContainer: React.FC<ScalableContainerProps> = ({
  platform,
  width,
  style,
  children,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { scaleFactor, predefined } = useCalculateScaleFactor({
    current: containerRef?.current,
    platform,
  });

  const h =
    platform === 'web' ? predefined.web.height * scaleFactor : predefined.pdf.height * scaleFactor;

  return (
    <ScaleFactorProvider factor={scaleFactor}>
      <div
        ref={containerRef}
        style={{ width: width || '100%', height: h, overflow: 'visible', ...style }}
      >
        <ScaleMe platform={platform} style={{ transform: `scale(${scaleFactor})` }}>
          {children}
        </ScaleMe>
      </div>
    </ScaleFactorProvider>
  );
};

const PreviewSlide: React.FC<Props> = withUserAndSlideProvider(
  ({
    user,
    slide,
    infoPlacement,
    theme,
    platform = 'web',
    width,
    isEditable,
    isDefaultConfig,
    activeSection,
    style = {},
    showMultiPages = false,
    hideProgressTracker,
    presentationHash,
    onCursorTracking = e => {},
    showTemplatePreview = false,
    whiteLabelSettings,
  }) => {
    const { category } = useCategory();

    if (isMultiPageSlide(platform, slide)) {
      const multislides = generateMultiPageSlide(
        slide,
        theme,
        platform,
        category || null,
        isEditable,
      );
      if (!multislides?.length) return null;

      if (!showMultiPages) {
        return (
          <ScalableContainer platform={platform} style={style} width={width}>
            {multislides[0]}
          </ScalableContainer>
        );
      }

      return (
        <>
          {multislides.map(singleSlide => (
            <ScalableContainer
              key={`${slide.id}-${platform}-${singleSlide.key || uuid()}`}
              platform={platform}
              style={style}
              width={width}
            >
              {singleSlide}
            </ScalableContainer>
          ))}
        </>
      );
    }

    const hasSlideComponent = !!SlideFactory.create({
      slide: slide.id as TSlideId,
      theme,
      platform,
      isEditable,
      template: slide.template,
      category: category ?? CATEGORIES.TRADITIONAL,
    });

    if (!hasSlideComponent) return null;

    return (
      <ScalableContainer platform={platform} style={style} width={width}>
        <Slide
          platform={platform}
          user={user}
          slide={slide}
          infoPlacement={infoPlacement}
          theme={theme}
          isEditable={isEditable}
          isDefaultConfig={isDefaultConfig}
          activeSection={activeSection}
          hideProgressTracker={hideProgressTracker}
          presentationHash={presentationHash}
          onCursorTracking={onCursorTracking}
          showTemplatePreview={showTemplatePreview}
          whiteLabelSettings={whiteLabelSettings}
        />
      </ScalableContainer>
    );
  },
);

export default PreviewSlide;
