import React from 'react';
import styled from 'styled-components';
import { useAtomValue } from 'jotai';

import { Card } from './Card';
import { Table } from './Table';
import { CriteriaReviewBox } from './CriteriaReviewBox';
import { useUser } from '../../../../providers/UserProvider';
import { useSlide } from '../../../../providers/SlideProvider';
import { CATEGORIES } from '../../../../../../constants/categories';
import { ColorOpacityApplier } from '../../../../services/ColorOpacityApplier';
import { PdfFrame } from '../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useCategory } from '../../../../../../providers/providers/CategoryProvider';
import { useUiConfigColorsPrimary } from '../../../../../../providers/providers/UiConfigColorProvider';
import { ReportInfoSection } from '../../../whatsMyHomeWorth/comparativeMarketAnalysis/web/ReportInfoSection';
import { getShelflifeAverage } from '../../../../../../pages/PresentationCreate/dash/services/getShelfLifeAverage';
import { Scale } from '../../../../../../features/report/scale-overview/scale/web/Scale';
import { reportPropertiesAtom } from '../../../../../../features/report/state/reportPropertiesAtom';
import { pricingRateOfChangeAtom } from '../../../../../../features/report/state/pricingRateOfChangeAtom';

const Col = styled('div')({ display: 'flex', flexDirection: 'column' });

const Container = styled(Col)({
  height: '100%',
  width: '100%',
});

const Row = styled('div')({
  display: 'flex',
  '&:not(:last-child)': {
    marginBottom: 12,
  },
});

export const OneSheeterPdf: React.FC = () => {
  const { isCanadaUser } = useUser()!;
  const { category } = useCategory();
  const propertiesRaw = useAtomValue(reportPropertiesAtom);
  const properties = propertiesRaw.map(prop => prop.property);

  const rateOfChange = useAtomValue(pricingRateOfChangeAtom);

  const slide = useSlide();

  const sum = properties.reduce((acc, property) => acc + property.pricePerSqft, 0);

  const averagePricePerSqft = Math.round(sum / properties.length);

  const value = getShelflifeAverage(properties);

  const leads =
    slide?.buyerInterestData?.data?.leads?.contacts?.details
      ?.map((item: any) => ({
        buyerId: item.id,
        email: item.contact_mask,
        interests: item.interest_description,
      }))
      .slice(0, 3) ?? [];

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <Container>
        <Row>
          <ReportInfoSection
            coloredBoxStyles={{
              background: ColorOpacityApplier.hex(useUiConfigColorsPrimary(), '0.05'),
            }}
          />
        </Row>
        <Row>
          <CriteriaReviewBox />
        </Row>
        <Row>
          <div
            style={{
              backgroundColor: '#fff',
              transform: 'scale(0.7)',
              transformOrigin: '0 0',
              maxHeight: 420,
            }}
          >
            <Scale
              containerStyle={{
                width: 1285,
                height: 600,
                overflow: 'auto',
                border: '1px solid #ddd',
                borderRadius: 5,
              }}
              defaultMarkersOnly
            />
          </div>
        </Row>
        <Row style={{ gap: 15 }}>
          {!isCanadaUser && (
            <Card
              title='Price Per SQFT'
              value={`$${averagePricePerSqft}`}
              description='Average price per square foot of all comparable properties.'
            />
          )}
          <Card
            title='Pricing Rate of Change'
            value={`${rateOfChange?.text}`}
            description='Average rate of change between listed and recorded price of sold comps'
          />
          <Card
            title='Current Active Shelf Life'
            value={!value ? 'N/A' : value.toString()}
            description='Average days comps are sitting on the site'
            days
          />
        </Row>
        <Row>
          <Table items={leads} />
        </Row>
      </Container>
    </PdfFrame>
  );
};
