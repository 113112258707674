import { useEffect, useMemo, useState } from 'react';
import { CustomComparablesModal } from '../../../../../manual-comparables/OffMLSComparables/CustomComparablesModal/CustomComparablesModal';
import { AddProperties } from './AddProperties';
import { useProperties } from '../../../../../../providers/providers/PropertiesProvider';
import { ManualMLSErrors, ON_MLS_ERRORS, TProperty } from '../../../../../../types';
import { PropertiesList } from './PropertiesList';
import { transformOnMlsCompositeId } from '../../../../../../services/transformOnMlsCompositeId';
import { ReviewOffMlsComparablesModal } from '../../../../../manual-comparables/ReviewCustomComparablesModal/ReviewCustomComparablesModal';
import { useUrlSearchParam } from '../../../../../../hooks/useQueryParams';
import {
  useInvalidatePresentation,
  usePresentation,
} from '../../../../../../hooks/usePresentation';
import { useSetAtom } from 'jotai';
import { customComparableToEditAtom } from '../../../../../manual-comparables/state/customComparableToEditAtom';

export const Properties = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { addProperty, properties, updateProperty } = useProperties();
  const [onMlsErrors, setOnMlsErrors] = useState<ManualMLSErrors>({});
  const [openReviewCustomComparablesModal, setOpenReviewCustomComparablesModal] = useState(false);
  const [isCustomComparablesReviewed, setIsCustomComparablesReviewed] = useState(false);
  const presentationHash = useUrlSearchParam('presentation');
  const setCustomComparableToEdit = useSetAtom(customComparableToEditAtom);
  const { data: presentation } = usePresentation(presentationHash || '');
  const [isFromInvalidListing, setIsFromInvalidListing] = useState(false);

  const [isSingleMlsInputError, setIsSingleMlsInputError] = useState(false);
  const [singleMlsInputErrorProperty, setSingleMlsInputErrorProperty] = useState<TProperty | null>(
    null,
  );

  const presentationId = useUrlSearchParam('presentation_id');
  const isRebuild = useUrlSearchParam('action') === 'rebuild';

  const invalidatePresentation = useInvalidatePresentation(presentationHash || '');

  const onCloseReviewCustomComparablesModal = () => {
    setOpenReviewCustomComparablesModal(false);
    setIsCustomComparablesReviewed(true);
  };

  const notFoundMls = Object.keys(onMlsErrors).filter(
    key =>
      onMlsErrors[key]?.error === ON_MLS_ERRORS.NOT_FOUND ||
      onMlsErrors[key]?.error === ON_MLS_ERRORS.INVALID,
  );

  const offMlsComparables = useMemo(() => {
    return properties.filter(
      property => property?.isCustom && !property?.isCreatedFromInvalidListing,
    );
  }, [properties]);

  useEffect(() => {
    const presentationProperties =
      presentation?.version === 2
        ? presentation?.comparableProperties?.active
        : presentation?.properties;

    const hasCustomOffMlsComparables = presentationProperties?.some(
      (property: TProperty) => property?.isCustom && !property?.isCreatedFromInvalidListing,
    );

    if (!isRebuild || !hasCustomOffMlsComparables || isCustomComparablesReviewed || !presentationId)
      return;

    setOpenReviewCustomComparablesModal(true);
  }, [isCustomComparablesReviewed, isRebuild, presentation, presentationId]);

  const onAddOnMls = (ids: string[], properties: TProperty[]) => {
    ids?.forEach(id => {
      const { listingId } = transformOnMlsCompositeId(id);

      const property = properties.find(property => property.id === listingId);
      addProperty && property && addProperty(property);
    });
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setIsFromInvalidListing(false);
    setCustomComparableToEdit(null);
    setIsFromInvalidListing(false);
  };

  const onOpenModal = () => {
    setIsModalOpen(true);
  };

  const onSuccess = (data: TProperty) => {
    if (properties.find(property => property.id === data.id)) {
      updateProperty(data);
      return;
    }

    const createdId = data.mlsListingId;
    setOnMlsErrors?.(prev => {
      delete prev[createdId as string];
      return prev;
    });
    setIsSingleMlsInputError(false);
    setSingleMlsInputErrorProperty(null);
    addProperty(data);
  };

  const handleEditOpenOnError = (id?: string) => {
    if (!id) return;

    const customComparableToCreate = onMlsErrors[id]?.property || singleMlsInputErrorProperty;
    setCustomComparableToEdit(customComparableToCreate || { id: id });
    setIsFromInvalidListing(true);
    setIsModalOpen(true);
  };

  const handleEditOpen = (id?: string) => {
    if (!id) return;
    const customComparableToCreate = properties?.find(p => p.id === id);
    setCustomComparableToEdit(customComparableToCreate || { id: id });
    setIsModalOpen(true);
  };

  return (
    <>
      <AddProperties
        onOpenModal={onOpenModal}
        setOnMlsErrors={setOnMlsErrors}
        onAddOnMls={onAddOnMls}
        handleEditOpenOnError={handleEditOpenOnError}
        setIsSingleMlsInputError={setIsSingleMlsInputError}
        isSingleMlsInputError={isSingleMlsInputError}
        setSingleMlsInputErrorProperty={setSingleMlsInputErrorProperty}
      />
      <PropertiesList
        notFoundMls={notFoundMls}
        onAddOnMls={onAddOnMls}
        setOnMlsErrors={setOnMlsErrors}
        handleEditOpenOnError={handleEditOpenOnError}
        handleEditOpen={handleEditOpen}
        setIsSingleMlsInputError={setIsSingleMlsInputError}
        isSingleMlsInputError={isSingleMlsInputError}
        setSingleMlsInputErrorProperty={setSingleMlsInputErrorProperty}
      />
      <CustomComparablesModal
        isOpen={isModalOpen}
        onClose={handleClose}
        onSuccess={onSuccess}
        creatingFromInvalidListing={isFromInvalidListing}
      />
      <ReviewOffMlsComparablesModal
        offMlsComparables={offMlsComparables}
        isOpen={openReviewCustomComparablesModal}
        onClose={onCloseReviewCustomComparablesModal}
        presentationId={Number(presentationId)}
        onUpdate={() => invalidatePresentation()}
      />
    </>
  );
};
