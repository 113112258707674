import { states } from '../../../constants/states';
import { BASEMENT_OPTION, GARAGE_OPTION, HOA_FREQUENCY_OPTION, LEVEL_OPTION } from '../../../types';

export const BASEMENT_OPTIONS = [
  { label: BASEMENT_OPTION.NO, value: BASEMENT_OPTION.NO },
  { label: BASEMENT_OPTION.YES, value: BASEMENT_OPTION.YES },
  { label: BASEMENT_OPTION.FINISHED_BASEMENT, value: BASEMENT_OPTION.FINISHED_BASEMENT },
  {
    label: BASEMENT_OPTION.PARTIAL_FINISHED_BASEMENT,
    value: BASEMENT_OPTION.PARTIAL_FINISHED_BASEMENT,
  },
  { label: BASEMENT_OPTION.UNFINISHED_BASEMENT, value: BASEMENT_OPTION.UNFINISHED_BASEMENT },
];

export const GARAGE_OPTIONS = [
  { label: GARAGE_OPTION.NO_GARAGE, value: '0' },
  { label: GARAGE_OPTION.ONE_CAR_GARAGE, value: '1' },
  { label: GARAGE_OPTION.TWO_CAR_GARAGE, value: '2' },
  { label: GARAGE_OPTION.THREE_PLUS_CAR_GARAGE, value: '3' },
];

export const HOA_FEE_FREQUENCY_OPTIONS = [
  { label: HOA_FREQUENCY_OPTION.BI_MONTHLY, value: HOA_FREQUENCY_OPTION.BI_MONTHLY },
  { label: HOA_FREQUENCY_OPTION.MONTHLY, value: HOA_FREQUENCY_OPTION.MONTHLY },
  { label: HOA_FREQUENCY_OPTION.QUARTERLY, value: HOA_FREQUENCY_OPTION.QUARTERLY },
  { label: HOA_FREQUENCY_OPTION.ANNUALLY, value: HOA_FREQUENCY_OPTION.ANNUALLY },
];

export const LEVELS_OPTIONS = [
  { label: LEVEL_OPTION.ONE_LEVEL, value: '1' },
  { label: LEVEL_OPTION.TWO_LEVEL, value: '2' },
  { label: LEVEL_OPTION.THREE_PLUS_LEVEL, value: '3' },
];

export const LISTING_STATUS_OPTIONS = [
  { label: 'Coming Soon', value: 'coming_soon' },
  { label: 'Pending', value: 'pending' },
  { label: 'Active', value: 'active' },
  { label: 'Sold', value: 'sold' },
];

export const STATES_OPTIONS = states.map(({ abbreviation }) => {
  return { value: abbreviation, label: abbreviation };
});
