import { CustomComparablePayload, TProperty } from '../../../types';

export const customComparableEntityToApiMapper = (
  payload: Partial<TProperty> & {
    presentationId: number;
    subject: {
      size: number;
      coordinates: {
        latitude: number;
        longitude: number;
      };
    };
    creatingFromInvalidListing: boolean;
  },
): CustomComparablePayload => {
  return {
    presentation_id: payload.presentationId,
    delivery_line: payload?.address?.deliveryLine || '',
    number: '1', // TODO: get number
    city: payload?.address?.city || '',
    state: payload?.address?.state || '',
    zip: payload?.address?.zip || '',
    status: payload?.status || '',
    type: payload?.propertyType || '',
    price: payload?.price || 0,
    sale_price: payload?.salePrice || 0,
    beds: payload?.beds || 0,
    size: payload?.size || 0,
    latitude: payload?.coordinates?.latitude || 0,
    longitude: payload?.coordinates?.longitude || 0,
    total_baths: payload?.totalBaths || 0,
    baths: payload?.baths || 0,
    total_size: payload?.totalSize || 0,
    list_date: payload?.listDate || 0,
    sale_date: payload?.saleDate || 0,
    year_built: payload?.yearBuilt || 0,
    mls_id: payload?.mlsId ? String(payload?.mlsId) : '',
    is_air_conditioning: payload?.isAirConditioning || false,
    is_master_on_main: payload?.isMasterOnMain || false,
    levels: payload?.levels || 0,
    pool: payload?.pool || false,
    is_deck: payload?.isDeck || false,
    view: payload?.view || false,
    basement: payload?.basement || '',
    is_water_view: payload?.isWaterView || false,
    is_water_front: payload?.isWaterFront || false,
    garage: payload?.garage || 0,
    is_fixer_upper: payload?.isFixerUpper || false,
    is_new_construction: payload?.isNewConstruction || false,
    is_adult55: payload?.isAdult55 || false,
    is_green_energy_star: payload?.isGreenEnergyStar || false,
    is_horse_property: payload?.isHorseProperty || false,
    is_golf_course: payload?.isGolfCourse || false,
    hoa: payload?.hoaFee || false,
    hoa_fee_value: payload?.hoaFeeValue || 0,
    hoa_fee_freq: payload?.hoaFeeFreq || '',
    description: payload?.description || '',
    virtual_tour: payload?.virtualTour || '',
    images: payload?.images || [],
    subject: {
      size: payload?.subject.size,
      coordinates: {
        latitude: payload.subject?.coordinates.latitude,
        longitude: payload.subject?.coordinates?.longitude,
      },
    },
    is_valid_mls_listing: payload?.isValidMlsListing || false,
    creating_from_invalid_listing: payload?.creatingFromInvalidListing || false,
    mls_listing_id: payload?.mlsListingId || '',
  };
};
