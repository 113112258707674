import styled from 'styled-components';
import { TProperty } from '../../../../types';
import { SectionDetailItem } from './components/SectionDetailItem';
import { useDaysOnSite } from '../../../../hooks/daysOnSite/useDaysOnSite';
import { getPropertyTypeString } from '../../../../pages/PresentationCreate/dash/view/pages/Authenticated/PropertySearch/PropertyDetails/PropertySearchCriteria/Criterias/PropertyType/propertyTypeOptions';
import { SectionTitle } from './components/SectionTitle';
import { getPropertyId } from '../../services/getPropertyId';

interface Props {
  property: TProperty;
}

export const ListedInfo = ({ property }: Props) => {
  const DOS = useDaysOnSite(property);
  const customPropertyId = property.sourceId ? property.id || '' : property.mlsListingId || '';
  const id = property.isCustom ? customPropertyId : getPropertyId(property) || '';

  return (
    <Container>
      <SectionTitle title='listed info' />
      {property.status !== 'sold' && (
        <SectionDetailItem
          label='Listing Date'
          value={property.listDateHuman ? property.listDateHuman : '-'}
        />
      )}
      {property.status === 'sold' && (
        <SectionDetailItem
          label='Sold Date'
          value={property.saleDateHuman ? property.saleDateHuman : '-'}
        />
      )}
      {property.status !== 'pending' && (
        <SectionDetailItem label={DOS.label} value={DOS.value ? String(DOS.value) : '-'} />
      )}
      <SectionDetailItem label='MLS ID' value={id.toUpperCase()} />
      <SectionDetailItem
        label='Property Type'
        value={getPropertyTypeString(property.propertyType)}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
