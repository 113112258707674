export class Property {
  id = null;
  address = null;
  excluded = null;
  mlsId = null;
  propertyType = null;
  size = null;
  sizeDiff = null;
  totalSize = null;
  beds = null;
  totalBaths = null;
  baths = null;
  daysOnMarket = null;
  daysToClose = null;
  price = null;
  pricePerSqft = null;
  salePrice = null;
  salePricePerSqft = null;
  yearBuilt = null;
  coordinates = null;
  distance = null;
  distanceKm = null;
  brokerName = null;
  pool = null;
  view = null;
  status = null;
  saleType = null;
  description = null;
  virtualTour = null;
  images = null;
  listDate = null;
  listDateHuman = null;
  saleDate = null;
  saleDateHuman = null;
  hoaFee = null;
  hoaFeeValue = null;
  hoaFeeFreq = null;
  disclaimer = null;
  partialMatch = null;
  partialMatchInfo = null;
  singleImage = null;
  levels = null;
  isFixerUpper = null;
  isNewConstruction = null;
  isAdult55 = null;
  isGreenEnergyStar = null;
  isHorseProperty = null;
  isGolfCourse = null;
  isMasterOnMain = null;
  isAirConditioning = null;
  isWaterFront = null;
  isWaterView = null;
  isDeck = null;
  basement = null;
  garage = null;
  isCustom = null;
  isValidMlsListing = null;
  isCreatedFromInvalidListing = null;
  sourceId = null;
}
