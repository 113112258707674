import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useProperties } from '../../../../../../providers/providers/PropertiesProvider';
import { OffMLSComparable } from '../../../../../manual-comparables/OffMLSComparables/OffMLSComparable';
import { OnMlsComparable } from '../../../../../manual-comparables/OnMLSComparables/OnMlsComparable';
import styled from 'styled-components';
import { useDeleteCustomComparable } from '../../../../../manual-comparables/hooks/useDeleteCustomComparable';
import { getPropertyId } from '../../../../../report/services/getPropertyId';
import { OnMLSInput } from '../../../../../manual-comparables/OnMLSComparables/OnMLSInput';
import { ManualMLSErrors, TProperty } from '../../../../../../types';
import { Droppable, DragDropContext, DropResult } from 'react-beautiful-dnd';

interface Props {
  notFoundMls: string[];
  onAddOnMls: (ids: string[], properties: TProperty[]) => void;
  setOnMlsErrors: Dispatch<SetStateAction<ManualMLSErrors>>;
  handleEditOpenOnError: (id?: string) => void;
  handleEditOpen: (id?: string) => void;
  setIsSingleMlsInputError?: Dispatch<SetStateAction<boolean>>;
  isSingleMlsInputError?: boolean;
  setSingleMlsInputErrorProperty?: Dispatch<SetStateAction<TProperty | null>>;
}
export const PropertiesList = ({
  notFoundMls,
  onAddOnMls,
  setOnMlsErrors,
  handleEditOpen,
  handleEditOpenOnError,
  setIsSingleMlsInputError,
  isSingleMlsInputError,
  setSingleMlsInputErrorProperty,
}: Props) => {
  const { properties, removeProperty, setProperties } = useProperties();
  const { mutateAsync: deleteComparable, isLoading: deleteLoading } = useDeleteCustomComparable();

  const mlsIds = useMemo(() => properties.map(p => getPropertyId(p)), [properties]);

  const handleDeleteCustom = async (id: string) => {
    await deleteComparable(id, {
      onSuccess: () => removeProperty(id),
    });
  };

  const onDragEnd = useCallback(
    (result: DropResult): void => {
      if (!result.destination) {
        return;
      }

      const sourceIndex = result.source.index;
      const destinationIndex = result.destination.index;
      if (properties[destinationIndex].status !== 'active') return;
      const updatedProperties = [...properties];
      const movingItem = updatedProperties.splice(sourceIndex, 1);
      updatedProperties.splice(destinationIndex, 0, movingItem[0]);
      setProperties([...updatedProperties]);
    },
    [properties, setProperties],
  );

  if (!properties?.length && !notFoundMls?.length) return null;

  return (
    <Container>
      {Boolean(notFoundMls.length) && (
        <>
          {notFoundMls.map((mls, i) => {
            return (
              <OnMLSInput
                key={mls}
                onAdd={onAddOnMls}
                mls={mls}
                mlsIds={mlsIds}
                setErrors={setOnMlsErrors}
                isInvalidMLS
                onModalOpen={handleEditOpenOnError}
                setIsSingleMlsInputError={setIsSingleMlsInputError}
                isSingleMlsInputError={isSingleMlsInputError}
                setSingleMlsInputErrorProperty={setSingleMlsInputErrorProperty}
              />
            );
          })}
        </>
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={'manual-mls-list'} type={'manual-mls-list'}>
          {(provided, snapshot) => (
            <Wrapper ref={provided.innerRef} {...provided.droppableProps}>
              {properties?.map((property, i) => {
                if (property?.isCustom && !property?.isCreatedFromInvalidListing) {
                  return (
                    <OffMLSComparable
                      key={property.id}
                      comparable={property}
                      isDisabled={false}
                      onEdit={() => {
                        handleEditOpen(property.id);
                      }}
                      onDelete={() => {
                        handleDeleteCustom(property.id);
                      }}
                      withDnD
                      index={i}
                      notActive={property?.status !== 'active'}
                    />
                  );
                }

                return (
                  <OnMlsComparable
                    key={property.id}
                    mlsIds={mlsIds}
                    property={property}
                    removeProperty={removeProperty}
                    index={i}
                    notActive={property?.status !== 'active'}
                    withDnD
                    propertyMlsId={property?.mlsId}
                    onEdit={() => handleEditOpen(property.id)}
                    onDeleteSuccess={(property: TProperty) => removeProperty(property.id)}
                    onlyActive
                  />
                );
              })}
            </Wrapper>
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  margin: 20px 0;
  gap: 10px;
  border-top: ${({ theme }) => `1px solid ${theme.colors.v2.gray[200]}`};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.v2.gray[200]}`};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
