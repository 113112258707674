import { useFormContext } from 'react-hook-form';
import { Button } from '../../../V2/Button/Button';
import { useSendEmail } from '../hooks/useSendEmail';
import Loader from '../../../../Loader/Loader';
import styled, { useTheme } from 'styled-components';
import { useUser } from '../../../../Slide/providers/UserProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { transformFormValuesToPayload } from '../transformFormValuesToPayload';
import { TEmailFormData } from '../schema';

interface Props {
  presentationHash: string;
  isPdfGenerating?: boolean;
  pdfUrl?: string;
  onPreviewClick: () => void;
  onClose: () => void;
  isPreview?: boolean;
  onBackClick: () => void;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const LoadingText = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[300]};
`;

export const EmailActionButtons = ({
  presentationHash,
  isPdfGenerating,
  pdfUrl,
  onPreviewClick,
  onClose,
  isPreview,
  onBackClick,
}: Props) => {
  const { handleSubmit } = useFormContext();

  const { colors } = useTheme();

  const { mutateAsync: sendEmail, isLoading } = useSendEmail();

  const user = useUser();

  const onSubmit = handleSubmit(async data => {
    const payload = transformFormValuesToPayload({
      formValues: data as TEmailFormData,
      user,
      pdfUrl,
      presentationHash,
    });
    await sendEmail(payload);

    onClose();
  });

  if (isPdfGenerating) {
    return (
      <>
        <Button variant='secondary' onClick={onClose}>
          Cancel
        </Button>
        <Wrapper>
          <Loader color={colors.v2.gray[300]} />
          <LoadingText>Generating PDF...</LoadingText>
        </Wrapper>
      </>
    );
  }

  return (
    <>
      {isPreview && (
        <Button variant='link' onClick={onBackClick}>
          <FontAwesomeIcon icon={['fas', 'chevron-left']} />
          Back
        </Button>
      )}
      <Button variant='secondary' onClick={onClose}>
        Cancel
      </Button>
      {!isPreview && (
        <Button variant='secondary' onClick={onPreviewClick}>
          Preview Email
        </Button>
      )}
      <Button variant='primary' onClick={onSubmit} isLoading={isLoading}>
        Send
      </Button>
    </>
  );
};
