import format from 'date-fns/format';
import {
  BASEMENT_OPTION,
  CustomComparableFormData,
  PROPERTY_STATUS,
  TProperty,
} from '../../../types';
import { CurrentUserService } from '../../../services/CurrentUserService';

export const customComparableFormDataToEntityMapper = (
  payload: CustomComparableFormData,
): Partial<TProperty> => {
  const sortedImages = [...(payload?.images || [])]
    ?.sort((_, b) => {
      return b.isFavorite ? 1 : -1;
    })
    ?.map(img => img.url);

  // TODO: Replace with real data
  const getBasementValue = (value: string) => {
    if (!value) return undefined;

    if (value === BASEMENT_OPTION.NO) return 'none';

    return value.toLowerCase();
  };

  const isCanadaUser = CurrentUserService.isCanadaUser();

  return {
    description: payload.description,
    // source: 'manual',
    // excluded: false,
    mlsId: payload.mlsId,
    mlsListingId: payload.mlsListingId ? String(payload.mlsListingId) : undefined,
    propertyType: payload.propertyType.value,
    address: {
      deliveryLine: payload.address,
      fullAddress: payload.address,
      city: payload.city,
      state: isCanadaUser
        ? (payload.province as string)
        : (payload.state as { label: string; value: string }).value,
      zip: payload.zip,
      external: true,
    },
    size: Number(payload.livingAreaSqft),
    // sizeDiff: 1,
    totalSize: payload.totalSize ? Number(payload.totalSize) : undefined,
    beds: payload.bedroomCount ? Number(payload.bedroomCount) : undefined,
    totalBaths: payload.totalBaths ? Number(payload.totalBaths) : undefined,
    baths: payload.baths ? Number(payload.baths) : undefined,
    // daysOnMarket: 1,
    // daysToClose: 1,
    price: Number(payload.listPrice),
    // pricePerSqft: 1,
    salePrice: Number(payload.soldPrice),
    // salePricePerSqft: 1,
    yearBuilt: payload.yearBuilt ? Number(payload.yearBuilt) : undefined,
    coordinates: {
      latitude: payload.markerPosition.lat,
      longitude: payload.markerPosition.lng,
    },
    // distance: 1,
    // distanceKm: 1,
    pool: payload?.pool,
    view: payload?.view,
    status: payload.listingStatus.value as PROPERTY_STATUS,
    images: sortedImages || [],
    listDate: payload.listDate
      ? Date.UTC(
          payload.listDate.getFullYear(),
          payload.listDate.getMonth(),
          payload.listDate.getDate(),
        ) / 1000
      : undefined,
    saleDate: payload.saleDate
      ? Date.UTC(
          payload.saleDate.getFullYear(),
          payload.saleDate.getMonth(),
          payload.saleDate.getDate(),
        ) / 1000
      : undefined,
    // partialMatch: false, // TODO: get partialMatch
    // partialMatchInfo: 'partialMatchInfo', // TODO: get partialMatchInfo
    // brokerName: 'brokerName', // TODO: get brokerName
    basement: getBasementValue(payload.basement?.value),
    isWaterFront: payload.isWaterFront,
    isWaterView: payload.isWaterView,
    isDeck: payload?.isDeck,
    isFixerUpper: payload?.isFixerUpper,
    isNewConstruction: payload?.isNewConstruction,
    isAdult55: payload?.isAdult55,
    isGreenEnergyStar: payload?.isGreenEnergyStar,
    isHorseProperty: payload?.isHorseProperty,
    isGolfCourse: payload?.isGolfCourse,
    isAirConditioning: payload.isAirConditioning,
    isMasterOnMain: payload.isMasterOnMain,
    hoaFee: payload.hoaFee,
    hoaFeeValue: payload.hoaFeeValue ? Number(payload.hoaFeeValue) : undefined,
    hoaFeeFreq: payload.hoaFeeFreq?.value,
    garage: payload.garage?.value ? Number(payload.garage.value) : undefined,
    levels: payload.levels?.value ? Number(payload.levels.value) : undefined,
    // saleType: 'saleType', // TODO: get saleType or undefined or null
    // disclaimer: 'disclaimer', // TODO: get disclaimer
    // singleImage: 'singleImage', // TODO: get singleImage or undefined or null
    virtualTour: payload.virtualTour || undefined,
    // comparableExtensions: undefined, // TODO: get ComparableExtensions[]
    // adjustedPrice: undefined, // TODO: get adjustedPrice
    // note: undefined, // TODO: get note
    // adjustedPricePerSqft: undefined, // TODO: get adjustedPricePerSqft
    isCustom: true,
  };
};
