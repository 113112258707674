import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  MovedSlideFooter,
  MoveSlideText,
  MoveSlideTextBold,
  RevertCTA,
  SlideContainer,
  SlideWrapper,
} from './styles';
import { TSlide, TSlideId } from '../../../../../../../types';
import PreviewSlide from '../../../../../../../components/PreviewSlide/PreviewSlide.component';
import { useUiConfigTheme } from '../../../../../../../providers/providers/UiConfigThemeProvider';
import { useUiConfigInfoPlacement } from '../../../../../../../providers/providers/UiConfigInfoPlacementProvider';
import { Typography } from '../../../../../../../components/Common';
import { useOrder } from '../../../../../../../providers/providers/OrderProvider';
import { useSections } from '../../../../../../../providers/providers/SectionsProvider';
import WarningConfirmPopup from '../../../../../../../components/Common/WarningConfirmPopup/WarningConfirmPopup';
import { PresentationConfigsFactory } from '../../services/PresentationSlidesFactory';
import { usePresentationType } from '../../../../../../../providers/providers/PresentationTypeProvider';
import { usePresentationMode } from '../../../../../../../providers/providers/PresentationModeProvider';

interface Props {
  slide: TSlide;
  isSelected: boolean;
  onClick: () => void;
}
export const SelectableSlide: React.FC<Props> = ({ slide, isSelected, onClick }) => {
  const { theme } = useUiConfigTheme();
  const { infoPlacement } = useUiConfigInfoPlacement();
  const { sectionsSlide, setSectionsSlideOrder } = useOrder();
  const { sections } = useSections();
  const [isRevertPopupOpen, setIsRevertPopupOpen] = useState(false);
  const { presentationType } = usePresentationType();
  const { presentationMode } = usePresentationMode();

  const defaultSectionSlides = useMemo(() => {
    const config = PresentationConfigsFactory.create(presentationType, presentationMode);
    return config.getPredefinedSectionSlides();
  }, [presentationMode, presentationType]);

  const defaultSlideSection = useMemo(() => {
    if (slide.template) {
      return slide.sectionId;
    }

    let slideSection = null;
    Object.keys(defaultSectionSlides).forEach(section => {
      if (defaultSectionSlides[section].includes(slide.id as TSlideId)) {
        slideSection = section;
      }
    });

    return slideSection;
  }, [defaultSectionSlides, slide.id, slide.sectionId, slide.template]);

  const currentSlideSection = useMemo(() => {
    if (!isSelected) return null;
    let slideSection = null;
    Object.keys(sectionsSlide).forEach(section => {
      if (sectionsSlide[section].includes(slide.id as TSlideId)) {
        slideSection = section;
      }
    });

    return slideSection;
  }, [isSelected, sectionsSlide, slide.id]);

  const isInOtherSection = useMemo(() => {
    const defaultSections = Object.keys(defaultSectionSlides);
    const notIncludedInDefault = !defaultSections.includes(defaultSlideSection as string);

    if (!isSelected || notIncludedInDefault) return false;
    return isSelected && defaultSlideSection && currentSlideSection !== defaultSlideSection;
  }, [currentSlideSection, defaultSectionSlides, defaultSlideSection, isSelected]);

  const onConfirmRevert = useCallback(() => {
    if (!currentSlideSection) return;

    const newSectionsSlide = { ...sectionsSlide };
    newSectionsSlide[currentSlideSection] = newSectionsSlide[currentSlideSection].filter(
      slideId => slideId !== slide.id,
    );
    newSectionsSlide[defaultSlideSection as string] = newSectionsSlide[
      defaultSlideSection as string
    ].concat(slide.id as TSlideId);

    setSectionsSlideOrder(newSectionsSlide);

    setIsRevertPopupOpen(false);
  }, [currentSlideSection, defaultSlideSection, sectionsSlide, setSectionsSlideOrder, slide.id]);

  const { hash } = useParams<{ hash: string }>();

  return (
    <SlideContainer
      onClick={onClick}
      style={{ pointerEvents: slide.id === 'closingAndNextSteps' ? 'none' : 'auto' }}
    >
      <SlideWrapper disabled={!isSelected}>
        <Typography
          variant='body1'
          style={{ fontWeight: 500, position: 'absolute', top: 13, left: 10 }}
          color='400'
        >
          {slide.label}
        </Typography>
        <PreviewSlide
          slide={slide}
          theme={theme}
          infoPlacement={infoPlacement}
          style={{ pointerEvents: 'none' }}
          presentationHash={hash}
        />
      </SlideWrapper>

      <MovedSlideFooter>
        {isInOtherSection && currentSlideSection && (
          <>
            <MoveSlideText>
              Moved to <MoveSlideTextBold>{sections[currentSlideSection]?.label}</MoveSlideTextBold>
            </MoveSlideText>
            <RevertCTA
              onClick={e => {
                e.stopPropagation();
                setIsRevertPopupOpen(true);
              }}
            >
              Revert?
            </RevertCTA>
          </>
        )}
      </MovedSlideFooter>
      {isRevertPopupOpen && (
        <WarningConfirmPopup
          open={isRevertPopupOpen}
          heading={`Reset Slide Position`}
          description={
            'Do you want to return this slide to its default section? This action will reset your recent change.'
          }
          onConfirm={onConfirmRevert}
          onCancel={() => setIsRevertPopupOpen(false)}
          onClose={() => setIsRevertPopupOpen(false)}
          confirmButtonText='Yes, Return Slide to Default Section'
        />
      )}
    </SlideContainer>
  );
};
