import { useMemo } from 'react';
import { PastPresentationItem } from '../types';

import { createColumnHelper } from '@tanstack/react-table';
import { AddressCell } from '../components/PastPresentationsTable/components/AddressCell';
import { TextCell } from '../components/PastPresentationsTable/components/TextCell';
import { LastUpdatedCell } from '../components/PastPresentationsTable/components/LastUpdatedCell';
import { FeedbackCell } from '../components/PastPresentationsTable/components/FeedbackCell';
import { PreviewButtonsCell } from '../components/PastPresentationsTable/components/PreviewButtonsCell';
import { AnalyticsModalButtonCell } from '../components/PastPresentationsTable/components/AnalyticsModalButtonCell';
import { ActionButtonsCell } from '../components/PastPresentationsTable/components/ActionButtonsCell';
import { VersionsCell } from '../components/PastPresentationsTable/components/VersionsCell';

const columnHelper = createColumnHelper<PastPresentationItem>();

export const usePastPresentationsColumns = () => {
  return useMemo(
    () => [
      columnHelper.accessor('address', {
        cell: info => {
          return (
            <AddressCell
              address={info.getValue()}
              canExpand={info.row.getCanExpand()}
              isExpanded={info.row.getIsExpanded()}
              onToggle={info.row.getToggleExpandedHandler()}
              presentationType={info.row.original.type}
            />
          );
        },
        header: 'Address',
        enableSorting: true,
        meta: {
          tdStyles: {
            width: '20%',
          },
        },
      }),
      columnHelper.accessor('clientName', {
        cell: info => {
          return <TextCell text={info.getValue()} />;
        },
        header: 'Client',
        enableSorting: true,
        meta: {
          tdStyles: {
            width: '10%',
          },
        },
      }),
      columnHelper.display({
        id: 'versions',
        cell: info => {
          if (info.row.getParentRow() || info.row.getIsExpanded()) return null;
          return <VersionsCell presentationItem={info?.row?.original} />;
        },
        header: 'Versions',
        enableSorting: false,
        meta: {
          tdStyles: {
            width: '7%',
          },
        },
      }),
      columnHelper.accessor('lastUpdated', {
        cell: info => {
          return (
            <LastUpdatedCell
              lastUpdatedDate={`${info.getValue().toLocaleDateString('en-US')}`}
              presentationHash={info.row.original.hash}
              presentationType={info.row.original.type}
              presentationId={Number.parseInt(info.row.original.id)}
              canBeRebuilt={info.row.original.canBeRebuilt}
            />
          );
        },
        header: 'Last updated',
        enableSorting: true,
        meta: {
          tdStyles: {
            width: '15%',
          },
        },
      }),
      columnHelper.accessor('topFeedback', {
        cell: info => {
          return (
            <FeedbackCell
              topFeedback={info.getValue()}
              feedbackOption={info.row.original.feedbackMoreInfo}
            />
          );
        },
        header: 'Feedback',
        enableSorting: false,
        meta: {
          tdStyles: {
            width: '25%',
          },
        },
      }),
      columnHelper.display({
        id: 'preview',
        cell: info => {
          return (
            <PreviewButtonsCell
              buildInProgress={info.row.original.buildInProgress}
              hash={info.row.original.hash}
              pdf={info.row.original.pdf}
            />
          );
        },
        header: 'Presentation',
        meta: {
          tdStyles: {
            width: '10%',
          },
        },
      }),
      columnHelper.display({
        id: 'analytics',
        cell: info => {
          return (
            <AnalyticsModalButtonCell
              buildInProgress={info.row.original.buildInProgress}
              hash={info.row.original.hash}
              sessionsCount={info.row.original.sessionsCount}
            />
          );
        },
        header: 'Analytics',
        meta: {
          tdStyles: {
            width: '6%',
          },
        },
      }),
      columnHelper.display({
        id: 'actions',
        cell: info => {
          return <ActionButtonsCell presentation={info.row.original} />;
        },
        header: 'Actions',
        meta: {
          tdStyles: {
            width: '7%',
          },
        },
      }),
    ],
    [],
  );
};
