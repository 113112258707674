import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../../components/Common/V2/Button/Button';
import { useOrder } from '../../../../providers/providers/OrderProvider';
import { useClient } from '../../../../providers/providers/ClientProvider';
import { useSlides } from '../../../../providers/providers/SlidesProvider';
import { useSections } from '../../../../providers/providers/SectionsProvider';
import { useVideoLink } from '../../providers/VideoLinkProvider';
import { useProperties } from '../../../../providers/providers/PropertiesProvider';
import { useUrlSearchParam } from '../../../../hooks/useQueryParams';
import { useCategory } from '../../../../providers/providers/CategoryProvider';
import { PRESENTATION_TYPES } from '../../../../constants/PresentationTypes';
import { useUiConfigs } from '../../../../providers/providers/UiConfigProvider';
import { PresentationApi } from '../../../../pages/Presentation/api/PresentationApi';
import { TClient } from '../../../../types';
import { toast } from 'react-toastify';
import { useDomainWhitelabelSettings } from '../../../whitelabel/useDomainWhitelabelSettings';
import { FEATURES, useHasFeatureFlag } from '../../../../hooks/useFeatureFlags';

export const CreateBuyerTourActions: React.FC = () => {
  const { push } = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const ui = useUiConfigs();
  const { client } = useClient();
  const { slides } = useSlides();
  const { category } = useCategory();
  const { sections } = useSections();
  const { videoLink } = useVideoLink();
  const { properties } = useProperties();
  const { sections: sectionsOrder, sectionsSlide } = useOrder();

  const presentationHash = useUrlSearchParam('presentation');
  const presentationId = useUrlSearchParam('presentation_id') || '';

  const queryClient = useQueryClient();

  const { data } = useDomainWhitelabelSettings();

  const activeProperties = React.useMemo(
    () => properties.filter(p => p.status === 'active'),
    [properties],
  );

  const onCreatePresentation = async () => {
    setIsLoading(true);

    try {
      const presentation = {
        ui,
        slides,
        sections,
        order: { sections: sectionsOrder, sectionsSlide },
        category,
        type: PRESENTATION_TYPES.BUYER_TOUR,
        videoLink,
      };

      const presentationData = {
        ...presentation,
        whiteLabelSettings: data?.whiteLabelSettings || null,
      };
      await PresentationApi.updateBT({
        id: presentationId,
        presentation: presentationData,
        shouldBeSaved: true,
        properties: activeProperties,
        client: client as TClient,
      });
      queryClient.invalidateQueries(['presentation']);

      await PresentationApi.triggerPdfGeneration(presentationId);

      push(`/presentation/${presentationHash}/edit`);
    } catch (err) {
      console.error(err);
      toast(
        'We had trouble creating your presentation. Please try again. If the issue persists, please contact our support team.',
        {
          type: 'error',
          position: 'bottom-center',
        },
      );
    } finally {
      setIsLoading(false);
    }
  };

  const enableCreatePresentation = !!client && !!activeProperties.length;

  return (
    <Button
      variant='primary'
      onClick={onCreatePresentation}
      style={{ marginRight: 10, width: 142 }}
      isLoading={isLoading}
      disabled={isLoading || !enableCreatePresentation}
    >
      Save & Continue
    </Button>
  );
};
