import { ReactNode, FC, CSSProperties } from 'react';
import styled from 'styled-components';

interface ChartContainerProps {
  headline?: string;
  description?: ReactNode;
  legend?: ReactNode;
  style?: CSSProperties;
  childrenWrapperStyle?: CSSProperties;
}

export const ChartContainer: FC<ChartContainerProps> = ({
  children,
  headline,
  description,
  legend,
  style = {},
  childrenWrapperStyle = {},
}) => (
  <Container style={style}>
    {headline && <ChartHeadline>{headline}</ChartHeadline>}
    {description}
    {legend}
    <ChildrenWrapper style={childrenWrapperStyle}>{children}</ChildrenWrapper>
  </Container>
);

const Container = styled.div`
  width: 1500px;
  margin: 0 auto;

  @media print {
    width: 1500px;
    margin: 0 auto;
  }

  @media (max-width: 1600px) {
    width: 1100px;
  }
  @media (max-width: 760px) {
    width: 500px;
  }
  @media (max-width: 1000px) {
    width: 640px;
  }
`;

const ChartHeadline = styled.header`
  font-size: 30px;
  font-weight: bold;
  color: #010101;
  border-bottom: 2px solid #ddd;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 12px;
  margin-bottom: 40px;

  span {
    align-self: flex-start;
    margin-left: 50px;

    &::after {
      content: 'x';
      color: #808080;
      font-weight: normal;
      cursor: pointer;
    }
  }

  @media all and (max-width: 1200px) {
    margin: 0 auto 40px auto;
    max-width: 100%;
  }
`;

const ChildrenWrapper = styled.div`
  width: 100%;
  height: 400px;
`;
