import { useSearchByMlsId } from './useSearchByMlsId';
import {
  ManualMLSErrors,
  ManualMlsMultipleProperties,
  ON_MLS_ERRORS,
  TProperty,
} from '../../../../types';

export const useFetchProperties = () => {
  const { mutateAsync: searchByMlsIds } = useSearchByMlsId();

  return async (ids: string[], subjectId: string) => {
    const errors: ManualMLSErrors = {};
    const properties: TProperty[] = [];
    const multipleResults: ManualMlsMultipleProperties = {};

    for (const id of ids) {
      const mlsProperty = await searchByMlsIds({ mlsId: id, propertyId: subjectId }).catch(err => {
        errors[id] = err.message;
      });

      if (!mlsProperty) {
        errors[id] = {
          error: ON_MLS_ERRORS.NOT_FOUND,
          property: null,
        };
        continue;
      }

      if (mlsProperty.length === 1 && !mlsProperty[0].isValidMlsListing) {
        errors[id] = {
          error: ON_MLS_ERRORS.INVALID,
          property: mlsProperty[0],
        };
        properties.push(mlsProperty[0]);
        continue;
      }

      if (mlsProperty.length > 1) {
        multipleResults[id] = mlsProperty;
        continue;
      }

      properties.push(mlsProperty[0]);
    }

    return {
      properties,
      multipleResults,
      hasMultipleResults: !!Object.keys(multipleResults).length,
      errors,
      hasErrors: !!Object.keys(errors).length,
    };
  };
};
