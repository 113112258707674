import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { getErrorMessage } from '../../../pages/PresentationCreate/dash/view/pages/Authenticated/PropertySearch/getErrorMessage';
import { CustomComparablePayload, TError, TProperty } from '../../../types';
import { corePresentApi } from '../../../api/CorePresentApi';

const editCustomComparable = async (
  payload: CustomComparablePayload & { id: string },
): Promise<TProperty> => {
  const response = await corePresentApi.put(`/comparable-property/${payload.id}`, payload);

  return response;
};

export const useEditCustomComparable = () => {
  return useMutation(editCustomComparable, {
    onError: e => {
      console.error(e);
      const errorMessage =
        'We had trouble saving this comparable, please try again. If the issue persists, please contact our support team.';
      toast(errorMessage, {
        type: 'error',
        position: 'bottom-center',
      });
    },
  });
};
