export const activeCompetitionData = {
  heading: 'Fresh/Stale',
  value: 'LISTINGS',
  subtitle: 'The average active comparable',
  description: [
    {
      title: 'WHAT IS FRESH/STALE?',
      text: 'Fresh/stale maps highlight how many comparable properties are currently on the market, where they are located in relation to the subject property, and how long they have been on site (a fresh or stale indicator).',
    },
    {
      title: 'How is fresh/stale used in a price analysis?',
      text: 'Quantity, proximity, and time spent on site are critical variables in determining a solid offense pricing strategy. Quantity helps identify other options buyers have, while the time spent on site can reveal pricing expectations and price fatigue.',
    },
  ],
};
