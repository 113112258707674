import React from 'react';

import { AddSlides } from '../../../AddSlides/AddSlides';
import { OrderableSlide } from './OrderableSlide/OrderableSlide.component';
import { TSectionConfig, TSlide, TSlideId } from '../../../../../../../types';
import { Body, BodyContent, TheContent } from './DynamicOrderableSlides.styles';
import { usePresentationType } from '../../../../../../../providers/providers/PresentationTypeProvider';
import { SlidePropsProvider } from '../../../../../../../components/Slide/providers/SlideProvider';

interface DynamicOrderableSlidesProps {
  notRemovable?: TSlideId[];
  notDraggable?: TSlideId[];
  slides: Record<string, TSlide>;
  order: TSlideId[];
  onRemoveSlide: (slide: TSlide) => void;
  section: TSectionConfig;
  open: boolean;
  showSectionContents?: boolean;
}

export const DynamicOrderableSlides: React.FC<DynamicOrderableSlidesProps> = React.memo(
  function DynamicOrderableSlides({
    notRemovable,
    notDraggable,
    slides,
    order,
    section,
    onRemoveSlide,
    open,
    showSectionContents,
  }) {
    const { presentationType } = usePresentationType();

    let totalSlidesNumber = order.length;
    if (showSectionContents) totalSlidesNumber += 1;

    const hideButton = presentationType === 'presentInfo' ? false : section.id === 'startingSlides';

    return (
      <Body open={open ? 1 : 0}>
        <BodyContent>
          <TheContent>
            {order.map((slideId, i) => {
              const slide = slides[slideId];

              if (!slide || !open) return;

              return (
                <SlidePropsProvider slide={slide} key={slideId}>
                  <OrderableSlide
                    showSectionContents={showSectionContents}
                    slide={slide}
                    index={i}
                    sectionId={section.id}
                    onRemoveSlide={onRemoveSlide}
                    notDraggable={notDraggable}
                    notRemovable={notRemovable}
                  />
                </SlidePropsProvider>
              );
            })}

            {!hideButton && <AddSlides section={section.id} />}
          </TheContent>
        </BodyContent>
      </Body>
    );
  },
);
