import { DefaultTooltipContent } from 'recharts';
import { useIsDTC } from '../../../../hooks/daysOnSite/useIsDTC';
import { MoneyFormatter } from '../../../../pages/PresentationCreate/dash/services/moneyFormatter';
import { TooltipPayloadProps } from '../../../../types';
import { Adjusted, PriceWrapper, TooltipWrapper } from '../components/UI';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';

interface SellingPaceTooltipProps {
  active: boolean;
  payload: TooltipPayloadProps[];
}

export const SellingPaceTooltip = ({ active, payload, ...props }: SellingPaceTooltipProps) => {
  const isDTC = useIsDTC();
  if (!active) {
    return <DefaultTooltipContent payload={payload as Payload<number, string>[]} {...props} />;
  }

  const DOS = {
    label: isDTC ? 'Days To Close' : 'Days on Site',
    value: isDTC ? payload[0].payload.days_to_close : payload[0].value,
  };

  const salePrice = payload[0].payload.sale_price;
  const label = payload[0].payload.name;
  const adjustedPrice = payload[0].payload.adjustedPrice;
  const isCustom = payload[0].payload.isCustom;

  return (
    <TooltipWrapper>
      <p>{label}</p>
      <p>Sale Date: {payload[0].payload.sale_date}</p>

      <PriceWrapper>
        <p
          style={{
            color: payload[0].color,
          }}
        >{`Sale Price: ${MoneyFormatter.format(salePrice)}`}</p>
        {Boolean(adjustedPrice) && (
          <Adjusted>ADJ Sale Price: {MoneyFormatter.format(adjustedPrice)}</Adjusted>
        )}
      </PriceWrapper>

      <p
        style={{
          color: payload[0].color,
        }}
      >{`${DOS.label}: ${DOS.value}`}</p>
      <p>Source: {isCustom ? 'Agent' : 'MLS'}</p>
    </TooltipWrapper>
  );
};
