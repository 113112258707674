import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { getErrorMessage } from '../../../pages/PresentationCreate/dash/view/pages/Authenticated/PropertySearch/getErrorMessage';
import { CustomComparablePayload, TError, TProperty } from '../../../types';
import { corePresentApi } from '../../../api/CorePresentApi';

const createCustomComparable = async (payload: CustomComparablePayload): Promise<TProperty> => {
  const response = await corePresentApi.post('/comparable-property', payload);

  return response;
};

export const useCreateCustomComparable = () => {
  return useMutation(createCustomComparable, {
    onError: e => {
      console.error(e);
      const errorMessage =
        'We had trouble saving this comparable, please try again. If the issue persists, please contact our support team.';
      toast(errorMessage, {
        type: 'error',
        position: 'bottom-center',
      });
    },
  });
};
