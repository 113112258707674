import { GoogleMapComponent } from '../Common/GoogleMap/GoogleMap.component';
import { Marker } from '@react-google-maps/api';
import { Dispatch, SetStateAction, useEffect } from 'react';
import styled from 'styled-components';
import { useMarkerPositionFromAddress } from './useMarkerPositionFromAddress';
import { TManualAddress } from '../../types';

interface Props {
  address?: TManualAddress | null;
  markerPosition: google.maps.LatLngLiteral | null;
  defaultCenter: google.maps.LatLngLiteral | null;
  setMarkerPosition: Dispatch<SetStateAction<google.maps.LatLngLiteral | null>>;
  setDefaultCenter?: Dispatch<SetStateAction<google.maps.LatLngLiteral>>;
  zoom?: number;
}

export const ManualEntryMap = ({
  address,
  markerPosition,
  defaultCenter,
  setMarkerPosition,
  setDefaultCenter,
  zoom = 16,
}: Props) => {
  const { data: markerPositionFromAddress, error } = useMarkerPositionFromAddress(address);

  useEffect(() => {
    const setMarkerFromAddress = () => {
      if (!markerPositionFromAddress) return;

      setMarkerPosition(markerPositionFromAddress);
      setDefaultCenter?.(markerPositionFromAddress);
    };

    setMarkerFromAddress();
  }, [markerPositionFromAddress, setMarkerPosition, setDefaultCenter]);

  return (
    <>
      <GoogleMapComponent
        center={defaultCenter || { lat: 36.778259, lng: -119.417931 }}
        zoom={zoom}
        mapContainerStyle={{ height: `500px`, width: `100%` }}
        onClick={(g: google.maps.MapMouseEvent) => {
          if (!g.latLng) return;
          setMarkerPosition({ lat: g.latLng.lat(), lng: g.latLng.lng() });
        }}
      >
        {markerPosition && <Marker position={markerPosition} />}
      </GoogleMapComponent>
      {error && <LocateError>{String(error)}</LocateError>}
    </>
  );
};

const LocateError = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
