import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { TProperty } from '../../../types';
import { corePresentApi } from '../../../api/CorePresentApi';

const deleteCustomComparable = async (id: string): Promise<TProperty> => {
  const response = await corePresentApi.delete(`/comparable-property/${id}`);

  return response;
};

export const useDeleteCustomComparable = () => {
  return useMutation(deleteCustomComparable, {
    onError: e => {
      console.error(e);
      const errorMessage =
        'We had trouble removing this comparable, please try again. If the issue persists, please contact our support team.';
      toast(errorMessage, {
        type: 'error',
        position: 'bottom-center',
      });
    },
  });
};
