import { TUser } from '../../../../types';
import { TEmailFormData } from './schema';

export const transformFormValuesToPayload = ({
  formValues,
  user,
  pdfUrl,
  presentationHash,
}: {
  formValues: TEmailFormData;
  user: TUser;
  pdfUrl?: string;
  presentationHash: string;
}) => {
  return {
    client_id: formValues.client.id as number,
    subject: formValues.subject,
    message: formValues.message,
    signature: formValues.useDefaultSignature ? user.signature || '' : formValues?.signature || '',
    pdf_url: formValues.isPdfIncluded ? pdfUrl || '' : '',
    include_agent_photo: formValues.isAgentPhotoIncluded || false,
    presentation_hash: presentationHash,
  };
};
