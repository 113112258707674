import { useEffect } from 'react';
import { Table as TTable } from '@tanstack/react-table';
import { usePdfGenerationRetry } from '../../hooks/usePdfGenerationRetry';
import { useLoadPresentationsWithPdfPooling } from '../../hooks/useLoadPresentations';
import { PastPresentationItem } from '../../types';
import { Table } from '../../../../../../components/Table';

interface Props {
  table: TTable<PastPresentationItem>;
}

export const PastPresentationsTable = ({ table }: Props) => {
  const { data } = useLoadPresentationsWithPdfPooling();

  const { mutate: pdfGenerationRetry, isLoading: isPdfGenerationLoading } = usePdfGenerationRetry();

  useEffect(() => {
    if (!data) return;

    if (isPdfGenerationLoading) return;
    pdfGenerationRetry(data);
  }, [data, isPdfGenerationLoading, pdfGenerationRetry]);

  return <Table table={table} tableWrapperStyles={{ minWidth: '1220px' }} />;
};
