import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import React, { useState, useRef } from 'react';
import { TBuyerTourPresentation } from '../../../../../types';
import { AddClient } from '../../../../../components/Common/AddClient/AddClient.component';
import { TClient } from '../../../../../types';
import { SendEmailPopup } from '../../../../../components/Common/SendEmailPopup/SendEmailPopup.component';
import { usePresentationPdf } from '../../../../../hooks/usePresentationPdf';
import { DashboardItem } from '../../../../../pages/Dashboard/DashboardItems/DashboardItem/DashboardItem.component';

import { Container } from '../../../../../pages/Dashboard/DashboardItems/DashboardItems.styles';
import { BTAddCoreVideo } from './BTAddCoreVideo/BTAddCoreVideo.component';
import { useSlides } from '../../../../../providers/providers/SlidesProvider';
import { corePresentApi } from '../../../../../api/CorePresentApi';
import { useQueryClient } from '@tanstack/react-query';
import { URLS } from '../../../../../constants/urls';
import { PresentationApi } from '../../../../../pages/Presentation/api/PresentationApi';

export type TDashboardItem = 'emailPresentation' | 'addCoreVideo' | 'addClient';

interface DashboardItemsProps {
  presentationData: TBuyerTourPresentation;
}

export const BTDashboardItems: React.FC<DashboardItemsProps> = ({ presentationData }) => {
  const { hash } = useParams<{ hash: string }>();
  const inputRef = useRef<HTMLInputElement>(null);
  const presentationUrl = `${URLS.CORE_PRESENT_FRONT_APP}/presentation/${hash}`;

  const { slides } = useSlides();

  const [dashboardItem, setDashboardItem] = useState<null | TDashboardItem>(null);
  const [loadingItem, setLoadingItem] = useState<TDashboardItem | null>(null);
  const [isPdfLoading, setIsPdfLoading] = useState(false);

  const queryClient = useQueryClient();

  const copyToClipboard = () => {
    const copyText = inputRef.current;
    if (!copyText) return;
    copyText.value = presentationUrl;
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
    toast('Copied to clipboard', { type: 'info', position: 'bottom-center' });
  };

  const { videoLink } = presentationData;

  const presentationClient = presentationData.clientId
    ? {
        name: presentationData.clientName,
        id: presentationData.clientId,
        email: presentationData.clientEmail,
      }
    : null;

  const greetingVideoSlide = slides?.['greetingVideo'];

  const getDashboardModalContent = (item: TDashboardItem | null) => {
    switch (item) {
      case 'addClient':
        return (
          <AddClient
            isOpen={true}
            onClose={() => setDashboardItem(null)}
            id={presentationData.id ? presentationData.id : ''}
            presentationClient={presentationClient as TClient}
            presentationType={presentationData?.type}
            hash={hash}
          />
        );
      case 'emailPresentation':
        return (
          <SendEmailPopup
            presentationType={presentationData.type}
            presentationHash={presentationData?.hash ?? ''}
            client={{
              id: presentationData.clientId,
              name: presentationData.clientName,
              email: presentationData.clientEmail,
            }}
            isOpen={true}
            onClose={() => setDashboardItem(null)}
            isPdfGenerating={!!data?.isBuildInProgress || isLoading}
            pdfUrl={data?.pdf}
          />
        );
      case 'addCoreVideo':
        return (
          <BTAddCoreVideo
            isOpen={true}
            onClose={() => setDashboardItem(null)}
            initialVideoLink={videoLink}
            presentationData={presentationData as TBuyerTourPresentation}
          />
        );
      default:
        return <></>;
    }
  };

  const onRemoveVideoLink = async () => {
    try {
      if (!presentationData) return;
      if (!greetingVideoSlide) return;

      setLoadingItem('addCoreVideo');

      const data = {
        ...presentationData,
        order: {
          ...presentationData.order,
          sectionsSlide: {
            ...presentationData.order.sectionsSlide,
            initSection: presentationData.order.sectionsSlide.initSection.filter(
              sId => sId !== 'greetingVideo',
            ),
          },
        },
        videoLink: null,
      };
      await corePresentApi.put(`/presentations/${presentationData.id}`, {
        reset: 'video_link',
        data: JSON.stringify(data),
      });
      setLoadingItem(null);
      queryClient.invalidateQueries(['presentation', presentationData?.hash]);
    } catch (e: any) {
      console.error(e);
    }
  };

  const presentationId = presentationData.id ? presentationData.id : '';

  const { data, isLoading } = usePresentationPdf(presentationId, hash);

  const onOpenAddClientModal = () => {
    setDashboardItem('addClient');
  };

  const onDownloadPdf = async (pdf?: string, hash?: string) => {
    if (!pdf || !hash) return;
    try {
      setIsPdfLoading(true);
      await PresentationApi.downloadPresentation(pdf, hash);
    } catch (e: any) {
      console.error('Saving PDF ERROR: ', e);
    } finally {
      setIsPdfLoading(false);
    }
  };

  return (
    <Container>
      <DashboardItem
        icon={['fas', 'square-user']}
        text={presentationClient ? `Client added: ${presentationClient.name}` : 'Save & Add Client'}
        onClick={onOpenAddClientModal}
        keepFontSize
        hasRemoveButton={false}
        isHighlighted
        hasSavedLabel={!!presentationClient}
        buttonText={presentationClient ? 'Edit' : undefined}
      />

      {greetingVideoSlide && (
        <DashboardItem
          icon={['fas', 'video-plus']}
          text='Add Video Link'
          onClick={() => setDashboardItem('addCoreVideo')}
          buttonText={videoLink ? 'Edit' : ''}
          keepFontSize
          hasRemoveButton={!!videoLink}
          onRemoveClick={onRemoveVideoLink}
          loading={loadingItem === 'addCoreVideo'}
        />
      )}
      <DashboardItem
        icon={['fas', 'circle-play']}
        text='Open Presentation'
        onClick={() => window.open(`/present/presentation/${hash}`)}
      />
      <DashboardItem
        icon={['fas', 'envelope']}
        text='Email Presentation'
        onClick={() => presentationClient && setDashboardItem('emailPresentation')}
        disabled={!presentationClient}
      />
      <DashboardItem
        loading={isLoading || !!data?.isBuildInProgress || isPdfLoading}
        icon={['fas', 'file-check']}
        text={`Download & Print`}
        onClick={() => onDownloadPdf(data?.pdf, presentationData?.hash)}
      />
      <DashboardItem
        icon={['fas', 'arrow-up-right-from-square']}
        text={presentationUrl}
        buttonText='Copy'
        onClick={copyToClipboard}
      />
      {getDashboardModalContent(dashboardItem)}
      <input
        type='text'
        ref={inputRef}
        style={{ position: 'absolute', opacity: 0, zIndex: -1 }}
        defaultValue=''
      />
    </Container>
  );
};
