import { FC, forwardRef } from 'react';
import { useAtomValue } from 'jotai';
import styled, { CSSProperties, useTheme } from 'styled-components';

import { useUiConfigColorsPrimary } from '../../../providers/providers/UiConfigColorProvider';
import { isPresentationViewAtom } from '../../../features/report/state/isPresentationViewAtom';

interface CheckboxProps {
  label?: string;
  name?: string;
  onChange: (checked: boolean) => void;
  value: boolean;
  disabled?: boolean;
  labelStyles?: CSSProperties;
  containerStyles?: CSSProperties;
}

export const Checkbox: FC<CheckboxProps> = forwardRef<HTMLDivElement, CheckboxProps>(
  ({ label, name, value, onChange, disabled, labelStyles, containerStyles }, ref) => {
    const primaryColor = useUiConfigColorsPrimary();
    const theme = useTheme();
    const isPresentationView = useAtomValue(isPresentationViewAtom);

    const color = isPresentationView ? primaryColor : theme.colors.v2.primary;

    return (
      <CheckboxLabel disabled={disabled} style={containerStyles}>
        <CheckboxWrapper>
          <HiddenCheckbox
            name={name}
            checked={value}
            onChange={() => onChange(!value)}
            disabled={disabled}
          />
          <StyledCheckbox checked={value} color={color} ref={ref}>
            <Icon viewBox='0 0 24 24'>
              <polyline points='20 6 9 17 4 12' strokeLinecap='round' strokeLinejoin='round' />
            </Icon>
          </StyledCheckbox>
        </CheckboxWrapper>
        {label && (
          <Label style={labelStyles} disabled={disabled}>
            {label}
          </Label>
        )}
      </CheckboxLabel>
    );
  },
);

Checkbox.displayName = 'Checkbox';

const CheckboxLabel = styled.label<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const CheckboxWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2.5px;
`;

const StyledCheckbox = styled.div<{ checked: boolean; color: string }>`
  display: flex;
  width: 15px;
  height: 15px;
  border-radius: 2px;
  transition: all 150ms;
  background: ${props => (props.checked ? props.color : 'transparent')};
  border: 1px solid ${props => (props.checked ? props.color : '#b1b1b1')};

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;

const Label = styled.span<{ disabled?: boolean }>`
  color: ${props => (props.disabled ? '#b1b1b1' : '#444')};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
