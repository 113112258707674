import { useQueries } from '@tanstack/react-query';
import { MediaApi } from '../../../../../../../../api/MediaApi';

const uploadImage = async (file: File): Promise<string> => {
  const imageUrl = await MediaApi.upload(file);
  return imageUrl;
};

export const useUploadImages = (files: File[]) => {
  return useQueries<string[]>({
    queries: files.map(file => {
      return {
        queryKey: ['imageUpload', file.name],
        queryFn: () => uploadImage(file),
        staleTime: Infinity,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      };
    }),
  });
};
