import styled from 'styled-components';
import { COMPARABLE_MODAL_MENU_ITEM_ID } from '../../../../../../../types';
import { MainSection } from '../MainSection';
import { MainFeatures } from './MainFeatures';
import { UploadImage } from './UploadImage/UploadImage';
import { InteriorFeatures } from './InteriorFeatures';
import { ExteriorFeatures } from './ExteriorFeatures';
import { OtherFeatures } from './OtherFeatures';
import { Description } from './Description';
import { VirtualTour } from './VirtualTour';

export const OptionalFields = () => {
  return (
    <MainSection
      title='Optional Fields'
      titleId={COMPARABLE_MODAL_MENU_ITEM_ID.OPTIONAL_FIELDS}
      titleWrapperStyle={{ paddingLeft: '20px' }}
      description='Enter as many details as possible to ensure your listing integrates into all relevant data sets - missing fields may exclude it from some comparisons.'
    >
      <SubSectionsContainer>
        <MainFeatures />
        <InteriorFeatures />
        <ExteriorFeatures />
        <OtherFeatures />
        <Description />
        <VirtualTour />
        <UploadImage />
      </SubSectionsContainer>
    </MainSection>
  );
};

const SubSectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
