import React from 'react';
import { extractFirstSectionSlideMap } from '../pages/Presentation/services/SlidesExtractor';
import { TPresentation, TUser } from '../types';
import PresentationSlide from '../pages/Presentation/components/PresentationSlide/PresentationSlide';
import { config as sectionContentsSlide } from '../components/Slide/slides/sectionContents';
import { usePresentationSlides } from '../hooks/useModePresentationSlides';
import { usePresentationMode } from '../providers/providers/PresentationModeProvider';

interface PresentationSsrProps {
  presentation: TPresentation;
  user: TUser;
}
export const Slides: React.FC<PresentationSsrProps> = ({ presentation, user }) => {
  const { presentationMode } = usePresentationMode();
  const slides = usePresentationSlides(presentation);
  const firstSlideMap = extractFirstSectionSlideMap(presentation);

  const filteredSlides = slides.filter(
    slide => slide && slide.template !== 'pdfPresentationTemplate',
  );

  return (
    <>
      {filteredSlides.map(slide => {
        return (
          <>
            {firstSlideMap[slide.id] && (
              <PresentationSlide
                key={`section-slide-${slide.id}`}
                platform='pdf'
                resizable
                user={user}
                infoPlacement={presentation.ui.infoPlacement}
                theme={presentation.ui.theme}
                slide={sectionContentsSlide}
                activeSection={firstSlideMap[slide.id]}
                presentationHash={presentation.hash}
                whiteLabelSettings={presentation.whiteLabelSettings}
              />
            )}

            <PresentationSlide
              key={slide.id}
              platform='pdf'
              resizable
              user={user}
              infoPlacement={presentation.ui.infoPlacement}
              theme={presentation.ui.theme}
              slide={slide}
              presentationHash={presentation.hash}
              hideProgressTracker={presentationMode !== 'regular'}
              whiteLabelSettings={presentation.whiteLabelSettings}
            />
          </>
        );
      })}
    </>
  );
};
