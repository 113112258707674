import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDropzone } from 'react-dropzone';
import styled, { useTheme } from 'styled-components';
import { Button } from '../../../../../../../../components/Common/V2/Button/Button';
import Loader from '../../../../../../../../components/Loader/Loader';

interface Props {
  onDrop: (acceptedFiles: File[]) => void;
  isLoading: boolean;
}

export const UploadImageArea = ({ onDrop, isLoading }: Props) => {
  const { colors } = useTheme();

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    maxFiles: 10,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'image/svg+xml': ['.svg'],
      'image/bmp': ['.bmp'],
    },
    noClick: true,
  });

  return (
    <DropArea {...getRootProps()}>
      <input {...getInputProps()} accept='image/*' />
      {!isLoading && (
        <FontAwesomeIcon
          style={{ color: colors.v2.gray[200], fontSize: 26 }}
          icon={['fas', 'cloud-arrow-up']}
        />
      )}
      <Wrapper>
        {isLoading ? (
          <Loader size='3x' centered />
        ) : (
          <>
            <Title>Drag & Drop property image here</Title>
            <Or>OR</Or>
            <Button variant='primary' onClick={open}>
              Browse Files
            </Button>
            <AdditionalInfoWrapper>
              <AdditionalInfo>
                <InfoLabel>Supported File Types:</InfoLabel>
                <InfoText>.bmp .jpg .jpeg .png .svg </InfoText>
              </AdditionalInfo>
              <AdditionalInfo>
                <InfoLabel>Image Upload Limit:</InfoLabel>
                <InfoText>10 </InfoText>
              </AdditionalInfo>
            </AdditionalInfoWrapper>
          </>
        )}
      </Wrapper>
    </DropArea>
  );
};

const DropArea = styled.div`
  display: flex;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.v2.gray[100]};
  border: 2px dashed ${({ theme }) => theme.colors.v2.gray[200]};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  padding: 25px;
  height: 276px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;

const Or = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;

const AdditionalInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
`;

const AdditionalInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;
`;

const InfoLabel = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;

const InfoText = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.v2.gray[300]};
`;
