import styled from 'styled-components';
import { TProperty } from '../../../types';

import { ListingGallery } from './ListingGallery';
import { PageHeader } from './PageHeader/PageHeader';
import { Details } from './Details/Details';
import { Notes } from './Notes';
import { getPropertyId } from '../services/getPropertyId';

interface Props {
  notes: any[];
  property: TProperty;
}

export const ListingDetailsPdf = ({ notes, property }: Props) => {
  const note = property.note || notes?.find(n => n.property_id === getPropertyId(property))?.note;

  const bottomText = property.isCustom
    ? 'This property was manually added by the Agent and was not sourced from the MLS. All information is deemed reliable but subject to verification.'
    : 'All information provided is deemed reliable but is not guaranteed and should be independently verified.';

  return (
    <Container>
      <PageHeader property={property} />

      <DetailsContainer>
        <Details property={property} />

        <GalleryContainer style={{ marginBottom: '10px' }}>
          <ListingGallery propertyImages={property?.images} />
        </GalleryContainer>
        {note && <Notes note={note} />}
      </DetailsContainer>

      <Legal>{bottomText}</Legal>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const DetailsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
`;

const Legal = styled.div`
  padding-top: 15px;
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray[400]};
  font-size: 13px;
  font-weight: 500;
`;

const GalleryContainer = styled.div`
  overflow: hidden;
  padding-top: 20px;
  border-top: ${({ theme }) => `1px solid ${theme.colors.gray[200]}`};
`;
