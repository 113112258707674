import { CSSProperties, FC } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface Props {
  id: string;
  sectionId: string;
  index: number;
  disabled?: boolean;
}

export const Draggable: FC<Props> = ({ id, sectionId, index, disabled, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: id,
    disabled: disabled,
    data: {
      sectionId: sectionId,
      index: index,
    },
  });

  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
    position: 'relative',
    zIndex: isDragging ? 100 : 0,
  };

  const rotationStyle: CSSProperties = {
    transform: isDragging ? 'rotate(4deg)' : '',
  };

  return (
    <div ref={setNodeRef} {...listeners} {...attributes} style={style}>
      <div style={rotationStyle}>{children}</div>
    </div>
  );
};
