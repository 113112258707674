import React from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PreviewSlide from '../../../../../../../../components/PreviewSlide/PreviewSlide.component';
import { useSelectedSlide } from '../../../../../../../../providers/providers/SelectedSlideProvider';
import { useUiConfigInfoPlacement } from '../../../../../../../../providers/providers/UiConfigInfoPlacementProvider';
import { useUiConfigTheme } from '../../../../../../../../providers/providers/UiConfigThemeProvider';
import { TSection, TSlide, TSlideId } from '../../../../../../../../types';
import { usePreviewMode } from '../../../../../providers/PreviewModeProvider';
import {
  SlideWrapper,
  InsideWrapper,
  LabelWrapper,
  RemoveIcon,
  GrabWrapper,
} from './OrderableSlide.style';
import { config as sectionContentsConfig } from '../../../../../../../../components/Slide/slides/sectionContents';
import { usePresentationMode } from '../../../../../../../../providers/providers/PresentationModeProvider';
import { useCategory } from '../../../../../../../../providers/providers/CategoryProvider';
import { shouldHideMultiSlidesPdf } from './shouldHideMultiSlidesPdf';
import { Draggable } from '../../../Draggable';
import { GhostSlide } from './GhostSlide';

interface SlideProps {
  slide: TSlide;
  index: number;
  showSectionContents?: boolean;
  sectionId: TSection;
  onRemoveSlide: (slide: TSlide) => void;
  notRemovable?: TSlideId[];
  notDraggable?: TSlideId[];
}

export const OrderableSlide: React.FC<SlideProps> = ({
  slide,
  index,
  showSectionContents,
  sectionId,
  onRemoveSlide,
  notDraggable,
  notRemovable,
}) => {
  const { infoPlacement } = useUiConfigInfoPlacement();
  const { theme } = useUiConfigTheme();
  const { selectedSlide, setSelectedSlide } = useSelectedSlide();
  const { previewMode } = usePreviewMode();
  const { presentationMode } = usePresentationMode();
  const { category } = useCategory();

  const appTheme = useTheme();

  const { hash } = useParams<{ hash: string }>();

  const styles: React.CSSProperties = {
    width: previewMode === 'web' ? '228px' : '144px',
  };

  if (previewMode === 'pdf') {
    styles.marginLeft = 'auto';
    styles.marginRight = 'auto';
  }

  const isNotRemovable = notRemovable?.includes(slide.id as TSlideId);
  const isNotDraggable = notDraggable?.includes(slide.id as TSlideId);

  const hideMultiSlidesPdf = shouldHideMultiSlidesPdf(previewMode, slide, theme, category);

  if (!selectedSlide) return null;

  return (
    <>
      {showSectionContents && index === 0 && (
        <SlideWrapper
          isactive={selectedSlide?.id === 'sectionContents'}
          style={styles}
          onClick={() => setSelectedSlide(sectionContentsConfig)}
        >
          <PreviewSlide
            slide={sectionContentsConfig}
            theme={theme}
            infoPlacement={infoPlacement}
            activeSection={sectionId}
            platform='pdf'
            presentationHash={hash}
            hideProgressTracker={presentationMode !== 'regular'}
          />
          <InsideWrapper onClick={() => {}} />
        </SlideWrapper>
      )}
      <GhostSlide
        slide={slide}
        isPDF={previewMode === 'pdf'}
        sectionId={sectionId}
        disabled={isNotDraggable}
        hideMultiSlidesPdf={hideMultiSlidesPdf}
      >
        <Draggable
          key={slide.id}
          id={slide.id}
          sectionId={sectionId}
          index={index}
          disabled={isNotDraggable}
        >
          <LabelWrapper>
            <GrabWrapper>
              {!isNotDraggable && (
                <FontAwesomeIcon
                  icon={['fas', 'grip-dots-vertical']}
                  color={appTheme.colors.v2.gray[400]}
                />
              )}
              {slide.label}
            </GrabWrapper>
            {!isNotRemovable && (
              <RemoveIcon onClick={() => onRemoveSlide(slide)}>
                <FontAwesomeIcon icon={['fas', 'close']} color={appTheme.colors.v2.gray[400]} />
              </RemoveIcon>
            )}
          </LabelWrapper>
          <SlideWrapper isactive={selectedSlide?.id === slide.id} style={styles}>
            <PreviewSlide
              slide={slide}
              theme={theme}
              infoPlacement={infoPlacement}
              platform={previewMode ?? 'web'}
              presentationHash={hash}
              hideProgressTracker={presentationMode !== 'regular'}
            />
            <InsideWrapper onClick={() => setSelectedSlide(slide)} />
          </SlideWrapper>
        </Draggable>
      </GhostSlide>
    </>
  );
};
