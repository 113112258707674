import { useParams } from 'react-router-dom';
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { corePresentApi } from '../../../api/CorePresentApi';
import { PresentationApi } from '../../Presentation/api/PresentationApi';
import { usePresentationPdf } from '../../../hooks/usePresentationPdf';

import { URLS } from '../../../constants/urls';
import { useSections } from '../../../providers/providers/SectionsProvider';
import { useOrder } from '../../../providers/providers/OrderProvider';
import { useSetSlidesSectionsAndOrderOnDashboard } from './hooks/useSetSlidesSectionsAndOrderOnDashboard';
import { usePresentationMode } from '../../../providers/providers/PresentationModeProvider';
import { useSlides } from '../../../providers/providers/SlidesProvider';
import { usePresentationType } from '../../../providers/providers/PresentationTypeProvider';

import { DashboardItem } from './DashboardItem/DashboardItem.component';
import { Container } from './DashboardItems.styles';
import { TClient, TPresentation } from '../../../types';
import { DashboardItemsUtils } from './services/DashboardItemsUtils';
import { getDashboardModalContent } from './services/getDashboardModalContent';
import { hasGreetingVideoSlide } from '../../PresentationEdit/PresentationCreationContent/services/hasGreetingVideoSlide';
import { hasNetProceedsSlide } from '../../PresentationEdit/PresentationCreationContent/services/hasNetProceedsSlide';
import { PRESENTATION_TYPES } from '../../../constants/PresentationTypes';
import { useInvalidatePresentation } from '../../../hooks/usePresentation';

export type TDashboardItem =
  | 'addLink'
  | 'addNetSheet'
  | 'emailPresentation'
  | 'presentationScheduler'
  | 'addCoreVideo'
  | 'addClient';

interface DashboardItemsProps {
  presentationData: TPresentation;
}

export const DashboardItems: React.FC<DashboardItemsProps> = ({ presentationData }) => {
  const { hash } = useParams<{ hash: string }>();
  const inputRef = useRef<HTMLInputElement>(null);
  const presentationUrl = `${URLS.CORE_PRESENT_FRONT_APP}/presentation/${hash}`;
  const [isPdfLoading, setIsPdfLoading] = useState(false);

  const { getSlide } = useSlides();

  const [dashboardItem, setDashboardItem] = useState<null | TDashboardItem>(null);
  const [loadingItem, setLoadingItem] = useState<TDashboardItem | null>(null);

  const { sections, sectionsSlide, setSectionsSlideOrder, orderByMode } = useOrder();
  const { sectionsByMode } = useSections();

  const { presentationMode } = usePresentationMode();
  const { presentationType } = usePresentationType();
  const invalidatePresentation = useInvalidatePresentation(hash);

  useSetSlidesSectionsAndOrderOnDashboard(presentationData);

  const queryClient = useQueryClient();

  const { agreementLink, videoLink } = presentationData;

  const presentationClient = presentationData.clientId
    ? {
        name: presentationData.clientName,
        id: presentationData.clientId,
        email: presentationData.clientEmail,
      }
    : null;

  const netSheetSlide = getSlide('netProceeds');
  const nextStepsSlide = getSlide('closingAndNextSteps');

  const onRemoveNetSheet = async () => {
    setLoadingItem('addNetSheet');

    if (!presentationData || !presentationData.id || !netSheetSlide) return;

    const presentationSlidesMap = { ...presentationData.slidesMap };
    delete presentationSlidesMap.netProceeds;

    const data = {
      ...presentationData,
      ...DashboardItemsUtils.removeSlideFromModes(
        'netProceeds',
        orderByMode,
        sectionsByMode,
        setSectionsSlideOrder,
      ),
      slidesMap: presentationSlidesMap,
    };

    await PresentationApi.update(presentationData.id, data);
    PresentationApi.triggerPdfGeneration(presentationData.id);
    queryClient.invalidateQueries(['presentation-pdf', presentationData.id]);
    queryClient.invalidateQueries(['presentation', presentationData?.hash]);
    setLoadingItem(null);
  };

  const onRemoveAgreementLink = async () => {
    try {
      setLoadingItem('addLink');
      await corePresentApi.put(`/presentations/${presentationData.id}`, {
        reset: 'agreement_link',
      });

      queryClient.invalidateQueries(['presentation', presentationData.hash]);
      setLoadingItem(null);
    } catch (e: any) {
      console.error(e);
    }
  };

  const onRemoveVideoLink = async () => {
    try {
      if (!presentationData) return;

      setLoadingItem('addCoreVideo');

      const presentationSlidesMap = { ...presentationData.slidesMap };
      delete presentationSlidesMap.greetingVideo;

      const data = {
        ...presentationData,
        ...DashboardItemsUtils.removeSlideFromModes(
          'greetingVideo',
          orderByMode,
          sectionsByMode,
          setSectionsSlideOrder,
        ),
        slidesMap: {
          ...presentationSlidesMap,
        },
        videoLink: null,
      };

      await corePresentApi.put(`/presentations/${presentationData.id}`, {
        reset: 'video_link',
        data: JSON.stringify(data),
      });
      queryClient.invalidateQueries(['presentation', presentationData.hash]);

      setLoadingItem(null);
    } catch (e: any) {
      console.error(e);
    }
  };

  const presentationId = presentationData.id || '';

  const { data, isLoading, isFetching } = usePresentationPdf(presentationId, hash);

  useEffect(() => {
    setTimeout(() => {
      if (data?.pdf || isLoading || isFetching || data?.isBuildInProgress) return;
      invalidatePresentation();
    }, 2000);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onOpenAddClientModal = () => {
    setDashboardItem('addClient');
  };

  const onDownloadPdf = async (pdf?: string, hash?: string) => {
    if (!pdf || !hash) return;
    try {
      setIsPdfLoading(true);
      await PresentationApi.downloadPresentation(pdf, hash);
    } catch (e: any) {
      console.error('Saving PDF ERROR: ', e);
    } finally {
      setIsPdfLoading(false);
    }
  };

  const hasGV = hasGreetingVideoSlide(presentationMode, { sections, sectionsSlide });
  const hasNS = hasNetProceedsSlide(presentationMode, { sections, sectionsSlide });

  const showAgreementLink = !['cma', 'oneSheeter'].includes(presentationMode) && nextStepsSlide;

  const agreementLinkText = useMemo(() => {
    const isWinTheOffer = presentationData.type === PRESENTATION_TYPES.WIN_THE_OFFER;
    const isPresentInfo = presentationData.type === PRESENTATION_TYPES.PRESENT_INFO;
    const isWTR = presentationData.type === PRESENTATION_TYPES.WIN_THE_REPRESENTATION;

    if (isWinTheOffer) {
      return 'Add Buyer’s Agreement Link';
    }

    if (isPresentInfo) {
      return 'Add Agreement Link';
    }

    if (isWTR) {
      return 'Add Buyer Representation Contract';
    }

    return 'Add Listing Agreement Link';
  }, [presentationData.type]);

  const emailPresentationTooltipText = presentationClient
    ? ''
    : "You cannot email this presentation until you use the 'Save & Add Client' button on this screen. Once you have added a client, you may email the presentation to said client.";

  if (!presentationMode) return null;

  return (
    <Container>
      <DashboardItem
        icon={['fas', 'square-user']}
        text={presentationClient ? `Client added: ${presentationClient.name}` : 'Save & Add Client'}
        onClick={onOpenAddClientModal}
        keepFontSize
        hasRemoveButton={false}
        isHighlighted
        hasSavedLabel={!!presentationClient}
        buttonText={presentationClient ? 'Edit' : undefined}
      />
      {showAgreementLink && (
        <DashboardItem
          icon={['fas', 'file-circle-plus']}
          text={agreementLinkText}
          onClick={() => setDashboardItem('addLink')}
          buttonText={agreementLink ? 'Edit' : ''}
          keepFontSize
          hasRemoveButton={!!agreementLink}
          onRemoveClick={onRemoveAgreementLink}
          loading={loadingItem === 'addLink'}
        />
      )}
      {presentationType !== 'presentInfo' && (
        <DashboardItem
          icon={['fas', 'square-poll-vertical']}
          text='Add Net Sheet'
          onClick={() => setDashboardItem('addNetSheet')}
          buttonText={hasNS ? 'Edit' : ''}
          keepFontSize
          hasRemoveButton={hasNS}
          onRemoveClick={onRemoveNetSheet}
          loading={loadingItem === 'addNetSheet'}
        />
      )}
      {presentationType !== 'presentInfo' && (
        <DashboardItem
          icon={['fas', 'video-plus']}
          text='Add Video Link'
          onClick={() => setDashboardItem('addCoreVideo')}
          buttonText={hasGV ? 'Edit' : ''}
          keepFontSize
          hasRemoveButton={hasGV}
          onRemoveClick={onRemoveVideoLink}
          loading={loadingItem === 'addCoreVideo'}
          tooltip='When adding a video to your presentation, it will be integrated as the first slide, preceding the title slide. The video can be seamlessly played during the presentation.'
        />
      )}
      <DashboardItem
        icon={['fas', 'circle-play']}
        text='Open Presentation'
        onClick={() => window.open(`/present/presentation/${hash}`)}
      />
      <DashboardItem
        icon={['fas', 'envelope']}
        text='Email Presentation'
        onClick={() => presentationClient && setDashboardItem('emailPresentation')}
        disabled={!presentationClient}
        tooltip={emailPresentationTooltipText}
      />
      <DashboardItem
        loading={isLoading || isFetching || !!data?.isBuildInProgress || !data?.pdf || isPdfLoading}
        icon={['fas', 'file-check']}
        text={`Download & Print`}
        onClick={() => {
          onDownloadPdf(data?.pdf, presentationData?.hash);
        }}
      />
      <DashboardItem
        icon={['fas', 'arrow-up-right-from-square']}
        text={presentationUrl}
        buttonText='Copy'
        onClick={() => DashboardItemsUtils.copyToClipboard(inputRef, presentationUrl)}
      />
      {getDashboardModalContent(
        dashboardItem,
        presentationData,
        setDashboardItem,
        presentationClient as TClient,
        data?.pdf || '',
        data?.isBuildInProgress || isLoading,
      )}
      <input
        type='text'
        ref={inputRef}
        style={{ position: 'absolute', opacity: 0, zIndex: -1 }}
        defaultValue=''
      />
    </Container>
  );
};
