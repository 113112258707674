import { FC } from 'react';
import { useDroppable } from '@dnd-kit/core';
import styled, { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TSlide } from '../../../../../../../../types';

interface Props {
  slide: TSlide;
  disabled?: boolean;
  isPDF: boolean;
  sectionId: string;
  hideMultiSlidesPdf?: boolean;
}

export const GhostSlide: FC<Props> = ({
  disabled,
  isPDF,
  children,
  sectionId,
  slide,
  hideMultiSlidesPdf,
}) => {
  const { setNodeRef, isOver } = useDroppable({
    id: slide.id,
    disabled,
    data: {
      sectionId,
    },
  });

  const { colors } = useTheme();

  if (hideMultiSlidesPdf) return <></>;

  return (
    <Wrapper ref={setNodeRef}>
      {isOver && (
        <GhostWrapper>
          <GhostHeaderWrapper>
            <FontAwesomeIcon
              icon={['fas', 'grip-dots-vertical']}
              size='sm'
              color={colors.v2.gray[200]}
            />
            <GhostHeader />
            <FontAwesomeIcon icon={['fas', 'close']} size='sm' color={colors.v2.gray[200]} />
          </GhostHeaderWrapper>
          <GhostBody isPDF={isPDF}>
            <GhostContent />
          </GhostBody>
        </GhostWrapper>
      )}
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const GhostWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const GhostHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding-top: 2px;
  padding-bottom: 2px;
`;

const GhostHeader = styled.div`
  height: 10px;
  width: 100%;
  margin-right: 20px;
  background-color: ${({ theme }) => theme.colors.v2.gray[200]};
`;

const GhostBody = styled.div<{ isPDF: boolean }>`
  height: 100%;
  width: 100%;
  padding: ${({ isPDF }) => (isPDF ? '0px 44px 20px' : '0px 0px 20px')};
`;

const GhostContent = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.v2.gray[200]};
`;
