import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { COMPARABLE_MODAL_MENU_SUBITEM_ID } from '../../../../../../../types';
import { SubSection } from '../SubSection';
import { Checkbox } from '../../../../../../../components/Common/V2/Checkbox';
import { CheckboxWithSelect } from './components/CheckboxWithSelect';
import { LEVELS_OPTIONS } from '../../../../../constants/options';

export const InteriorFeatures = () => {
  const { control } = useFormContext();

  return (
    <SubSection
      title='Interior Features'
      titleId={COMPARABLE_MODAL_MENU_SUBITEM_ID.INTERIOR_FEATURES}
      withHorizontalPadding
    >
      <Wrapper>
        <Controller
          name='isAirConditioning'
          control={control}
          defaultValue={false}
          render={({ field }) => <Checkbox {...field} label='Air Conditioning' />}
        />
        <Controller
          name='isMasterOnMain'
          control={control}
          defaultValue={false}
          render={({ field }) => <Checkbox {...field} label='Master on Main' />}
        />
        <Controller
          name='levels'
          control={control}
          render={({ field }) => (
            <CheckboxWithSelect
              {...field}
              label='Levels'
              options={LEVELS_OPTIONS}
              defaultValue={field.value || null}
              selectedOption={field.value || null}
            />
          )}
        />
      </Wrapper>
    </SubSection>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 10px;
`;
