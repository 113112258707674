import { Controller, useFormContext } from 'react-hook-form';

import { Textarea } from '../../../../../../../components/Common/V2/Textarea';
import { COMPARABLE_MODAL_MENU_SUBITEM_ID } from '../../../../../../../types';
import { SubSection } from '../SubSection';

export const Description = () => {
  const { control } = useFormContext();

  return (
    <SubSection
      title='Description'
      titleId={COMPARABLE_MODAL_MENU_SUBITEM_ID.DESCRIPTION}
      withHorizontalPadding
    >
      <Controller
        name='description'
        control={control}
        defaultValue={null}
        render={({ field }) => (
          <Textarea placeholder='Add description' {...field} style={{ borderRadius: 5 }} />
        )}
      />
    </SubSection>
  );
};
