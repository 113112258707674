import React, { useState, useEffect, useRef } from 'react';
import {
  DndContext,
  useSensors,
  useSensor,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  closestCenter,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import {
  DynamicSection,
  OrderableSlidesComponentProps,
} from './DynamicSection/DynamicSection.component';
import { Container } from './DynamicOrderableSections.styles';
import {
  TDynamicSection,
  TDynamicSectionId,
  TSection,
  TSectionConfig,
  TSectionsSlide,
  TSlide,
  TSlideId,
} from '../../../../../types';
import { useSelectedSlide } from '../../../../../providers/providers/SelectedSlideProvider';
import { useActiveSection } from '../../../../../components/Slide/providers/ActiveSectionProvider';
import { usePresentationType } from '../../../../../providers/providers/PresentationTypeProvider';
import { useOrder } from '../../../../../providers/providers/OrderProvider';
import { getDndSections } from './getDndSections';
import { useDragAndDrop } from './hooks/useDragAndDrop';

const sectionsOpenState = (sections: TSection[]): { [key in TSection]?: boolean } => {
  const obj: { [key in TSection]?: boolean } = {};
  sections.forEach((s, i) => (obj[s] = false));

  return obj;
};

interface SlideSectionsProps {
  sectionsOrder: TSection[];
  onSectionsOrderChange: (updatedSectionsOrder: TSection[]) => void;
  sections: Record<TDynamicSectionId, TDynamicSection>;
  extractSlideSection?: (slide: TSlide) => TSectionConfig | null;
  OrderableSlidesComponent: React.FC<OrderableSlidesComponentProps>;
  openInitialSection?: boolean;
  slidesOrderBySections: TSectionsSlide;
  notRemovableSections?: TSlideId[];
  notMovableAbove?: TSlideId[];
}

export const DynamicOrderableSections: React.FC<SlideSectionsProps> = ({
  sectionsOrder,
  onSectionsOrderChange,
  sections,
  extractSlideSection = () => {},
  OrderableSlidesComponent,
  openInitialSection,
  slidesOrderBySections,
  notRemovableSections,
  notMovableAbove,
}) => {
  const { selectedSlide } = useSelectedSlide();
  const { activeSection, setActiveSection } = useActiveSection();

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 150,
        tolerance: 5,
        distance: 5,
      },
    }),
  );
  const { setSectionsSlideOrder, sectionsSlide } = useOrder();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [open, setOpen] = useState({
    ...sectionsOpenState(sectionsOrder),
    [sectionsOrder[0]]: true,
  });

  const onToggle = React.useCallback(
    (sectionId: TSection | null) => {
      if (!sectionId) {
        setOpen(prev => {
          let previous = prev;
          Object.keys(prev).forEach(sectionId => {
            previous[sectionId as TSection] = false;
          });
          return previous;
        });
        setActiveSection(null);
        return;
      }
      setOpen(prev => ({ ...sectionsOpenState(sectionsOrder), [sectionId]: !prev[sectionId] }));
      setActiveSection(sectionId);
    },
    [sectionsOrder, setActiveSection],
  );

  const { onDragStart, onDragMove, onDragOver, onDragEnd, dndElements, updateInt, setDndElements } =
    useDragAndDrop({
      sectionsOrder,
      onSectionsOrderChange,
      notRemovableSections,
      timeoutRef,
      onToggle,
      open,
    });

  useEffect(() => {
    if (openInitialSection) {
      setOpen({
        ...sectionsOpenState(sectionsOrder),
        [sectionsOrder[0]]: true,
      });
    }
  }, [openInitialSection, sectionsOrder]);

  useEffect(() => {
    if (!dndElements) return;

    const { active, over } = dndElements;

    const isActiveSection = active?.data?.current?.isSection;
    const isSectionOpen = open[over?.id as TSection];
    if (isActiveSection || !active || !over || !isSectionOpen) return;

    const activeSlideId = active?.id as TSlideId;
    const { sourceSection, destinationSection } = getDndSections({ active, over });

    if (sourceSection !== destinationSection) {
      if (!sourceSection || !destinationSection) return;
      const updatedSectionsSlide = { ...sectionsSlide };

      const sourceIndex = updatedSectionsSlide[sourceSection].indexOf(activeSlideId);
      updatedSectionsSlide[sourceSection].splice(sourceIndex, 1);

      const notMovableIndexes =
        notMovableAbove
          ?.map(slideId => updatedSectionsSlide[destinationSection].indexOf(slideId as TSlideId))
          ?.filter(index => index !== -1) || [];

      let destinationIndex = -1;

      if (notMovableIndexes?.length) {
        destinationIndex = Math.max(...notMovableIndexes) + 1;
      }

      updatedSectionsSlide[destinationSection].splice(destinationIndex, 0, activeSlideId);

      const updatedSectionsSlideOrder = {
        ...sectionsSlide,
        [sourceSection]: updatedSectionsSlide[sourceSection],
        [destinationSection]: updatedSectionsSlide[destinationSection],
      };
      setSectionsSlideOrder(updatedSectionsSlideOrder);
    }

    setDndElements(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dndElements, open, setSectionsSlideOrder]);

  useEffect(() => {
    if (!selectedSlide) return;

    const isSectionContents = selectedSlide?.id === 'sectionContents';
    if (isSectionContents && activeSection && !open[activeSection as TSection]) {
      onToggle(activeSection as TSection);
      return;
    }

    const sectionId = extractSlideSection(selectedSlide)?.id;
    if (sectionId && !open[sectionId]) {
      onToggle(sectionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSlide]);

  if (!Object.keys(sections).length) return null;

  const slideIds = sectionsOrder.flatMap(sec => slidesOrderBySections[sec]);

  return (
    <Container>
      <DndContext
        sensors={sensors}
        onDragStart={onDragStart}
        onDragMove={onDragMove}
        onDragEnd={onDragEnd}
        onDragOver={onDragOver}
        modifiers={[restrictToVerticalAxis]}
        collisionDetection={closestCenter}
      >
        <SortableContext
          items={[...sectionsOrder, ...slideIds]}
          strategy={verticalListSortingStrategy}
        >
          {sectionsOrder.map((sec, i) => (
            <DynamicSection
              key={`${sec}-${updateInt}`}
              index={i}
              section={sections[sec]}
              open={!!open[sec]}
              onToggle={onToggle}
              OrderableSlidesComponent={OrderableSlidesComponent}
            />
          ))}
        </SortableContext>
      </DndContext>
    </Container>
  );
};
