import { useMemo } from 'react';

import { useIsDTC } from '../../../../../../../../hooks/daysOnSite/useIsDTC';
import { getMedianPaceDays } from '../../../../../../../../services/getMedianPaceDays';
import { TSellingPace } from '../../../../../../../../types';

export function useSellingPaceTookTimeMessage(data: TSellingPace | null): string {
  const isDTC = useIsDTC();

  return useMemo(() => {
    const fieldToCheck = getMedianPaceDays(isDTC);
    const metricText = isDTC ? 'days to close' : 'days on site';

    if (!data) return '';

    if (data?.[fieldToCheck]) {
      return `Half of closed comparables took less than ${data[fieldToCheck]} ${metricText}`;
    }

    return !data?.average_of_all
      ? ''
      : `The average closed comparable took ${data?.average_of_all} days to close.`;
  }, [data, isDTC]);
}
