import React, { useCallback } from 'react';

import {
  TCategory,
  TSectionConfig,
  TSlide,
  TSlideId,
  TSlidePlatform,
  TSlideTheme,
} from '../../../../../types';
import { CATEGORIES } from '../../../../../constants/categories';
import { usePreviewMode } from '../../providers/PreviewModeProvider';
import { useCategory } from '../../../../../providers/providers/CategoryProvider';
import { SlideFactory } from '../../../../../components/Slide/services/SlideFactory';
import { useOrder } from '../../../../../providers/providers/OrderProvider';
import { useSlides } from '../../../../../providers/providers/SlidesProvider';
import { useUiConfigTheme } from '../../../../../providers/providers/UiConfigThemeProvider';
import { OrderableSlidesComponentProps } from '../DynamicOrderableSections/DynamicSection/DynamicSection.component';
import { DynamicOrderableSlides } from '../DynamicOrderableSections/DynamicSection/DynamicOrderableSlides/DynamicOrderableSlides.component';
import { useLoadPersonalized } from '../../../../../data/personalized/useLoadPersonalized';
import { useUser } from '../../../../../components/Slide/providers/UserProvider';
import { isPopulatedPersonalizedSlide } from '../../../../../services/isPopulatedPersonalizedSlide';

export const RegularDynamicOrderableSlides: React.FC<OrderableSlidesComponentProps> = React.memo(
  function RegularDynamicOrderableSlides({ section, open }) {
    const { hash } = useUser();
    const { getSlides } = useSlides();
    const { sectionsSlide, removeSlideFromSection } = useOrder();
    const { data } = useLoadPersonalized(hash);

    const { theme } = useUiConfigTheme();
    const { category } = useCategory();
    const { previewMode } = usePreviewMode();

    const sectionSlides = React.useMemo(
      () => getSlides(sectionsSlide[section.id]),
      [getSlides, section.id, sectionsSlide],
    );

    const hasSlideComponent = (
      slide: TSlide,
      theme: TSlideTheme,
      platform: TSlidePlatform,
      category?: TCategory,
    ) => {
      return !!SlideFactory.create({
        slide: slide.id as TSlideId,
        theme,
        platform,
        template: slide.template,
        category: category ?? CATEGORIES.TRADITIONAL,
      });
    };

    const slidesOrder = React.useMemo(() => {
      return sectionSlides
        .filter(slide =>
          ['aboutMe', 'testimonials'].includes(slide.id)
            ? hasSlideComponent(slide, theme, previewMode, category) &&
              isPopulatedPersonalizedSlide(slide.id, data)
            : hasSlideComponent(slide, theme, previewMode, category),
        )
        .map(slide => slide.id) as TSlideId[];
    }, [sectionSlides, theme, previewMode, category, data]);

    const onRemoveSlide = React.useCallback(
      (slide: TSlide) => {
        removeSlideFromSection(section.id, slide.id as TSlideId);
      },
      [removeSlideFromSection, section.id],
    );

    const slidesMap = React.useMemo(
      () => Object.fromEntries(sectionSlides.map(slide => [slide.id, { ...slide }])),
      [sectionSlides],
    );

    const notDraggable = React.useMemo(
      () => ['coverSlide', 'greetingVideo', 'closingAndNextSteps'],
      [],
    ) as TSlideId[];

    const notRemovable = React.useMemo(
      () => ['coverSlide', 'closingAndNextSteps'],
      [],
    ) as TSlideId[];

    return (
      <DynamicOrderableSlides
        order={slidesOrder}
        slides={slidesMap}
        section={section as TSectionConfig}
        onRemoveSlide={onRemoveSlide}
        notDraggable={notDraggable}
        notRemovable={notRemovable}
        open={open}
        showSectionContents={previewMode === 'pdf' && section.id !== 'startingSlides'}
      />
    );
  },
);
