import styled, { CSSProperties } from 'styled-components';
import { COMPARABLE_MODAL_MENU_ITEM_ID } from '../../../../../../types';

interface Props {
  children: React.ReactNode;
  title: string;
  titleId: COMPARABLE_MODAL_MENU_ITEM_ID;
  infoText?: string;
  withHorizontalPadding?: boolean;
  titleWrapperStyle?: CSSProperties;
  description?: string;
}

export const MainSection = ({
  children,
  title,
  titleId,
  infoText,
  withHorizontalPadding,
  titleWrapperStyle,
  description,
}: Props) => {
  return (
    <Container id={titleId} withHorizontalPadding={withHorizontalPadding}>
      <TitleWrapper style={titleWrapperStyle}>
        <Title>{title}</Title>
        {infoText && <InfoText>{infoText}</InfoText>}
      </TitleWrapper>
      {description && <Description>{description}</Description>}
      {children}
    </Container>
  );
};

const Container = styled.div<{ withHorizontalPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  ${({ withHorizontalPadding }) =>
    withHorizontalPadding &&
    `
    padding-left: 20px;
    padding-right: 20px;
  `}
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h4`
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;

const InfoText = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;

const Description = styled.span`
  color: ${({ theme }) => theme.colors.v2.gray[400]};
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  padding: 5px 20px 0px;
`;
