import { Controller, useFormContext, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import { useUser } from '../../../../Slide/providers/UserProvider';
import { TextEditor } from '../../../TextEditor/TextEditor.component';
import { InputLabel, InputWrapper } from './common';
import { Checkbox } from '../../../V2/Checkbox';
import { useMemo } from 'react';

const Signature = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.v2.gray[200]};
  border-radius: 5px;
  cursor: not-allowed;
  min-height: 164px;
  padding: 10px;
  position: relative;
`;

const SignatureOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  top: 0;
  left: 0;
  background-color: #fff;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SignatureInput = () => {
  const { control } = useFormContext();

  const user = useUser();

  const useDefaultSignature = useWatch({ name: 'useDefaultSignature' });

  const signature = useMemo(() => {
    if (useDefaultSignature) {
      return (
        <Signature>
          <div dangerouslySetInnerHTML={{ __html: user?.signature || '' }} />
          <SignatureOverlay />
        </Signature>
      );
    }

    return (
      <Controller
        name='signature'
        control={control}
        render={({ field }) => (
          <TextEditor
            onChangeText={field.onChange}
            rawHtml={field.value}
            editorWrapperStyles={{ minHeight: 164, marginBottom: 10 }}
            toolbarOptions={['inline', 'list', 'fontSize']}
          />
        )}
      />
    );
  }, [control, useDefaultSignature, user?.signature]);

  return (
    <InputWrapper>
      <LabelWrapper>
        <InputLabel>Email Signature:</InputLabel>
        <Controller
          name='useDefaultSignature'
          control={control}
          render={({ field }) => (
            <Checkbox
              value={field.value}
              onChange={field.onChange}
              label='Use Default Outgoing Email Signature'
              disabled={!user?.signature}
            />
          )}
        />
      </LabelWrapper>

      {signature}
    </InputWrapper>
  );
};
