import React from 'react';

import { activeShelfLifeData } from '../data';
import { CATEGORIES } from '../../../../../../../../constants/categories';
import { PdfFrame } from '../../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useCategory } from '../../../../../../../../providers/providers/CategoryProvider';
import { SlideWithGraphWrapperPdf } from '../../../../../components/common/SlideWithGraphWrapperPdf';
import { ActiveShelfLifeChart } from '../../../../../../../../features/report/charts/active-shelf-life/ActiveShelfLifeChart';
import { useDaysOnSite } from '../../../../../../../../features/report/charts/active-shelf-life/useDaysOnSite';

const ActiveShelfLifePdf: React.FC = () => {
  const { category } = useCategory();
  const daysOnSite = useDaysOnSite();

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <SlideWithGraphWrapperPdf
        heading={activeShelfLifeData.heading}
        title={`${daysOnSite} ${activeShelfLifeData.value}`}
        subtitle={activeShelfLifeData.subtitle}
        description={activeShelfLifeData.description}
        isReversed
      >
        <ActiveShelfLifeChart style={{ height: 440 }} />
      </SlideWithGraphWrapperPdf>
    </PdfFrame>
  );
};

export default ActiveShelfLifePdf;
