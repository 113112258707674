export const transformOnMlsCompositeId = (value: string) => {
  if (value && value.includes(':composite:')) {
    const splitted = value.split(':composite:');
    return {
      mlsId: splitted[0],
      listingId: splitted[1],
    };
  }

  return {
    listingId: value,
  };
};
