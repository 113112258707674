import { useMutation } from '@tanstack/react-query';
import { corePresentApi } from '../api/CorePresentApi';
import { TPresentation } from '../types';

const rebuildPresentation = async (presentationId: number): Promise<TPresentation> => {
  const rebuiltPresentation = await corePresentApi.post(`/presentations/${presentationId}/rebuild`);

  return rebuiltPresentation;
};

export const useRebuildPresentation = () => {
  return useMutation(rebuildPresentation, {
    onError: err => {
      console.error('Error while rebuilding presentation', err);
    },
  });
};
