import React from 'react';

import {
  TSectionConfig,
  TSlide,
  TSlideId,
  TSlideTheme,
  TSlidePlatform,
  TCategory,
  TDynamicSectionId,
} from '../../../../../types';
import { CATEGORIES } from '../../../../../constants/categories';
import { usePreviewMode } from '../../providers/PreviewModeProvider';
import { useCategory } from '../../../../../providers/providers/CategoryProvider';
import { SlideFactory } from '../../../../../components/Slide/services/SlideFactory';
import { useOrder } from '../../../../../providers/providers/OrderProvider';
import { useSlides } from '../../../../../providers/providers/SlidesProvider';
import { useUiConfigTheme } from '../../../../../providers/providers/UiConfigThemeProvider';
import { OrderableSlidesComponentProps } from '../DynamicOrderableSections/DynamicSection/DynamicSection.component';
import { DynamicOrderableSlides } from '../DynamicOrderableSections/DynamicSection/DynamicOrderableSlides/DynamicOrderableSlides.component';

export const OneSheeterOrderableSlides: React.FC<OrderableSlidesComponentProps> = ({
  section,
  open,
}) => {
  const { sectionsSlide, setSectionSlidesOrder } = useOrder();
  const { getSlides } = useSlides();

  const { theme } = useUiConfigTheme();
  const { category } = useCategory();
  const { previewMode } = usePreviewMode();

  const oneSheeterSlides = React.useMemo(
    () => getSlides(sectionsSlide[section.id as TDynamicSectionId] ?? []),
    [getSlides, sectionsSlide, section.id],
  );

  const onRemoveSlide = (slide: TSlide) => {
    const newSlidesOrder = sectionsSlide.oneSheeterSection.filter(slideId => slide.id !== slideId);

    setSectionSlidesOrder(section.id, newSlidesOrder);
  };

  const hasSlideComponent = (
    slide: TSlide,
    theme: TSlideTheme,
    platform: TSlidePlatform,
    category?: TCategory,
  ) => {
    if (!slide) return false;

    return !!SlideFactory.create({
      slide: slide.id as TSlideId,
      theme,
      platform,
      template: slide.template,
      category: category ?? CATEGORIES.TRADITIONAL,
    });
  };

  const slidesMap = React.useMemo(
    () => Object.fromEntries(oneSheeterSlides.map(slide => [slide.id, { ...slide }])),
    [oneSheeterSlides],
  );

  const slidesOrder = React.useMemo(() => {
    return sectionsSlide[section.id]
      .map(slideId => slidesMap[slideId])
      .filter(slide => hasSlideComponent(slide, theme, previewMode, category))
      .map(slide => slide.id as TSlideId);
  }, [sectionsSlide, section.id, slidesMap, theme, previewMode, category]);

  if (!sectionsSlide?.oneSheeterSection) return null;

  return (
    <DynamicOrderableSlides
      order={slidesOrder}
      slides={slidesMap}
      section={section as TSectionConfig}
      onRemoveSlide={onRemoveSlide}
      notDraggable={['oneSheeter']}
      notRemovable={['oneSheeter']}
      open={open}
    />
  );
};
