import { ThemeColors } from '../types';

const colors: ThemeColors = {
  v2: {
    primary: '#05205E',
    background: '#FFFFFF',
    accent: '#CD00FC',
    text: {
      regular: '#444444',
      contrastText: '#ffffff',
      link: '#0066cc',
    },
    gradient: {
      accent: 'linear-gradient(137deg, #CD00FC 0%, #F00 96.56%)',
      border:
        'linear-gradient(white, white), linear-gradient(to top right, #3c1787 0%, #6d10ab 10%, #940ac9 28%, #b305e1 42%, #ca02f1 57%, #d700fb 68%, #dc00ff 75%, #ff0000 100%)',
      background:
        'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 41.16%), linear-gradient(270deg, rgba(255, 255, 255, 0.30) 0%, rgba(192, 168, 255, 0.30) 22%, rgba(207, 217, 255, 0.30) 82.5%, rgba(255, 255, 255, 0.30) 100%), #FFF',
    },
    button: {
      danger: '#ED0D21',
    },
    gray: {
      400: '#444444',
      300: '#B1B1B1',
      200: '#DDDDDD',
      100: '#F1F1F1',
    },
    status: {
      success: '#a3c769',
      warning: '#EBAF17',
      error: '#EC1E24',
      info: '#FEF8E7',
    },
  },
  primary: {
    light: '#99C5B5',
    main: '#05205E',
    dark: '#706c61',
    contrastText: '#ffffff',
    textLight: '#373e43',
    borderDark: '#323232',
    textLink: '#0066CC',
  },
  secondary: {
    light: '#E5D0CC',
    main: '#05205E',
    dark: '#BFACB5',
    contrastText: '#111111',
    textLight: '#626262',
    borderDark: '#323232',
    textLink: '#0066CC',
  },
  gray: {
    400: '#444444',
    300: '#B1B1B1',
    200: '#DDDDDD',
    100: '#F1F1F1',
  },
  success: '#a3c769',
  warning: '#EBAF17',
  error: '#D0021B',
};

export default colors;
